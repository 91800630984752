import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

export default function Navigation(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const { cancelled, verified } = props;

  const signOut = () => {
    if (window.confirm("Would you like to log out?")) {
      localStorage.clear();
      window.location = "/";
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      {matches && (
        <div className={classes.root}>
          <CssBaseline />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={clsx(open && classes.hide)}
          >
            <i className="fas fa-bars"></i>
          </IconButton>
          <Drawer
            className={classes.drawer}
            anchor="right"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? null : (
                  <i class="fas fa-chevron-right"></i>
                )}
              </IconButton>
            </div>
            <Divider />
            <List>
              {verified && !cancelled && (
                <>
                  <ListItem button key="Cases">
                    <ListItemText
                      primary="Cases"
                      onClick={() => (window.location = "/cases")}
                    />
                  </ListItem>
                  <ListItem button key="Providers">
                    <ListItemText
                      primary="Providers"
                      onClick={() => (window.location = "/providers")}
                    />
                  </ListItem>
                  <ListItem button key="Team">
                    <ListItemText
                      primary="Team"
                      onClick={() => (window.location = "/team")}
                    />
                  </ListItem>
                  <ListItem button key="Templates">
                    <ListItemText
                      primary="Templates"
                      onClick={() => (window.location = "/templates")}
                    />
                  </ListItem>
                </>
              )}
              <ListItem button key="My Account">
                <ListItemText
                  primary="My Account"
                  onClick={() => (window.location = "/account")}
                />
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem button key="Sign Out">
                <ListItemText primary="Sign Out" onClick={signOut} />
              </ListItem>
            </List>
          </Drawer>
        </div>
      )}
      {!matches && (
        // (cancelled != null) &&
        <div
          style={{
            fontFamily: "Avenir Next",
            display: "flex",
            flex: 1,
            height: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
            marginRight: "2%",
          }}
        >
          {verified && !cancelled && (
            <div
              style={{
                display: "flex",
                height: "100%",
                flexDirection: "row",
              }}
            >
              <NavLink className="App-link" to="/cases">
                Cases
              </NavLink>
              <NavLink className="App-link" to="/providers">
                Providers
              </NavLink>
              <NavLink className="App-link" to="/team">
                Team
              </NavLink>
              <NavLink className="App-link" to="/templates">
                Templates
              </NavLink>
            </div>
          )}
          <NavLink exact className="App-link" to="/account">
            My Account
          </NavLink>
          <div
            className="App-link"
            onClick={signOut}
            style={{ cursor: "pointer" }}
          >
            Sign Out
          </div>
        </div>
      )}
    </>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
}));
