import React, { useState, useCallback } from "react";
import Modal from "@material-ui/core/Modal";
import { TextField, Grid, Button } from "@material-ui/core";
import axios from "axios";
import HelloSign from "hellosign-embedded";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { useDropzone } from "react-dropzone";
import { FiUpload } from "react-icons/fi";
import { ClipLoader } from "react-spinners";
import useKochava from "../../hooks/useKochava";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      display: "flex",
      justifyContent: "center",
      margin: "40px auto",
      [theme.breakpoints.down(768)]: {
        margin: "24px auto",
      },
      [theme.breakpoints.down(415)]: {
        width: "100%",
      },
      "& label": {
        color: "#5C5C5C",
      },
    },
  },
  heading: {
    fontSize: 32,
    fontWeight: 700,
    marginTop: 16,
    marginBottom: 16,
    [theme.breakpoints.down(768)]: {
      fontSize: 24,
    },
  },
}));

export default function TemplateModal(props) {
  const client = new HelloSign();
  const classes = useStyles();
  const { recordActivity } = useKochava();
  const [title, setTitle] = useState(null);
  const [loading, setLoading] = useState(false);

  const [template, setTemplate] = useState(null);

  const { templates, setTemplates, user } = props;

  const handleClose = () => {
    setTemplate(null);
    props.setOpen(false);
  };

  //React Dropzone
  const onDrop = useCallback((acceptedFiles) => {
    console.log("acceptedFiles", acceptedFiles);
    setTemplate(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  function uploadTemplate(e) {
    e.preventDefault();
    setLoading(true);
    let file = new FormData();

    file.append("file", template);
    file.append("title", title);
    file.append("firmId", props.firms[0].firmId);
    // if (template.name.toLowerCase().includes("pdf")) {
    axios
      .post(`/helloSign/template/draft`, file, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((resp) => {
        let edit_url = resp.data.results.template.edit_url;

        client.open(edit_url, {
          clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
          testMode: true,
          skipDomainVerification: false,
        });

        setLoading(false);

        client.on("sign", (data) => {
          console.log("The document has been signed!");
          console.log("Signature ID: " + data.signatureId);
        });
        client.on("createTemplate", (data) => {
          props.setOpen(false);
          props.notify("Template added!", true);
          let arr = [...templates];
          let savedTemplate = resp.data.savedTemplate;
          savedTemplate.creator = user;
          arr.unshift(savedTemplate);
          setTemplates([...arr]);
        });

        client.on("error", (err) => {
          console.log("HelloSign error", err);
          props.setOpen(false);
          props.notify("Error adding template. Please try again later.", false);
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log(
          "Error uploading template",
          err.response ? err.response : err
        );
        props.notify(
          err?.response?.data?.message
            ? err.response.data.message
            : "Error adding template. Please try again later.",
          false
        );
      });
    process.env.REACT_APP_TRACKING_ENABLED === "true" &&
      recordActivity("Template Added");
    // } else {
    //   setLoading(false);
    //   props.notify(
    //     "Error uploading template. Please upload a PDF file.",
    //     false
    //   );
    // }
  }

  return (
    <Modal
      open={props.open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ margin: "1rem" }}
      onClick={handleClose}
    >
      <div className="modal">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-form">
            <div className={classes.root}>
              <ClearIcon className="close" onClick={handleClose} />
              <h2 className={classes.heading}>Upload a Template</h2>
              <TextField
                variant="outlined"
                disableUnderline={true}
                label="Template Name"
                onChange={(e) => setTitle(e.target.value)}
                style={{
                  flex: 0.6,
                  color: "#000",
                  borderRadius: "100px",
                }}
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
              <Grid
                container
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={4}>
                  <input
                    type="file"
                    className="file-upload"
                    style={{ fontSize: 16, cursor: "pointer" }}
                    onChange={(e) => {
                      setTemplate(e.target.files[0]);
                    }}
                    style={{ display: "none" }}
                    id="file"
                  />
                  <label for="file" className="choose-file-input">
                    Choose File
                  </label>
                </Grid>
                <Grid item xs={8}>
                  <p className="choose-file-text">
                    {template ? (
                      template.name
                    ) : (
                      <span style={{ color: "#5C5C5C" }}>No File Chosen</span>
                    )}
                  </p>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="center"
                {...getRootProps()}
                className="drop-file-section"
              >
                <Grid item xs={12}>
                  <FiUpload />
                </Grid>
                <Grid item xs={12}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the file here..</p>
                  ) : (
                    <p>You can also drop your file here.</p>
                  )}
                </Grid>
              </Grid>
              <Button
                className="btn light-blue add-btn"
                style={{
                  alignItems: "center",
                  margin: "95px auto",
                  border: "none",
                }}
                disabled={!title || !template || loading}
                onClick={(e) => uploadTemplate(e)}
              >
                {loading ? (
                  <div
                    className="sweet-loading"
                    style={{
                      position: "absolute",
                      width: 15,
                      height: 15,
                      top: 13,
                    }}
                  >
                    <ClipLoader size={20} color="#fff" loading={loading} />
                  </div>
                ) : (
                  <span>Add Template</span>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
