import React, { useEffect } from "react";
import { Grid, useMediaQuery, Box } from "@material-ui/core";
import CheckoutForm from "./CheckoutForm";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import useKochava from "../../../hooks/useKochava";
import CheckoutFormDetails from "./CheckoutFormDetails";

export default function Billing(props) {
  const { recordActivity } = useKochava();
  const classes = useStyles();
  const mobile = useMediaQuery("(max-width:940px)");
  const {
    firstName,
    email,
    phone,
    lastName,
    firmName,
    password,
    passwordConfirmation,
    swapView,
    getFirm,
    setAuth,
    notify,
  } = props;

  useEffect(() => {
    process.env.REACT_APP_TRACKING_ENABLED === "true" &&
      recordActivity("Signup checkout form ", { checkoutForm: "arrived" });
  }, []);

  function signup() {
    axios
      .post("/auth/portal/signup", {
        firstName,
        lastName,
        email,
        phone,
        password,
      })
      .then((resp) => {
        localStorage.setItem("userId", resp.data.userId);
        localStorage.setItem("auth", resp.headers.authorization);
        setAuth(resp.headers.authorization);
        window.location = "/account/business";
        console.log("user created", resp);
      })
      .catch((err) => {
        console.log(
          "Error signing up",
          err.response ? err.response.data.message : err
        );
        notify(
          err.response
            ? err.response.data.message
            : "Error signing up. Please try again",
          false
        );
      });
  }

  return (
    <div className={classes.center}>
      <div style={{ padding: "1rem 0" }}>
        <div className={classes.root}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                swapView();
              }}
              className={classes.button}
              style={{
                marginBottom: mobile ? 16 : 30,
                marginTop: 16,
              }}
            >
              Go Back
            </button>
            {/* <button
              onClick={(e) => {
                e.stopPropagation();
                signup();
              }}
              className={classes.button}
            >
              No thanks, I'll do this later
            </button> */}
          </div>
          <Grid container className={classes.billingContainer}>
            <Grid
              item
              xs={mobile ? 12 : 6}
              style={{ padding: "80px 60px 60px 75px" }}
              className={classes.column}
            >
              <CheckoutFormDetails />
            </Grid>

            <Grid
              item
              xs={mobile ? 12 : 6}
              style={{ padding: "60px 75px 67px 64px" }}
              className={classes.column}
            >
              <CheckoutForm
                getFirm={getFirm}
                firmName={firmName}
                firstName={firstName}
                lastName={lastName}
                email={email}
                phone={phone}
                password={password}
                setAuth={setAuth}
                notify={notify}
                {...props}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  center: {
    minHeight: "calc(100vh - 60px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#f5f7f9",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    margin: "auto",
    zIndex: 1,
    [theme.breakpoints.down(1200)]: {
      margin: "auto 1rem",
    },
  },
  button: {
    display: "flex",
    alignItems: "center",
    background: "none",
    border: "none",
    cursor: "pointer",
    textDecoration: "underline",
    top: "-8%",
  },
  billingContainer: {
    backgroundColor: "#fff",
    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: 10,
    [theme.breakpoints.up(1030)]: {
      maxWidth: 1197,
    },
  },
  column: {
    margin: "0px !important",
    [theme.breakpoints.down(940)]: {
      padding: "32px !important",
    },
    [theme.breakpoints.down(415)]: {
      padding: "16px !important",
    },
  },
}));
