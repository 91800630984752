import React, { useState } from "react";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { Button, TextField } from "@material-ui/core";
import "../../App.css";
import axios from "axios";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { ContactSupportOutlined } from "@material-ui/icons";
import { ClipLoader } from "react-spinners";
import { PhoneFormatter } from "../../lib/NumberFormatter";
import Autocomplete from "../../lib/Autocomplete";

const useStyles = makeStyles((theme) => ({
  input: {
    margin: "3.6% 0",
    textAlign: "left",
    "& > label": {
      color: "#5C5C5C !important",
    },
  },
}));

export default function EditProvider(props) {
  const {
    providers,
    setProviders,
    notify,
    setEditProviderModal,
    fetchBusinesses,
  } = props;
  let { provider } = props;
  const [name, setName] = useState(provider?.name);
  const [phone, setPhone] = useState(provider?.phone);
  const [street, setStreet] = useState(provider?.address);
  const [city, setCity] = useState(provider?.city);
  const [zip, setZip] = useState(provider?.zip);
  const [state, setState] = useState(provider?.state);
  const [address, setAddress] = useState(
    provider ? `${provider.address}, ${provider.city}, ${provider.state}` : ""
  );
  const [notes, setNotes] = useState(provider?.note);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [validateAddress, setValidateAddress] = useState(false);
  const [coordinates, setCoordinates] = useState({});
  console.log("provider in editProvider", provider);

  const inputProps = {
    variant: "outlined",
    color: "primary",
    className: classes.input,
    inputProps: { maxLength: 50 },
  };

  const updateProvider = () => {
    setLoading(true);
    console.log("id HERE", provider.id);
    axios
      .put(`/businesses/update/${provider.id}`, {
        address: street,
        city,
        state,
        zipCode: zip,
        name,
        notes,
        phone,
        latitude: coordinates.lat,
        longitude: coordinates.lng,
      })
      .then((resp) => {
        setLoading(false);
        setEditProviderModal(false);
        let arr = [...providers];
        let index = providers.findIndex((el) => {
          console.log("COMPARE", el.id, resp.data.business.id);
          return el.id === resp.data.business.id;
        });
        console.log("INDEX", arr, index);
        arr[index] = resp.data.business;
        provider = resp.data.business;
        setProviders(arr);
        console.log("Provider updated", resp);
        notify("Provider updated!", true);
      })
      .catch((err) => {
        setLoading(false);
        notify("Error updating provider", false);
        console.log("Error updating businesses", err);
      });
  };

  const handleSelect = (address) => {
    setAddress(address);

    //gets address
    geocodeByAddress(address)
      .then((results) => {
        console.log("results", results);
        let arr = results[0].address_components;
        let findStreetNum = arr.find((el) =>
          el.types.includes("street_number")
        );
        let findStreetName = arr.find((el) => el.types.includes("route"));
        let findCity = arr.find((el) => el.types.includes("locality"));
        let findZip = arr.find((el) => el.types.includes("postal_code"));
        let findState = arr.find((el) =>
          el.types.includes("administrative_area_level_1")
        );
        if (findStreetNum && findCity && findZip) {
          setValidateAddress(false);
          const street =
            findStreetNum.long_name + " " + findStreetName.long_name;
          const city = findCity.long_name;
          const state = findState.short_name;
          const zip = findZip.long_name;
          setStreet(street);
          setCity(city);
          setState(state);
          setZip(zip);
        } else {
          setAddress("");
          setValidateAddress(true);
        }
      })
      .catch((error) => console.error("Error", error));

    //gets lat and lng
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        console.log("Success", latLng);
        setCoordinates(latLng);
      })
      .catch((error) => console.error("Error", error));
  };

  const handleClose = (e) => {
    setEditProviderModal(false);
  };

  return (
    <Modal
      open
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      onClick={handleClose}
    >
      <div className="modal">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <ClearIcon className="close" onClick={handleClose} />
          <h2 className="modal-header">Edit Provider</h2>
          <div className="modal-form">
            <TextField
              placeholder="Name"
              value={name}
              name="name"
              fullWidth
              {...inputProps}
              onChange={(e) => setName(e.target.value)}
            />
            <PhoneFormatter
              placeholder="Phone Number"
              value={phone}
              fullWidth
              {...inputProps}
              onChange={(e) => setPhone(e.target.value)}
            />
            <div className="providers">
              <Autocomplete
                value={address}
                handleChange={(address) => setAddress(address)}
                handleSelect={handleSelect}
                variant="outlined"
                placeholder="Address"
                color="primary"
                classNameInput={classes.autocompleteInput}
              />
              {validateAddress && (
                <p
                  style={{
                    fontSize: 14,
                    color: "#ff7070",
                    textAlign: "left",
                    margin: 0,
                    marginBottom: 8,
                    lineHeight: "24px",
                    fontSize: 14,
                  }}
                >
                  Please enter your address in format: 123 N Main St, Boston MA,
                  02110
                </p>
              )}
            </div>
            <TextField
              placeholder="Notes"
              name="notes"
              fullWidth
              value={notes}
              {...inputProps}
              onChange={(e) => setNotes(e.target.value)}
            />
            <Button
              className="btn light-blue add-btn"
              disabled={!name || !address || !phone || loading}
              onClick={updateProvider}
            >
              {loading ? (
                <div
                  className="sweet-loading"
                  style={{
                    position: "absolute",
                    width: 15,
                    height: 15,
                    top: 13,
                  }}
                >
                  <ClipLoader size={20} color="#fff" loading={loading} />
                </div>
              ) : (
                <span>Update Provider</span>
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
