import React from "react";
import { BiCheck } from "react-icons/bi";

export function UncheckedIcon() {
  return (
    <div
      style={{
        width: 15,
        height: 15,
        border: "1px solid #5c5c5c",
        borderRadius: 2,
        marginTop: 2,
      }}
    />
  );
}

export function CheckedIcon() {
  return (
    <div
      style={{
        width: 15,
        height: 15,
        border: "1px solid #498cc2",
        borderRadius: 2,
        marginTop: 2,
      }}
    >
      <BiCheck
        size={20}
        color="#498cc2"
        style={{ position: "absolute", bottom: -2, left: -2.5 }}
      />
    </div>
  );
}
