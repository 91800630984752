import React, { useState } from "react";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { Button, TextField } from "@material-ui/core";
import "../../App.css";
import axios from "axios";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { ClipLoader } from "react-spinners";
import { PhoneFormatter } from "../../lib/NumberFormatter";
import Autocomplete from "../../lib/Autocomplete";

const useStyles = makeStyles((theme) => ({
  input: {
    margin: "3.6% 0",
    textAlign: "left",
    "& > label": {
      color: "#5C5C5C !important",
    },
  },
}));

export default function AddProvider(props) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [state, setState] = useState("");
  const [notes, setNotes] = useState("");
  const [loading, setLoading] = useState("");
  const { open, setOpen, providers, setProviders, notify } = props;
  const [address, setAddress] = useState(
    street ? `${street}, ${city}, ${state}` : ""
  );
  const [coordinates, setCoordinates] = useState({});
  const [validateAddress, setValidateAddress] = useState(null);

  const classes = useStyles();

  const addProvider = () => {
    setLoading(true);
    console.log("HERE", props.firms);
    axios
      .post("/businesses", {
        address: street,
        city,
        state,
        zipCode: zip,
        name,
        notes,
        phone,
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        firmId: props.firms[0].firmId,
      })
      .then((resp) => {
        setLoading(false);
        console.log("provider created", resp);
        let arr = [...providers];
        console.log("arr in addProvider", arr);
        arr.unshift(resp.data.business);
        setProviders([...arr]);
        notify("Provider added!", true);
        setOpen(false);
      })
      .catch((err) => {
        setLoading(false);
        notify("Error creating provider", false);
        console.log("Error creating businesses", err);
      });
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleSelect = (address) => {
    setAddress(address);

    //gets address
    geocodeByAddress(address)
      .then((results) => {
        console.log("results", results);
        let arr = results[0].address_components;
        let findStreetNum = arr.find((el) =>
          el.types.includes("street_number")
        );
        let findStreetName = arr.find((el) => el.types.includes("route"));
        let findCity = arr.find((el) => el.types.includes("locality"));
        let findZip = arr.find((el) => el.types.includes("postal_code"));
        let findState = arr.find((el) =>
          el.types.includes("administrative_area_level_1")
        );
        if (findStreetNum && findCity && findZip) {
          setValidateAddress(false);
          const street =
            findStreetNum.long_name + " " + findStreetName.long_name;
          const city = findCity.long_name;
          const state = findState.short_name;
          const zip = findZip.long_name;
          setStreet(street);
          setCity(city);
          setState(state);
          setZip(zip);
        } else {
          setAddress("");
          setValidateAddress(true);
        }
      })
      .catch((error) => console.error("Error", error));

    //gets lat and lng
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        console.log("Success", latLng);
        setCoordinates(latLng);
      })
      .catch((error) => console.error("Error", error));
  };

  const inputProps = {
    variant: "outlined",
    color: "primary",
    className: classes.input,
    inputProps: { maxLength: 50 },
  };

  return (
    <Modal
      open
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      onClick={handleClose}
    >
      <div className="modal">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <ClearIcon className="close" onClick={handleClose} />
          <h2 className="modal-header">Add A Provider</h2>
          <div
            className="modal-form"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <TextField
              label="Name"
              value={name}
              name="name"
              {...inputProps}
              onChange={(e) => setName(e.target.value)}
              inputProps={{ style: { textTransform: "capitalize" } }}
            />
            <PhoneFormatter
              label="Phone Number"
              value={phone}
              fullWidth
              {...inputProps}
              onChange={(e) => setPhone(e.target.value)}
            />
            <div className="providers">
              <Autocomplete
                value={address}
                handleChange={(address) => setAddress(address)}
                handleSelect={handleSelect}
                variant="outlined"
                placeholder="Address"
                color="primary"
                classNameInput={classes.autocompleteInput}
              />
              {validateAddress && (
                <p
                  style={{
                    fontSize: 14,
                    color: "#ff7070",
                    textAlign: "left",
                    margin: 0,
                    marginBottom: 8,
                    lineHeight: "24px",
                    fontSize: 14,
                  }}
                >
                  Please enter your address in format: 123 N Main St, Boston MA,
                  02110
                </p>
              )}
            </div>
            <TextField
              label="Notes"
              name="notes"
              value={notes}
              {...inputProps}
              onChange={(e) => setNotes(e.target.value)}
              inputProps={{ style: { textTransform: "capitalize" } }}
            />
            <Button
              className="btn light-blue add-btn"
              disabled={
                !name ||
                !phone ||
                !address ||
                loading ||
                validateAddress ||
                validateAddress == null
              }
              onClick={addProvider}
            >
              {loading ? (
                <div
                  className="sweet-loading"
                  style={{
                    position: "absolute",
                    width: 15,
                    height: 15,
                    top: 13,
                  }}
                >
                  <ClipLoader size={20} color="#fff" loading={loading} />
                </div>
              ) : (
                <span>Add Provider</span>
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const styles = {
  input: {
    backgroundColor: "#fff",
    marginTop: "1% 0",
  },
  checkbox: {
    backgroundColor: "#fff",
    borderRadius: 0,
    margin: "1% 0",
  },
};
