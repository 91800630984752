import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import "../../App.css";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { CSSTransition } from "react-transition-group";

export default function ResetPassword(props) {
  const classes = useStyles();
  const { email, onSubmit, notify } = props;
  const [fade, setFade] = useState(false);

  useEffect(() => {
    setFade(true);
  }, []);

  const handleSubmit = (event) => {
    axios
      .post("/auth/forgot/password", { email })
      .then((resp) => {
        console.log('Reset password email sent!', resp)
        notify("Reset password email sent!", true);
      })
      .catch((err) => {
        notify("Error sending password reset email", false);
        console.log("Error sending password reset email", err);
      });
  };

  return (
    <CSSTransition in={fade} timeout={700} classNames="my-node">
      {fade ? (
        <Box
          className="sign-in"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div className={classes.root}>
            <h1 style={{ margin: "21px 0px", fontSize: 32 }}>
              Check Your Email
            </h1>
            <p className={classes.p}>
              We’ve sent an email to {email} with instructions on how to create
              a new password.{" "}
            </p>
            <p className={classes.p}>
              It may take a few minutes to appear in your inbox. If you didn’t
              receive it, check your spam box or click below to resend.
            </p>
            <Box
              className={classes.form}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                className={classes.buttons}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Button className="submit-btn btn green" onClick={onSubmit}>
                  Sign In
                </Button>

                <p
                  style={{
                    color: "#4A4A4A",
                    margin: "46px 0px 9px",
                    fontWeight: "500",
                  }}
                >
                  Didn’t get an email?{" "}
                </p>

                <button className={classes.button} onClick={handleSubmit}>
                  Resend Email
                </button>
              </Box>
            </Box>
          </div>
        </Box>
      ) : (
        <div />
      )}
    </CSSTransition>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "460px",
  },
  p: {
    lineHeight: "24px",
    color: "#4A4A4A",
    marginBottom: "1.5rem",
    fontWeight: 500,
  },
  buttons: {
    [theme.breakpoints.up("sm")]: {
      margin: "95px 0px 0",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "55px 0px 0",
    },
  },
  button: {
    fontWeight: "bold",
    textTransform: "none",
    color: "#2A528C",
    fontSize: 16,
    textDecoration: "underline",
    fontFamily: "inherit",
    border: "none",
    background: "none",
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      color: "#6db0eb",
    },
  },
}));
