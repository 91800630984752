import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  FormControl,
  NativeSelect,
  Button,
  useMediaQuery,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CommentModal from "../Modals/CommentModal";
import { ClipLoader } from "react-spinners";
import { CSSTransition } from "react-transition-group";
import TimeAgo from "react-timeago";
import Logo from "../../../assets/pdf-icon.svg";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import edit from "../../../assets/edit.svg";
import remove from "../../../assets/remove.svg";
import axios from "axios";

export default function CaseTimeline(props) {
  const classes = useStyles();
  const {
    caseObj,
    comments,
    setComments,
    timeAgo,
    loading,
    setLoading,
    open,
    setOpen,
    notify,
    user,
    firms,
    commentDocuments,
    setCommentDocuments,
    fetchDocs,
    isSampleCase,
  } = props;
  const smScreen = useMediaQuery("(max-width:768px)");
  const mdScreen = useMediaQuery("(max-width:1024px)");

  const [anchorEl, setAnchorEl] = useState(null);
  const [fade, setFade] = useState(false);
  const [image, setImage] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);
  const [isEditCommentModal, setIsEditCommentModal] = useState(false);

  useEffect(() => {
    setFade(true);
  }, []);

  const sampleComments = [
    {
      username: "JFBossola",
      comment:
        "I will need a copy of your driver's license and vehicle registration.",
      createdAt: "Today",
    },
    {
      username: "JFBossola",
      comment:
        "Just posted the Auto Accident Sign Up Form, please complete by tomorrow afternoon.",
      createdAt: "Yesterday",
    },
  ];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseOver = (event, el) => {
    setSelectedComment(el);
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const deleteComment = (id) => {
    setLoading(true);
    axios
      .delete(`/comment/${id}`)
      .then((resp) => {
        notify("Comment successfully removed!", true);
        console.log("Comment deleted!", resp);
        let arr = [...comments];
        let index = arr.findIndex((el) => el.id == id);
        arr.splice(index, 1);
        setComments([...arr]);
        setAnchorEl(null);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        notify("Issue deleting comment. Please try again later", false);
        console.log("Error deleting comment", err, err.response, err.message);
      });
  };

  return (
    <CSSTransition in={fade} timeout={700} classNames="my-node">
      <div>
        {fade ? (
          <Grid
            className={
              comments.length !== 0 || isSampleCase
                ? classes.withComments
                : classes.withoutComments
            }
          >
            {!loading ? (
              <div>
                {!isSampleCase ? (
                  <div>
                    <p
                      style={{
                        display: comments.length !== 0 && "none",
                      }}
                    >
                      There are currently no comments
                    </p>
                    <div className={classes.withComments}>
                      <Button
                        className="btn case"
                        onClick={() => {
                          setOpen(true);
                          setSelectedComment(null);
                        }}
                      >
                        + Add a Comment
                      </Button>
                    </div>
                  </div>
                ) : (
                  //SAMPLE COMMENT
                  <Box className="auto-height-mobile">
                    <Box p={smScreen ? 0 : 2} className={classes.scroll}>
                      {sampleComments.map((el, i) => (
                        <Box key={i} className={classes.comment}>
                          <p style={{ fontWeight: "bold" }}>
                            {el.username} posted a comment
                          </p>

                          <Grid container style={{ margin: "8px auto 32px" }}>
                            <Grid item xs={12}>
                              <p style={{ margin: "8px auto" }}>{el.comment}</p>
                            </Grid>
                          </Grid>
                          <p
                            style={{
                              color: "#D3D3D3",
                              fontWeight: "600",
                              fontSize: 12,
                              margin: 0,
                            }}
                          >
                            {el.createdAt}
                          </p>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}


                <Box
                  className="auto-height-mobile"
                  style={{ display: comments.length === 0 && "none" }}
                >
                  <Box p={smScreen ? 0 : 2} className={classes.scroll}>
                    {comments.map((el, i) => (
                      <Box key={i} className={classes.comment}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {console.log("HEYYYYYY", el.documents)}
                          <p style={{ fontWeight: "bold" }}>
                            <span style={{ textTransform: "capitalize" }}>
                              {el.author?.firstName}
                            </span>{" "}
                            posted a comment
                          </p>

                          <Button
                            onClick={(e) => mdScreen && handleMouseOver(e, el)}
                            onMouseOver={(e) =>
                              !mdScreen && handleMouseOver(e, el)
                            }
                            className="ellipses-icon"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <FiberManualRecordIcon />
                            <FiberManualRecordIcon />
                            <FiberManualRecordIcon />
                          </Button>
                        </div>
                        <Grid container style={{ margin: "8px auto 32px" }}>
                          <Grid item xs={12}>
                            <p style={{ margin: "8px auto" }}>{el.comment}</p>
                          </Grid>
                          <Grid item xs={12}>
                            {el.documents ? (
                              el.documents?.fileName?.includes("pdf") ? (
                                <div className={classes.image}>
                                  <embed
                                    src={el.documents.document}
                                    width="100%"
                                    height="100%"
                                    style={{ margin: "16px auto" }}
                                  />
                                </div>
                              ) : (
                                // <div className={classes.image}>
                                //   <img
                                //     onClick={() => window.open(el.documents.document, "_blank")}
                                //     src={el.documents.document}
                                //     alt="Image"
                                //     style={{
                                //       width: "100%",
                                //       height: "100%",
                                //       objectFit: "contain",
                                //       margin: "16px auto",
                                //       cursor: 'pointer'
                                //     }}
                                //   />
                                // </div>
                                <div className={classes.image} style={{ cursor: 'pointer' }}>
                                  <img
                                    src={el.documents.document}
                                    alt="Image"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "contain",
                                      margin: "16px auto",
                                    }}
                                    onClick={e => window.open(el.documents.document, "_blank")}
                                  />
                                </div>
                              )
                            ) : null}
                          </Grid>
                        </Grid>
                        <p
                          style={{
                            color: "#D3D3D3",
                            fontWeight: "600",
                            fontSize: 12,
                            margin: 0,
                          }}
                        >
                          {timeAgo(el.createdAt)}
                          {/* <TimeAgo date={timeAgo(el.createdAt)} /> */}
                        </p>
                      </Box>
                    ))}
                    {/* SHOW MENU ON HOVER */}
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      MenuListProps={{ onMouseLeave: handleClose }}
                      getContentAnchorEl={null}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "center",
                        horizontal: "center",
                      }}
                      PaperProps={{
                        style: {
                          transform: "translateY(10px)",
                        },
                      }}
                      className={classes.menu}
                    >
                      <MenuItem
                        onClick={() => setIsEditCommentModal(true)}
                        className={classes.menuItem}
                        style={{
                          padding: "16px 40px 8px 17px",
                        }}
                      // disabled={isSampleTemplate}
                      >
                        <img
                          className={classes.menuIcon}
                          src={edit}
                          alt="Preview Icon"
                        />
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => deleteComment(selectedComment.id)}
                        style={{
                          borderTop: "1px solid #D3D3D3",
                          padding: "8px 40px 16px 17px",
                        }}
                        className={classes.menuItem}
                      // disabled={isSampleTemplate}
                      >
                        <img
                          className={classes.menuIcon}
                          src={remove}
                          alt="Remove Icon"
                          style={{
                            width: 12,
                            height: 12,
                            marginRight: 12,
                          }}
                        />
                        Remove
                      </MenuItem>
                    </Menu>
                  </Box>
                </Box>
              </div>
            ) : (
              <div
                className="sweet-loading"
                style={{
                  width: 100,
                  height: 75,
                  margin: "5% auto",
                  minHeight: "calc(100vh)",
                  display: comments ? "flex" : "none",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ClipLoader
                  style={{ position: "absolute" }}
                  size={75}
                  color={"rgb(47, 86, 138)"}
                />
              </div>
            )}
            {(open || isEditCommentModal) && (
              <CommentModal
                selectedComment={selectedComment}
                comments={comments}
                setComments={setComments}
                open={isEditCommentModal ? isEditCommentModal : open}
                setOpen={isEditCommentModal ? setIsEditCommentModal : setOpen}
                caseObj={caseObj}
                notify={notify}
                user={user}
                firms={firms}
                commentDocuments={commentDocuments}
                setCommentDocuments={setCommentDocuments}
                loading={loading}
                setLoading={setLoading}
                fetchDocs={fetchDocs}
              />
            )}
          </Grid>
        ) : (
          <div />
        )}
      </div>
    </CSSTransition>
  );
}

const useStyles = makeStyles((theme) => ({
  withComments: {
    display: "flex",
    backgroundColor: "#FAFAFA",
    flexDirection: "column",
    justifyContent: "center",
  },
  withoutComments: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    [theme.breakpoints.up(896)]: {
      minHeight: "100vh",
    },
  },
  scroll: {
    margin: "auto",
    height: "85vh",
    overflowY: "auto",
  },
  comment: {
    width: "90%",
    backgroundColor: "#fff",
    borderRadius: 10,
    margin: "0 auto 5%",
    padding: "2% 5%",
  },
  image: {
    width: "100%",
    height: 500,
  },
  menu: {
    "& .MuiList-root.MuiMenu-list.MuiList-padding": {
      padding: 0,
    },
  },
  menuItem: {
    width: 154,
    maxWidth: "100%",
  },
  menuIcon: {
    width: 12,
    height: 12,
    marginRight: 12,
  },
  icon: {
    width: 31,
    height: 31,
    marginRight: 17,
  },
}));
