import { forwardRef, useState } from "react";
import DatePicker, { setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextField } from "@material-ui/core";
import { ReactComponent as CalendarIcon } from "../../assets/calendar.svg";

const DatePickerInput = ({ placeholder, startDate, setStartDate }) => {
  const inputProps = {
    variant: "outlined",
    color: "primary",
    style: styles.input,
    inputProps: { maxLength: 50 },
  };

  const CustomTextField = forwardRef(({ value, onClick }, ref) => (
    <TextField
      label={placeholder}
      value={value}
      {...inputProps}
      onClick={onClick}
    />
  ));

  console.log("START DATE", startDate)

  return (
    <div
      style={{
        position: "relative",
        zIndex: 2,
      }}
    >
      <DatePicker
        placeholderText={placeholder}
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        customInput={<CustomTextField />}
        dateFormat={"MM/dd/yyyy"}
        showMonthDropdown
        showYearDropdown
        className={"dp-full-width"}
        wrapperClassName={"dp-full-width"}
      />
      <div style={{ position: "absolute", top: 27, right: 12 }}>
        <CalendarIcon />
      </div>
    </div>
  );
};

const styles = {
  input: {
    margin: "2.5% 0",
    textAlign: "left",
    width: "100%",
  },
};

export default DatePickerInput;
