import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  FormControl,
  NativeSelect,
  Button,
  useMediaQuery,
  Tab,
  Tabs,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Trash from "../../assets/trash.svg";
import Modal from "@material-ui/core/Modal";
import axios from "axios";
import { CSSTransition } from "react-transition-group";
import { ClipLoader } from "react-spinners";
import CaseTimeline from "./Tabs/CaseTimeline";
import CaseSummary from "./Tabs/CaseSummary";
import ESignRequests from "./Tabs/ESignRequests";
import PropTypes from "prop-types";
import "react-notifications/lib/notifications.css";
import { Redirect } from "react-router";
import ClientDetails from "./Tabs/ClientDetails";
import ClientDocuments from "./Tabs/ClientDocuments";
import DocumentModal from "./Modals/DocumentModal";
import { AiOutlineLeft } from "react-icons/ai";
import { Route } from "react-router-dom";
import Select, { components } from "react-select";
import EditLabelModal from "./Modals/EditLabelModal";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as ChevronUp } from "../../assets/chevron-up-large.svg";
import { ReactComponent as ChevronDown } from "../../assets/chevron-down-large.svg";
import moment from "moment";


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const mobile = useMediaQuery("(max-width:895px)");

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          py={mobile ? 5 : 8}
          px={mobile ? 4 : 8}
          style={{
            background: "#FAFAFA",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Case(props) {
  const [loaded, setLoaded] = useState(false);
  const [comments, setComments] = useState([]);
  const [clientDocuments, setClientDocuments] = useState([]);
  const [eSignDocuments, setESignDocuments] = useState([]);
  const [assignedDocuments, setAssignedDocuments] = useState([]);
  const [commentDocuments, setCommentDocuments] = useState(null);
  const [caseObj, setCaseObj] = useState(props.location.state?.caseObj || {});
  const [open, setOpen] = useState(false);
  const [fade, setFade] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isEditLabelModal, setIsEditLabelModal] = useState(false);
  const [editLabelLoading, setEditLabelLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const mobile = useMediaQuery("(max-width:895px)");
  const classes = useStyles();
  const leftColumnButtonWidth = 345;
  const {
    templates,
    notify,
    firms,
    setModal,
    user,
    status,
    setStatus,
    statuses,
    setStatuses,
    cases,
    setCases,
  } = props;
  const isSampleCase = props.location.state?.isSampleCase;
  const sampleStatuses = [
    {
      name: "In Negotiation",
      label: "In Negotiation",
      value: "in negotiation",
    },
    { name: "Closed", label: "Closed", value: "closed" },
  ];

  useEffect(() => {
    if (!isSampleCase) {
      fetchCase();
    }
    setFade(true);
  }, []);

  useEffect(() => {
    if (caseObj) fetchDocs();
  }, [caseObj])

  const sampleClientDocuments = [
    {
      author: { firstName: "William", lastName: "Johnson" },
      fileName: "../../assets/pdf.png",
      title: "Driver's License",
    },
    {
      author: { firstName: "William", lastName: "Johnson" },
      fileName: "../../assets/pdf.png",
      title: "Vehicle Registration",
    },
  ];

  const fetchCase = (e) => {
    setLoading(true);
    //Gets case info
    const params = new URLSearchParams(window.location.search)
    let caseId = params.get('id')
    let emailLink = caseId ? true : false

    axios
      .get(`/posts/post/${emailLink ? caseId : props.location.state.caseObj?.id}`)
      .then((resp) => {
        let statusArr = [...resp.data.post.Statuses];
        statusArr.push({ name: "edit labels", isButton: true });
        setCaseObj(resp.data.post);
        setComments(resp.data.post.comments);

        //! Hardcoded statuses for Jared's firm
        if (resp.data.post.firmId == 53) setStatuses([
          {
            name: 'Investigation',
            value: 'Investigation',
            label: 'Investigation'
          },
          {
            name: 'Medical Treatment',
            value: 'Medical Treatment',
            label: 'Medical Treatment'
          },
          {
            name: 'Under Review',
            value: 'Under Review',
            label: 'Under Review'
          },
          {
            name: 'In Negotiation',
            value: 'In Negotiation',
            label: 'In Negotiation'
          },
          {
            name: 'Settled',
            value: 'Settled',
            label: 'Settled'
          },
          {
            name: 'Litigation',
            value: 'Litigation',
            label: 'Litigation'
          },
          {
            name: 'Disengaged',
            value: 'Disengaged',
            label: 'Disengaged'
          },
          {
            name: 'Closed',
            value: 'Closed',
            label: 'Closed'
          },
        ])
        else setStatuses(statusArr);

        setSelectedStatus({
          name: resp.data.post.status,
          value: resp.data.post.status,
          label: resp.data.post.status,
        });
        setESignDocuments(resp.data.post.helloSignDocs);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error looking up case", err.response);
      });
  };

  const fetchDocs = (e) => {
    // Gets all case
    axios
      .get(`/documents/case?postId=${caseObj.id}&firmId=${firms[0]?.Firm.id}`)
      .then((resp) => {
        setClientDocuments(resp.data.documents.filter(el => el.adminId));
      })
      .catch((err) =>
        console.log("Error getting case documents", err.response)
      );
  };

  const handleStatusChange = (value) => {
    setSelectedStatus({
      name: value.name,
      value: value.name,
      label: value.name,
    });
    updateCase(value.name);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateCase = (status) => {
    setLoading(true);
    axios
      .put(`/posts/post/${caseObj.id}`, { status })
      .then((res) => {
        setLoading(false);
        let arr = [...cases];
        let i = arr.findIndex((el) => el.id == res.data.post.id);
        arr[i].status = res.data.post.status;
        setCases([...arr]);
        setStatus(res.data.post.status);
        fetchCase();
        return notify("Case updated!", true);
      })
      .catch((err) => {
        setLoading(false);
        console.log(
          "Error updating case",
          err.response ? err.response.data.message : err
        );
        return notify("Error updating case. Please try again.", false);
      });
  };

  const deleteStatus = (idsArr) => {
    setLoading(true);
    axios
      .delete(`/status/${caseObj.id}`, { data: idsArr })
      .then((res) => {
        let arr = [...statuses];
        arr = arr.filter((el) => !idsArr?.includes(el.id));
        setStatuses([...arr]);
        setLoading(false);
        setIsEditLabelModal(false);
        notify("Statuses deleted!", true);
        fetchCase();
      })
      .catch((err) => {
        setLoading(false);
        console.log(
          "Error deleting statuses",
          err.response ? err.response.data.message : err
        );
        return notify("Error deleting statuses. Please try again.", false);
      });
  };

  const handleClick = (e) => {
    e.stopPropagation();
    if (isSampleCase) {
      props.history.push("/client-details", {
        caseObj: {},
        isSampleCase: true,
      });
    } else {
      props.history.push("/client-details", {
        caseObj: caseObj,
      });
    }
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      borderBottomRadius: 17,
      overflow: "hidden",
      top: 35,
      borderBottomRightRadius: 17,
      borderBottomLeftRadius: 17,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderTopWidth: 0,
      width: 200,
      padding: 0,
    }),
    control: () => ({
      display: "flex",
      border: "1px solid #C4C4C4",
      borderTopRightRadius: 17,
      borderTopLeftRadius: 17,
      borderBottomLeftRadius: !menuOpen && 17,
      borderBottomRightRadius: !menuOpen && 17,
      borderBottomWidth: menuOpen && 0,
      fontSize: 16,
      width: 172,
      padding: 0,
      paddingLeft: 3,
      textTransform: "capitalize",
    }),
  };

  const MyOption = ({ data, innerProps, innerRef }) => {
    return (
      <div>
        {data?.isButton ? (
          <div style={{ marginBottom: 7 }}>
            <div
              style={{
                marginTop: statuses.length > 1 ? 6 : 0,
                marginBottom: 4,
                borderTop: statuses.length > 1 && "1px solid #D3D3D3",
              }}
            />
            <Button
              onClick={() => {
                setIsEditLabelModal(!isEditLabelModal);
              }}
              className={classes.optionButton}
            >
              <EditIcon style={{ marginLeft: 4, marginRight: 8 }} />
              {data.name}
            </Button>
          </div>
        ) : (
          <Button
            // ref={innerRef}
            {...innerProps}
            className={classes.optionButton}
          // onClick={() => {
          //   handleStatusChange(data.name);
          // }}
          >
            {data.name}
          </Button>
        )}
      </div>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? <ChevronUp /> : <ChevronDown />}
      </components.DropdownIndicator>
    );
  };

  //Toggles between status in Select input
  // const handleSelectChange = (e) => {
  //   updateCase(e.target.name);
  // };

  // Unassigns provider from case
  function unassignProvider(userId, businessId, postId) {
    if (window.confirm("Remove this provider from case?")) {
      axios
        .delete(
          `/usersbusiness?userId=${userId}&businessId=${businessId}&postId=${postId}`
        )
        .then((resp) => {
          fetchCase();
        })
        .catch((err) => console.log("Error deleting provider", err.response));
    }
  }

  // Deletes case
  function deleteCase() {
    if (window.confirm("Are you sure you want to remove this case?")) {
      axios
        .delete(`/posts/post/${caseObj.id}`)
        .then((resp) => {
          window.location = "/cases";
        })
        .catch((err) => console.log("Error deleting case", err.response));
    }
  }

  const addCaseStatus = (newStatusArr) => {
    setLoading(true);
    const postId = caseObj.id;
    let newStatuses = [];
    newStatusArr.map((el) => {
      if (el != "") {
        return newStatuses.push({
          name: el,
        });
      }
    });

    axios
      .post(`/status/${postId}`, newStatuses)
      .then((resp) => {
        setLoading(false);
        let arr = [...statuses];
        resp.data.status.map((el) => arr.splice(arr.length - 1, 0, el));
        setStatuses([...arr]);
        notify("Statuses added!", true);
        setIsEditLabelModal(false);
      })
      .catch((err) => {
        setLoading(false);
        notify("Error adding statuses", false);
        console.log("Error adding statuses", err);
      });
  };

  //Displays when info was posted
  const timeAgo = (createdAt) => {
    let date = moment(createdAt).format("MM/DD/YY h:mm a");
    return date;
  };

  console.log("COMMENTS", comments)

  return (
    <CSSTransition in={fade} timeout={700} classNames="my-node">
      <div>
        {fade ? (
          <div>
            {isEditLabelModal && (
              <EditLabelModal
                setOpen={setIsEditLabelModal}
                loading={editLabelLoading}
                data={statuses}
                handleClick={addCaseStatus}
                handleDelete={deleteStatus}
              />
            )}
            {caseObj && (
              <Grid container className={classes.root}>
                <Grid item xs={mobile ? 12 : 5}>
                  <Box py={mobile ? 1 : 5} px={mobile ? 2 : 10}>
                    <Grid container direction="row" justifyContent="flex-start">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          window.location = "/cases";
                        }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          textDecoration: "underline",
                          marginTop: mobile && 24,
                        }}
                      >
                        <AiOutlineLeft style={{ marginRight: ".4rem" }} /> Back
                      </button>
                    </Grid>
                    <Box my={4} mx={"15px"}>
                      <h3
                        style={{
                          fontSize: 24,
                          fontWeight: "600",
                          lineHeight: "30px",
                          margin: "30px 0px 10px 0px",
                        }}
                      >
                        {isSampleCase
                          ? "William Johnson"
                          : `${caseObj?.author?.firstName} ${caseObj?.author?.lastName}`}
                      </h3>
                      <h3
                        style={{
                          fontSize: 20,
                          fontWeight: "600",
                          margin: "16px 0px 24px 0px",
                          // lineHeight: "30px",
                          textTransform: "capitalize",
                        }}
                      >
                        Case:{" "}
                        <span
                          style={{
                            fontWeight: "600",
                            textTransform: "capitalize",
                          }}
                        >
                          {isSampleCase ? "Johnson 2022" : caseObj.title}
                        </span>
                      </h3>

                      <button
                        style={{
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          textDecoration: "underline",
                          padding: 0,
                        }}
                        onClick={(e) => !isSampleCase && handleClick(e)}
                      >
                        View Client Details
                      </button>
                    </Box>
                    <Box mt={5} mb={6} mx={"15px"} className={classes.status}>
                      <span style={{ marginRight: 6 }}>Status:</span>
                      <Select
                        value={
                          isSampleCase ? sampleStatuses[0] : selectedStatus
                        }
                        options={isSampleCase ? sampleStatuses : statuses}
                        components={{ Option: MyOption, DropdownIndicator }}
                        styles={customStyles}
                        onChange={!isSampleCase && handleStatusChange}
                        className={classes.dropdown}
                        onBlur={(event) => event.preventDefault()}
                        onMenuOpen={() => setMenuOpen(true)}
                        onMenuClose={() => setMenuOpen(false)}
                      />
                      {/* <div
                          style={{
                            position: "absolute",
                            top: 7,
                            right: 9,
                            zIndex: -1,
                          }}
                        >
                          {menuOpen ? <ChevronUp /> : <ChevronDown />}
                        </div> */}
                      {/* <NativeSelect
                          className={classes.select}
                          disableUnderline
                          value={caseObj.status}
                          onChange={(e) => handleSelectChange(e)}
                        >
                          {isSampleCase ? (
                            <option value="In Negotiation">
                              In Negotiation
                            </option>
                          ) : (
                            <>
                              {!caseObj.status && (
                                <option value="loading"></option>
                              )}
                              {statuses.map((el) => (
                                <option value={el}>{el}</option>
                              ))}
                            </>
                          )}
                        </NativeSelect> */}
                    </Box>
                    <div className="case-tabs">
                      <Tabs
                        variant="fullWidth"
                        orientation="vertical"
                        value={value}
                        indicatorColor="transparent"
                        onChange={handleTabChange}
                        aria-label="Vertical tabs example"
                        className="tabs"
                        classes={{
                          indicator: classes.indicator,
                        }}
                      >
                        <Tab
                          label={
                            <span
                              style={{
                                textTransform: "capitalize",
                                fontWeight: "500",
                                fontSize: 16,
                                backgroundColor: value == 0 && "#F7F7F7",
                                maxWidth: !mobile && leftColumnButtonWidth,
                                marginRight: "auto",
                              }}
                            >
                              Chat
                            </span>
                          }
                          {...a11yProps(0)}
                        />
                        <Tab
                          label={
                            <span
                              style={{
                                textTransform: "capitalize",
                                fontWeight: "500",
                                fontSize: 16,
                                backgroundColor: value == 1 && "#F7F7F7",
                                maxWidth: !mobile && leftColumnButtonWidth,
                                marginRight: "auto",
                              }}
                            >
                              Case Summary
                            </span>
                          }
                          {...a11yProps(1)}
                        />
                        <Tab
                          label={
                            <span
                              style={{
                                textTransform: "capitalize",
                                fontWeight: "500",
                                fontSize: 16,
                                backgroundColor: value == 2 && "#F7F7F7",
                                maxWidth: !mobile && leftColumnButtonWidth,
                                marginRight: "auto",
                              }}
                            >
                              E-Sign Requests
                            </span>
                          }
                          {...a11yProps(2)}
                        />
                        <Tab
                          label={
                            <span
                              style={{
                                textTransform: "capitalize",
                                fontWeight: "500",
                                fontSize: 16,
                                backgroundColor: value == 3 && "#F7F7F7",
                                maxWidth: !mobile && leftColumnButtonWidth,
                                marginRight: "auto",
                              }}
                            >
                              Client Documents
                            </span>
                          }
                          {...a11yProps(3)}
                        />
                      </Tabs>
                    </div>
                    <div
                      className={classes.divider}
                      style={{
                        maxWidth: mobile ? "100%" : leftColumnButtonWidth,
                      }}
                    ></div>
                    <Box
                      my={6}
                      mx={"15px"}
                      className={classes.providers}
                      style={{
                        maxWidth: mobile ? "100%" : leftColumnButtonWidth,
                      }}
                    >
                      <p style={{ fontWeight: 600 }}>Providers:</p>
                      {!isSampleCase ? (
                        caseObj?.providers?.map((el, i) => (
                          <Grid container key={i}>
                            <Grid
                              item
                              xs
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              {el.Business.name}
                              <img
                                src={Trash}
                                alt="Trash"
                                onClick={() =>
                                  unassignProvider(
                                    el.userId,
                                    el.businessId,
                                    el.postId
                                  )
                                }
                                style={{
                                  width: 16,
                                  height: 18,
                                  cursor: "pointer",
                                }}
                              />
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <Grid container>
                          <Grid
                            item
                            xs
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            A1 Imaging - Pembroke Pines
                            <img
                              src={Trash}
                              alt="Trash"
                              style={{
                                width: 16,
                                height: 18,
                                cursor: "pointer",
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                    <div
                      className={classes.divider}
                      style={{
                        maxWidth: mobile ? "100%" : leftColumnButtonWidth,
                      }}
                    ></div>
                    <Box
                      mx={"15px"}
                      style={{
                        maxWidth: mobile ? "100%" : leftColumnButtonWidth,
                      }}
                      className="case-options"
                    >
                      <Box className={classes.closeCase}>
                        <Button
                          style={{
                            display: caseObj?.status === "Closed" && "none",
                            position: "relative",
                          }}
                          onClick={() => !isSampleCase && updateCase("Closed")}
                          className={classes.button}
                          disabled={loading}
                        >
                          {loading ? (
                            <div
                              className="sweet-loading"
                              style={{
                                position: "absolute",
                                width: 15,
                                height: 15,
                                alignItems: "center",
                                width: "100%",
                                top: 8,
                              }}
                            >
                              <ClipLoader
                                size={20}
                                color={"rgb(47, 86, 138)"}
                                loading={loading}
                              />
                            </div>
                          ) : (
                            <span>Close Case</span>
                          )}
                        </Button>
                      </Box>
                      <Button
                        onClick={!isSampleCase && deleteCase}
                        className={classes.deleteButton}
                        disabled={loading}
                      >
                        <span>Delete</span>
                      </Button>
                    </Box>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs
                  style={{
                    background: "#FAFAFA",
                  }}
                >
                  <TabPanel value={value} index={0}>
                    <CaseTimeline
                      cases={cases}
                      setCases={setCases}
                      comments={comments}
                      setComments={setComments}
                      open={open}
                      setOpen={setOpen}
                      timeAgo={timeAgo}
                      notify={notify}
                      caseObj={caseObj}
                      user={props.user}
                      firms={firms}
                      commentDocuments={commentDocuments}
                      setCommentDocuments={setCommentDocuments}
                      loading={loading}
                      setLoading={setLoading}
                      fetchDocs={fetchDocs}
                      isSampleCase={isSampleCase}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <CaseSummary
                      caseObj={caseObj}
                      setCaseObj={setCaseObj}
                      fetchCase={fetchCase}
                      loading={loading}
                      setLoading={setLoading}
                      cases={cases}
                      notify={notify}
                      isSampleCase={isSampleCase}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <ESignRequests
                      templates={templates}
                      // documents={caseObj?.helloSignDocs}
                      caseId={caseObj?.id}
                      clientId={caseObj?.userId}
                      fetchCase={fetchCase}
                      notify={notify}
                      open={open}
                      setOpen={setOpen}
                      firms={firms}
                      user={user}
                      fetchCase={fetchCase}
                      isSampleCase={isSampleCase}
                      assignedDocuments={assignedDocuments}
                      setAssignedDocuments={setAssignedDocuments}
                      eSignDocuments={eSignDocuments}
                      setESignDocuments={setESignDocuments}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <ClientDocuments
                      documentsObj={caseObj?.helloSignDocs}
                      timeAgo={timeAgo}
                      notify={notify}
                      open={open}
                      setOpen={setOpen}
                      caseObj={caseObj}
                      firms={firms}
                      setModal={setModal}
                      fetchDocs={fetchDocs}
                      clientDocuments={
                        isSampleCase ? sampleClientDocuments : clientDocuments
                      }
                      setClientDocuments={setClientDocuments}
                      user={user}
                      isSampleCase={isSampleCase}
                    />
                  </TabPanel>
                </Grid>
              </Grid>
            )}

            <Box
              mx={"15px"}
              style={{
                maxWidth: mobile ? "100%" : leftColumnButtonWidth,
              }}
              className="case-options-mobile"
            >
              <Box className={classes.closeCase}>
                <Button
                  style={{
                    display: caseObj?.status === "Closed" && "none",
                    position: "relative",
                  }}
                  onClick={() => !isSampleCase && updateCase("Closed")}
                  className={classes.button}
                  disabled={loading}
                >
                  {loading ? (
                    <div
                      className="sweet-loading"
                      style={{
                        position: "absolute",
                        width: 15,
                        height: 15,
                        alignItems: "center",
                        width: "100%",
                        top: 8,
                      }}
                    >
                      <ClipLoader
                        size={20}
                        color={"rgb(47, 86, 138)"}
                        loading={loading}
                      />
                    </div>
                  ) : (
                    <span>Close Case</span>
                  )}
                </Button>
              </Box>
              <Button
                onClick={!isSampleCase && deleteCase}
                className={classes.deleteButton}
                disabled={loading}
              >
                <br /><br /><br />
                <span>Delete</span>
              </Button>
            </Box>
            {/* {caseObj && (
              <ClientDetails
                loading={loading}
                caseObj={caseObj}
                setCaseObj={setCaseObj}
                notify={notify}
                openClientDetails={openClientDetails}
                setOpenClientDetails={setOpenClientDetails}
                loading={loading}
                setLoading={setLoading}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                phone={phone}
                setPhone={setPhone}
                email={email}
                setEmail={setEmail}
                updateClient={updateClient}
                isSampleCase={isSampleCase}
              />
            )} */}
          </div>
        ) : (
          <div />
        )}
      </div>
    </CSSTransition>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
    color: "#000",
    lineHeight: "24px",
    backgroundColor: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  // select: {
  //   border: "1px solid #C4C4C4",
  //   padding: "2px 25px 1px 13px",
  //   textAlign: "left",
  //   width: 172,
  //   borderRadius: 25,
  //   color: "#4CDA64",
  //   display: "flex",
  //   alignItems: "center",
  //   color: "#4CDA64",
  //   "& .MuiSvgIcon-root": {
  //     color: "#000000",
  //     marginRight: 13,
  //   },
  //   [theme.breakpoints.down(895)]: {
  //     paddingLeft: "8px !important",
  //     "& .MuiSvgIcon-root": {
  //       marginRight: 8,
  //     },
  //     "& .MuiInputBase-input": {
  //       padding: "6px",
  //       margin: "0 !important",
  //     },
  //   },
  // },
  // selectEmpty: {
  //   marginTop: theme.spacing(2),
  // },
  dropdown: {
    backgroundColor: "transparent",
    "& .css-1okebmr-indicatorSeparator": {
      display: "none !important",
    },
    "& .css-4ljt47-MenuList": {
      padding: 0,
    },
  },
  status: {
    display: "flex",
    alignItems: "center",
  },
  divider: {
    borderBottom: "1px solid #D3D3D3",
    margin: "50px 14px",
  },
  viewClient: {
    textDecoration: "underline",
    fontSize: 15,
    transition: "color 0.2s linear",
    "&:hover": {
      color: "#757575",
    },
  },
  closeCase: {
    marginTop: 170,
    marginBottom: 20,
    "& > *": {
      width: "100%",
      border: "1px solid #2A528C",
      borderRadius: 25,
      color: "#2A528C",
      fontWeight: "600",
      fontSize: 16,
      textTransform: "none",
      transition: "all 0.2s linear",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#2A528C !important",
      },
    },
    [theme.breakpoints.down(896)]: {
      marginTop: 80,
    },
    [theme.breakpoints.down(415)]: {
      marginTop: 50,
    },
  },
  button: {
    background: "none",
    height: 40,
    cursor: "pointer",
  },
  deleteButton: {
    width: "100%",
    marginBottom: 20,
    borderRadius: 25,
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    "& > *": {
      color: "#2A528C",
      fontSize: 14,
      textTransform: "none",
    },
  },
  providers: {
    "& > *": {
      padding: "10px 0",
    },
  },
  optionButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    borderRadius: 0,
    textTransform: "capitalize",
    fontSize: 16,
    height: 38,
    padding: "7px 14px",
    "&:hover": {
      backgroundColor: "#D9D9D9 !important",
    },
  },
}));
