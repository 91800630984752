import { Box, Button, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { CSSTransition } from "react-transition-group";
import DatePickerInput from "../DateInput"
import moment from 'moment';


export default function CaseSummary(props) {
  const { caseObj, setCaseObj, loading, setLoading, notify, isSampleCase } =
    props;
  const classes = useStyles();
  const [title, setTitle] = useState(caseObj.title ? moment(caseObj.title).toDate() : "");
  const [caseType, setCaseType] = useState(
    caseObj.caseType ? caseObj.caseType : ""
  );
  const [content, setContent] = useState(
    caseObj.content ? caseObj.content : ""
  );
  const [fade, setFade] = useState(false);



  useEffect(() => {
    setFade(true);
  }, []);

  const inputProps = {
    variant: "outlined",
    color: "primary",
    inputProps: { maxLength: 50 },
  };

  const updateCase = () => {
    setLoading(true);
    console.log("title", title);
    console.log("case type", caseType);
    console.log("content", content);
    axios
      .put(`/posts/post/${caseObj.id}`, { title: moment(title).format("MM/DD/YYYY"), caseType, content })
      .then((res) => {
        setLoading(false);
        const { title, content, caseType } = res.data.post;
        notify("Case updated!", true);
        console.log("res here", res);
        props.fetchCase();
      })
      .catch((err) => {
        setLoading(false);
        notify("Error updating case. Please try again.", false);
        console.log(
          "Error updating case",
          err.response ? err.response.data.message : err
        );
      });
  };

  return (
    <CSSTransition in={fade} timeout={700} classNames="my-node">
      <div>
        {fade ? (
          <Box className={classes.root}>
            <form className={classes.form}>
              <p style={{ fontWeight: "700" }}>Case Summary</p>
              {/* <TextField
                label="Case Name"
                value={isSampleCase ? "Johnson 2022" : title}
                fullWidth
                name="case name"
                onChange={(e) => setTitle(e.target.value)}
                {...inputProps}
                inputProps={{ style: { textTransform: "capitalize" } }}
              /> */}
              {console.log("DATE OF LOSS", title)}

              <DatePickerInput
                placeholder="Date of Loss"
                startDate={title}
                setStartDate={setTitle}
              />
              <TextField
                label="Case Type"
                name="case type"
                value={isSampleCase ? "Car Accident" : caseType}
                fullWidth
                onChange={(e) => setCaseType(e.target.value)}
                {...inputProps}
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
              <TextField
                label="Summary"
                value={isSampleCase ? "Occurred on May 08, 2022" : content}
                fullWidth
                multiline
                // rows={5}
                name="Summary"
                onChange={(e) => setContent(e.target.value)}
                variant="outlined"
                color="primary"
              />
              <Grid style={{ textAlign: "right" }}>
                <Button
                  onClick={() => !isSampleCase && updateCase()}
                  className="view-case"
                  disabled={loading}
                  style={{ margin: "25px auto", position: "relative" }}
                >
                  {loading ? (
                    <div
                      className="sweet-loading"
                      style={{
                        position: "absolute",
                        width: 15,
                        height: 15,
                        top: 8,
                      }}
                    >
                      <ClipLoader
                        size={20}
                        color={"rgb(47, 86, 138)"}
                        loading={loading}
                      />
                    </div>
                  ) : (
                    <span>Save</span>
                  )}
                </Button>
              </Grid>
            </form>
          </Box>
        ) : (
          <div />
        )}
      </div>
    </CSSTransition>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    backgroundColor: "#fff",
    borderRadius: 10,
    margin: "0 auto 5%",
    padding: "2% 5%",
  },
  form: {
    "& .MuiTextField-root": {
      margin: "8px 0px",
    },
  },
}));
