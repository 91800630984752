import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  useMediaQuery,
} from "@material-ui/core";
import pdf from "../../../assets/pdf-icon.svg";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import preview from "../../../assets/preview.svg";
import download from "../../../assets/download.svg";
import edit from "../../../assets/edit.svg";
import remove from "../../../assets/remove.svg";
import { ClipLoader } from "react-spinners";
import { Done } from "@material-ui/icons";

export default function Assigned(props) {
  const classes = useStyles();
  const mobileScreen = useMediaQuery("(max-width:1024px)");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    templates,
    handleDelete,
    setESignOpen,
    setActiveESignRequest,
    activeESignRequest,
    handleDownload,
    handlePreview,
    loading,
  } = props;

  const handleMouseOver = (event, el) => {
    setActiveESignRequest(el);
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {loading ? (
        <div
          className="sweet-loading"
          style={{
            width: 15,
            height: 15,
            margin: "5% auto",
            justifyContent: "center",
          }}
        >
          <ClipLoader size={75} color={"rgb(47, 86, 138)"} loading={loading} />
        </div>
      ) : (
        <>
          {props.documents?.map((el, i) => (
            <Grid key={i} container className={classes.row}>
              <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
                <div
                  style={{
                    display: "flex",
                    marginRight: 16,
                    backgroundImage: `url(${pdf})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: 31,
                    width: 31,
                  }}
                />
                <div>
                  <div style={{ textTransform: "capitalize" }}>{el.title}</div>
                  {el.status_code === "signed" && (
                    <div
                      style={{
                        fontSize: 13,
                        display: "flex",
                        alignItems: "center",
                        marginTop: -2,
                      }}
                    >
                      <Done style={{ color: "green", marginRight: 3 }} />
                      Signed
                    </div>
                  )}
                </div>
              </div>
              <Grid item xs={1} key={i}>
                <div
                  onClick={(e) => mobileScreen && handleMouseOver(e, el)}
                  onMouseOver={(e) => !mobileScreen && handleMouseOver(e, el)}
                  className="ellipses-icon"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 40,
                  }}
                >
                  <FiberManualRecordIcon />
                  <FiberManualRecordIcon />
                  <FiberManualRecordIcon />
                </div>
              </Grid>
            </Grid>
          ))}
          {/* SHOW MENU ON HOVER */}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            MenuListProps={{ onMouseLeave: handleClose }}
            onClose={handleClose}
            // anchorOrigin={{
            //   vertical: "bottom",
            //   horizontal: "left",
            // }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem
              onClick={() => handlePreview(activeESignRequest)}
              className={classes.menuItem}
            >
              <img
                className={classes.menuIcon}
                src={preview}
                alt="Preview Icon"
                style={{ width: 12, height: 12, marginRight: 12 }}
              />
              Preview/Edit
            </MenuItem>
            <MenuItem
              onClick={() => setESignOpen(true)}
              className={classes.menuItem}
            >
              <img
                className={classes.menuIcon}
                src={edit}
                alt="Edit Icon"
                style={{ width: 12, height: 12, marginRight: 12 }}
              />
              Rename
            </MenuItem>
            <MenuItem
              onClick={() => handleDownload(activeESignRequest)}
              className={classes.menuItem}
            >
              <img
                className={classes.menuIcon}
                src={download}
                alt="Download Icon"
                style={{ width: 12, height: 12, marginRight: 12 }}
              />
              Download
            </MenuItem>
            {activeESignRequest.status_code !== "signed" && (
              <MenuItem
                onClick={() => handleDelete(activeESignRequest)}
                style={{
                  borderTop: "1px solid #D3D3D3",
                  marginTop: 20,
                }}
                className={classes.menuItem}
              >
                <img
                  className={classes.menuIcon}
                  src={remove}
                  alt="Remove Icon"
                  style={{
                    width: 12,
                    height: 12,
                    marginRight: 12,
                  }}
                />
                Remove
              </MenuItem>
            )}
          </Menu>
        </>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  menuItem: {
    overflow: 'visible'
  },
  root: {
    display: "flex",
    backgroundColor: "#FAFAFA",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
    backgroundColor: "#fff",
    borderRadius: 10,
    margin: "0 auto 25px",
    padding: "3% 5%",
  },
  indicator: {
    backgroundColor: "#000",
  },
  button: {
    textTransform: "none",
    color: "#2A528C",
    fontSize: 16,
    "&:hover": {
      background: "none !important",
    },
  },
}));
