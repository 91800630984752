import React, { useEffect, useState } from "react";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { Button, TextField, Paper, Box } from "@material-ui/core";
import data from "../../../lib/data";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import { CSSTransition } from "react-transition-group";
import { AirlineSeatFlatAngledTwoTone } from "@material-ui/icons";
import moment from "moment";
import "../../../App.css";
import edit from "../../../assets/pencil.svg";
import { ClipLoader } from "react-spinners";
import { PhoneFormatter } from "../../../lib/NumberFormatter";
import "../../../App.css";
import Autocomplete from "../../../lib/Autocomplete";
import { position } from "dom-helpers";
import validations from "../../../lib/validations";
import useKochava from "../../../hooks/useKochava";

export default function BusinessInfo(props) {
  const { recordActivity } = useKochava();
  const [firm, setFirm] = useState(null);
  const [loading, setLoading] = useState(false);
  const { verified, notify, checkVerification } = props;
  const [user, setUser] = useState("");
  const [fade, setFade] = useState(false);
  const [zip, setZip] = useState(firm?.zipCode);
  const [officeNum, setOfficeNum] = useState("");
  const [firms, setFirms] = useState([]);
  const [createdAt, setCreatedAt] = useState("");
  const [businessName, setBusinessName] = useState(
    props?.self ? props.self.firmName : ""
  );
  const [street, setStreet] = useState(firm?.address);
  const [city, setCity] = useState(firm?.city);
  const [state, setState] = useState(firm?.state);
  const [website, setWebsite] = useState(firm?.website);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [isEditingWebsite, setIsEditingWebsite] = useState(false);
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [suiteNumber, setSuiteNumber] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [coordinates, setCoordinates] = useState({});
  const [validateAddress, setValidateAddress] = useState(false);
  const [address, setAddress] = useState("");
  const [fullAddress, setFullAddress] = useState("");

  useEffect(() => {
    setFade(true);
  }, []);

  useEffect(() => {
    if (props.firms && props.firms.length > 0) {
      let firm = props.firms[0].Firm;
      setFirms(props.firms);
      setFirm(firm);
      setAddress(firm.address);
      setBusinessName(firm.name);
      setZip(firm.zip);
      setWebsite(firm.website);
      setContactNumber(firm.phone || "-");
    }
  }, [props.firms, props.firms && props.firms.length]);

  function createFirm() {
    setLoading(true);
    let updatedWebsite = website
      .replace("http://", "")
      .replace("https://", "")
      .replace("www.", "");
    axios
      .post("/firm/create", {
        name: businessName,
        address: street,
        city,
        state,
        zip,
        phone: contactNumber,
        website: updatedWebsite,
        latitude: coordinates.lat,
        longitude: coordinates.lng,
      })
      .then((resp) => {
        console.log("Firm created", resp);
        // setBusinessName(name);
        let { firm } = resp.data;
        console.log("resp.data.firm", firm);
        setFirms([...firms, { Firm: firm }]);
        props.getFirm();
        checkVerification();
        // window.location = "/cases";
        setLoading(false);
      })
      .catch((err) => {
        console.log(
          "Error creating firm",
          err.response ? err.response.data.message : err
        );
        setLoading(false);
        notify(
          err?.response?.data?.message ||
            "Error saving changes. Please try again.",
          false
        );
      });
    process.env.REACT_APP_TRACKING_ENABLED === "true" &&
      recordActivity("Business Info Filled", { converted: true });
  }

  function updateFirm() {
    setLoading(true);
    let updatedWebsite = website
      .replace("http://", "")
      .replace("https://", "")
      .replace("www.", "");
    axios
      .put(`/firm/update/${firm?.id}`, {
        name: businessName,
        address: street,
        city,
        state,
        zipCode: zip,
        phone: contactNumber,
        website: updatedWebsite,
        latitude: coordinates.lat,
        longitude: coordinates.lng,
      })
      .then((resp) => {
        console.log("Firm updated", resp);
        setLoading(false);
        notify("Your changes have been saved!", true);
        props.getFirm();
        setIsEditingWebsite(false);
        setIsEditingName(false);
      })
      .catch((err) => {
        console.log("Error updating firm", err.response);
        props.notify("Error updating firm. Please try again later.", false);
        setLoading(false);
      });
    process.env.REACT_APP_TRACKING_ENABLED === "true" &&
      recordActivity("Business Info Filled", { converted: true });
  }

  const classes = useStyles();

  function handleCancel() {
    setIsEditingName(false);
    setIsEditingWebsite(false);
    setValidateAddress(false);
    setBusinessName(firm ? firm.name : "");
    setStreet(firm ? firm.street : "");
    setCity(firm ? firm.city : "");
    setState(firm ? firm.state : "");
    setWebsite(firm ? firm.website : "");
    setAddress(firm ? firm.address : "");
    setFullAddress("");
    setSuiteNumber("");
    setContactNumber("");
  }

  const handleSelect = (address) => {
    setFullAddress(address);
    //gets address
    geocodeByAddress(address)
      .then((results) => {
        console.log("results", results);
        let arr = results[0].address_components;
        let findStreetNum = arr.find((el) =>
          el.types.includes("street_number")
        );
        let findStreetName = arr.find((el) => el.types.includes("route"));
        let findCity = arr.find((el) => el.types.includes("locality"));
        let findZip = arr.find((el) => el.types.includes("postal_code"));
        let findState = arr.find((el) =>
          el.types.includes("administrative_area_level_1")
        );

        if (findStreetNum && findCity && findZip) {
          setValidateAddress(false);
          const street =
            findStreetNum.long_name + " " + findStreetName.long_name;
          const city = findCity.long_name;
          const state = findState.short_name;
          const zip = findZip.long_name;
          setStreet(street);
          setCity(city);
          setState(state);
          setZip(zip);
          setAddress(street, city);
        } else {
          // setAddress("");
          setValidateAddress(true);
        }
      })
      .catch((error) => console.error("Error", error));

    //gets lat and lng
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        console.log("Success", latLng);
        setCoordinates(latLng);
      })
      .catch((error) => console.error("Error", error));
  };

  const searchOptions = {
    types: ["address"],
  };

  const createFirmInputProps = {
    variant: "outlined",
    color: "primary",
    className: classes.createFirmInput,
    inputProps: { maxLength: 50 },
  };

  const updateFirmInputProps = {
    variant: "standard",
    color: "primary",
    className: classes.updateFirmInput,
    inputProps: { maxLength: 50 },
  };

  return (
    <CSSTransition in={fade} timeout={700} classNames="my-node">
      {fade ? (
        verified ? (
          <div
            className="updateFirm"
            onClick={() => {
              setIsEditingName(false);
              setIsEditingAddress(false);
              setIsEditingWebsite(false);
            }}
          >
            <div className={classes.form}>
              <p style={styles.section}>Practice or Business Name</p>
              <div className={classes.divider} />
              <div style={{ position: "relative" }}>
                <div style={{ minHeight: "50vh" }}>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      // setIsEditingName(true);
                      setIsEditingWebsite(false);
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      <TextField
                        label="Name"
                        value={businessName}
                        fullWidth
                        disabled={!isEditingName && true}
                        name="businessName"
                        onChange={(e) => setBusinessName(e.target.value)}
                        {...updateFirmInputProps}
                        inputProps={{
                          style: {
                            textTransform: "capitalize",
                          },
                        }}
                      />
                    </div>

                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsEditingName(!isEditingName);
                      }}
                      className={classes.iconWrapper}
                    >
                      <img
                        className={classes.icon}
                        src={edit}
                        alt="Edit Icon"
                      />
                    </div>

                    <div className="firm" style={{ position: "relative" }}>
                      <Autocomplete
                        value={address}
                        handleChange={(address) => {
                          setAddress(address);
                        }}
                        handleSelect={handleSelect}
                        disabled={!isEditingAddress}
                        variant="standard"
                        color="primary"
                        label="Address"
                        classNameGrid={classes.updateFirmInput}
                        searchOptions={searchOptions}
                      />
                      {validateAddress && (
                        <p
                          style={{
                            fontSize: 14,
                            color: "#ff7070",
                            textAlign: "left",
                            margin: "8px 0px 40px",
                            lineHeight: "24px",
                            fontSize: 14,
                          }}
                        >
                          Please enter your address in format: 123 N Main St,
                          Boston MA, 02110
                        </p>
                      )}
                      <div
                        onClick={() => setIsEditingAddress(!isEditingAddress)}
                        className={classes.iconWrapper}
                      >
                        <img
                          className={classes.icon}
                          src={edit}
                          alt="Edit Icon"
                        />
                      </div>
                    </div>

                    {/* <TextField
                    label="Street"
                    value={street}
                    fullWidth
                    disabled={!isEditingName && true}
                    name="street"
                    onChange={(e) => setStreet(e.target.value)}
                    {...inputProps2}
                  /> */}
                    {/* <div style={{ display: "flex" }}>
                      <TextField
                        label="City"
                        value={city}
                        fullWidth
                        disabled={true}
                        name="city"
                        onChange={(e) => setCity(e.target.value)}
                        {...inputProps2}
                      />
                      <TextField
                        label="State"
                        value={state}
                        fullWidth
                        disabled={true}
                        name="state"
                        onChange={(e) => setState(e.target.value)}
                        {...inputProps2}
                      />
                    </div> */}
                  </div>

                  <div className={classes.divider} />

                  <div style={{ position: "relative" }}>
                    <PhoneFormatter
                      fullWidth
                      label="Phone"
                      placeholder="Contact Phone Number"
                      name="contactNumber"
                      disabled={!isEditingPhone && true}
                      value={contactNumber}
                      onChange={(e) => setContactNumber(e.target.value)}
                      {...updateFirmInputProps}
                    />
                    <div
                      onClick={(e) => {
                        console.log("click");
                        e.stopPropagation();
                        setIsEditingPhone(!isEditingPhone);
                      }}
                      className={classes.iconWrapper}
                    >
                      <img
                        className={classes.icon}
                        src={edit}
                        alt="Edit Icon"
                      />
                    </div>
                  </div>

                  <div className={classes.divider} />

                  <div
                    style={{ position: "relative" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsEditingName(false);
                    }}
                  >
                    <TextField
                      label="Website"
                      value={website}
                      fullWidth
                      disabled={!isEditingWebsite && true}
                      name="website"
                      onChange={(e) => setWebsite(e.target.value)}
                      {...updateFirmInputProps}
                      className={classes.website}
                    />
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsEditingWebsite(!isEditingWebsite);
                      }}
                      className={classes.iconWrapper}
                    >
                      <img
                        className={classes.icon}
                        src={edit}
                        alt="Edit Icon"
                      />
                    </div>
                  </div>
                  <div className={classes.divider} />
                </div>
              </div>
            </div>
            <div className={classes.buttons}>
              <Button
                className="btn clear account-btn"
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
              <Button
                className="btn light-blue account-btn"
                onClick={() => updateFirm()}
                style={{
                  color: "#fff",
                  width: "40%",
                  alignSelf: "center",
                  textTransform: "none",
                  margin: "3% 0",
                }}
                disabled={
                  !website?.includes(".com") || validateAddress || loading
                }
              >
                {loading ? (
                  <div
                    className="sweet-loading"
                    style={{
                      position: "absolute",
                      width: 15,
                      height: 15,
                      top: 8,
                    }}
                  >
                    <ClipLoader size={20} color="#fff" loading={loading} />
                  </div>
                ) : (
                  <span>Save Changes</span>
                )}
              </Button>
            </div>
          </div>
        ) : (
          <div className={`createFirmWrapper ${classes.createFirm}`}>
            <div style={{ marginBottom: "7.5%" }}>
              <p style={styles.section}>Practice or Business Name</p>
              <Grid container className={classes.createFirmInput}>
                <TextField
                  placeholder="Business Name"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  name="businessName"
                  fullWidth
                  {...createFirmInputProps}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                />
              </Grid>
            </div>
            <div style={{ marginBottom: "7.5%" }}>
              <p style={styles.section}>Office Address</p>
              <div className="createFirm">
                <Autocomplete
                  value={fullAddress}
                  handleChange={(address) => {
                    setFullAddress(address);
                  }}
                  handleSelect={handleSelect}
                  variant="outlined"
                  color="primary"
                  placeholder="Address"
                  classNameGrid={classes.createFirmInput}
                />
                {validateAddress && (
                  <p
                    style={{
                      margin: "8px 0px 40px",
                      fontSize: 14,
                      color: "#ff7070",
                      textAlign: "left",
                      lineHeight: "24px",
                      fontSize: 14,
                    }}
                  >
                    Please enter your address in format: 123 N Main St, Boston
                    MA, 02110
                  </p>
                )}
              </div>
              {/* </Grid> */}
              {/* <Grid container className={classes.rowInput}>
                <Grid item style={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    placeholder="City"
                    value={user.city}
                    name="city"
                    onChange={(e) => setCity(e.target.value)}
                    {...inputProps}
                  />
                </Grid>
                <Grid item style={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    placeholder="State"
                    value={user.state}
                    name="state"
                    onChange={(e) => setState(e.target.value)}
                    {...inputProps}
                  />
                </Grid>
                <Grid item style={{ flex: 0.7 }}>
                  <TextField
                    fullWidth
                    placeholder="Zip Code"
                    value={user.zip}
                    inputProps={{ maxLength: 5 }}
                    name="zip"
                    onChange={(e) => setZip(e.target.value)}
                    {...inputProps}
                  />
                </Grid>
              </Grid> */}
              <Grid container className={classes.createFirmInput}>
                <Grid
                  item
                  style={{
                    flex: 1,
                  }}
                >
                  <TextField
                    placeholder="Suite Number"
                    value={suiteNumber}
                    onChange={(e) => setSuiteNumber(e.target.value)}
                    name="businessName"
                    fullWidth
                    {...createFirmInputProps}
                  />
                </Grid>
                <Grid
                  item
                  style={{
                    flex: 1,
                  }}
                >
                  <PhoneFormatter
                    fullWidth
                    placeholder="Contact Phone Number"
                    name="contactNumber"
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                    {...createFirmInputProps}
                  />
                </Grid>
                <Grid item style={{ flex: 0.7, visibility: "hidden" }}></Grid>
              </Grid>
            </div>
            <div style={{ marginBottom: "7.5%" }}>
              <p style={styles.section}>Business Website</p>
              <Grid container className={classes.createFirmInput}>
                <TextField
                  placeholder="Website"
                  value={website}
                  name="website"
                  onChange={(e) => {
                    setWebsite(e.target.value);
                  }}
                  fullWidth
                  {...createFirmInputProps}
                />
              </Grid>
              <div>
                <p
                  style={{
                    fontSize: 14,
                    margin: 0,
                    marginTop: -16,
                    textAlign: "right",
                    color: "#ff7070",
                  }}
                >
                  *All fields are required
                </p>
              </div>
            </div>

            <div style={styles.alignRight}>
              <Button
                className="btn clear account-btn"
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
              <Button
                className="btn light-blue account-btn"
                onClick={() => createFirm()}
                disabled={
                  !businessName ||
                  !address ||
                  validateAddress ||
                  !website ||
                  !contactNumber ||
                  loading
                }
                style={{
                  color: "#fff",
                  width: "40%",
                  alignSelf: "center",
                  textTransform: "none",
                  margin: "3% 0",
                }}
              >
                {loading ? (
                  <div
                    className="sweet-loading"
                    style={{
                      position: "absolute",
                      width: 15,
                      height: 15,
                      top: 8,
                    }}
                  >
                    <ClipLoader size={20} color="#fff" loading={loading} />
                  </div>
                ) : (
                  <span>Save Changes</span>
                )}
              </Button>
            </div>
          </div>
        )
      ) : (
        <div />
      )}
    </CSSTransition>
  );
}

const useStyles = makeStyles((theme) => ({
  form: {
    [theme.breakpoints.up(769)]: {
      maxWidth: 350,
    },
  },
  buttons: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    [theme.breakpoints.down(350)]: {
      fontSize: "10px !important",
    },
  },
  divider: {
    borderBottom: "1px solid #E3E3E3",
    margin: "16px 0",
    [theme.breakpoints.up(769)]: {
      maxWidth: 385,
    },
  },
  iconWrapper: {
    position: "absolute",
    top: 6,
    transform: "translate(-50%, -50%)",
    left: "100%",
    maxWidth: "100%",
    cursor: "pointer",
    [theme.breakpoints.down(769)]: {
      left: "100%",
    },
  },
  icon: {
    width: 12,
    height: 12,
    marginRight: 24,
  },
  createFirm: {
    maxWidth: 690,
    [theme.breakpoints.down(769)]: {
      width: "100%",
    },
  },
  createFirmInput: {
    margin: "40px auto",
    gap: 15,
    [theme.breakpoints.down(769)]: {
      margin: "25px auto",
    },
    "& input::placeholder": {
      color: "#5C5C5C",
    },
    "& > *": {
      display: "flex",
    },
  },
  updateFirmInput: {
    margin: "25px auto",
    "& input": {
      fontSize: 14,
    },
  },
  website: {
    "& input": {
      fontWeight: 600,
      fontSize: 18,
    },
  },
}));

const styles = {
  checkbox: {
    backgroundColor: "#fff",
    borderRadius: 0,
    margin: "1% 0",
  },
  section: {
    textAlign: "left",
    fontSize: 24,
    fontWeight: "700",
    color: "rgba(92,92,92)",
    margin: "20px 0",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3% 5%",
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
};
