import axios from "axios";

const data = {
  async getSelf(userId) {
    try {
      return axios.get(`/users/self`).then((resp) => {
        console.log("Self found", resp);
        return resp.data.user;
      });
    } catch (err) {
      console.log("Error getting self", err);
    }
  },

  async getProviders(query, firmId) {
    return axios
      .get(`/businesses/firm/${firmId}` + (query ? `?name=${query}` : ""))
      .then((resp) => {
        console.log("Providers found", resp);
        // let providers = resp.data.businesses.sort(function (a, b) {
        //   return a.createdAt < b.createdAt ? 1 : -1;
        // });
        return resp.data.businesses;
      })
      .catch((err) => console.log("Error fetching businesses", err.response));
  },

  async getComments() {
    return axios
      .get("/comment")
      .then((resp) => {
        // console.log("Comments found", resp)
        return resp.data.comments;
      })
      .catch((err) => console.log("Error fetching comments", err.response));
  },

  async getCases(query, order, firmId) {
    return axios
      .get(
        `/posts/firm/${firmId}` +
          (query ? `?search=${query}` : "") +
          (order ? `?order=${order}` : "")
      )
      .then((resp) => {
        console.log("CASES RESP", resp)
        return resp.data.posts;
      })
      .catch((err) =>
        console.log("Error fetching cases", err?.response ? err.response : err)
      );
  },

  async getFirm() {
    return axios
      .get("/firm")
      .then((resp) => {
        console.log("User firm response", resp);
        return resp.data.firms;
      })
      .catch((err) => {
        console.log(
          "Error checking user firms",
          err.response ? err.response.data.message : err
        );
      });
  },

  // Users/Cases
  async getUsers(query) {
    // return axios
    //   .get("/users" + (query ? `?name=${query}` : ""))
    //   .then((resp) => {
    //     console.log("Users found", resp);
    //     return resp.data.users;
    //   })
    //   .catch((err) => console.log("Error fetching users", err.response));
  },

  // Team
  async getTeam(query, firmId) {
    return axios
      .post("/users/teams" + (query ? `?name=${query}` : ""), {
        firmId,
      })
      .then((resp) => {
        console.log("Team found", resp);
        const users = resp.data.users.sort(function (a, b) {
          return a.createdAt < b.createdAt ? 1 : -1;
        });
        return users;
      })
      .catch((err) => console.log("Error fetching team", err.response));
  },

  async getTemplates(firmId) {
    return axios
      .get(`template/firm?firmId=${firmId}`)
      .then((resp) => {
        return resp.data.templates;
      })
      .catch((err) =>
        console.log(
          "Error fetching templates",
          err?.response ? err.response : err
        )
      );
  },

  //Fetch Case that are part of a Provider
  async getProviderCases(providerId) {
    return axios
      .get(`/usersBusiness/business/${providerId}`)
      .then((resp) => {
        console.log("Cases with a Provider found", resp);
        return resp.data.usersBusiness;
      })
      .catch((err) =>
        console.log(
          "Error fetching cases with a provider",
          err?.response ? err.response : err
        )
      );
  },
};

export default data;
