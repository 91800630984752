import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { Button, TextField } from "@material-ui/core";
import axios from "axios";
import HelloSign from "hellosign-embedded";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { ClipLoader } from "react-spinners";
import { PhoneFormatter } from "../../lib/NumberFormatter";

const useStyles = makeStyles({
  button: {
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
});

export default function ConfirmationModal(props) {
  const { selected, open, setOpen, heading, handlePress, loading } = props;
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ margin: "1rem" }}
      onClick={handleClose}
    >
      <div className="modal">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <ClearIcon className="close" onClick={handleClose} />
          <h1
            style={{
              fontSize: 24,
              width: "85%",
              marginRight: "auto",
              marginLeft: "auto",
              lineHeight: "36px",
              marginBottom: 0,
              marginTop: 0,
            }}
          >
            {heading}
          </h1>
          <div className="modal-form" style={styles.content}>
            <Button className="btn light-blue add-btn" onClick={handlePress}>
              {loading ? (
                <div
                  className="sweet-loading"
                  style={{
                    position: "absolute",
                    width: 15,
                    height: 15,
                    top: 13,
                  }}
                >
                  <ClipLoader size={20} color="#fff" loading={loading} />
                </div>
              ) : (
                <span>Delete</span>
              )}
            </Button>
            <Button className={classes.button} onClick={handleClose}>
              <span style={{ textTransform: "none" }}>Cancel</span>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const styles = {
  content: {
    display: "flex",
    flexDirection: "column",
  },
};
