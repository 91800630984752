import React, { useState, useEffect } from "react";
import { Button, TextField, useMediaQuery } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import { CSSTransition } from "react-transition-group";
import Password from "../../Password";
import { makeStyles } from "@material-ui/core/styles";
import edit from "../../../assets/pencil.svg";
import { ClipLoader } from "react-spinners";
import { PhoneFormatter } from "../../../lib/NumberFormatter";
import { NetworkWifiSharp } from "@material-ui/icons";
import ChangePassword from "../Modals/ChangePassword";
import validations from "../../../lib/validations";

export default function AccountInfo(props) {
  const classes = useStyles();
  const { self, getSelf, notify } = props;
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [validateEmail, setValidateEmail] = useState(false);
  const [phone, setPhone] = useState("");
  const [fade, setFade] = useState(false);
  const [editLoginInfo, setEditLoginInfo] = useState(false);
  const [editContactInfo, setEditContactInfo] = useState(false);
  const mobile = useMediaQuery("(max-width:1080px)");
  const [showPassword, setShowPassword] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const { validateName } = validations;

  useEffect(() => {
    setFade(true);
  }, []);

  useEffect(() => {
    setFirstName(self?.firstName);
    setLastName(self?.lastName);
    setEmail(self?.email);
    setPhone(self?.phone);
  }, [self]);

  function updateSelf() {
    setLoading(true);
    axios
      .put(`/users/self`, { firstName, lastName, email, phone })
      .then((resp) => {
        console.log("Account updated", resp);
        notify("Account updated!", true);
        setLoading(false);
        getSelf();
      })
      .catch((err) => {
        console.log("Error updating account", err.response);
        notify("Error updating account. Please try again later.", false);
        setLoading(false);
      });
  }

  function updatePassword() {
    setLoading(true);
    axios
      .post("/auth/change/password", { firstName, lastName, email, phone })
      .then((resp) => {
        console.log("Account updated", resp);
        notify("Account updated!", true);
        setLoading(false);
        getSelf();
      })
      .catch((err) => {
        console.log("Error updating account", err.response);
        notify("Error updating account. Please try again later.", false);
        setLoading(false);
      });
  }

  function handleCancel() {
    setEditContactInfo(false);
    setEditLoginInfo(false);
    setFirstName(self?.firstName);
    setLastName(self?.lastName);
    setEmail(self?.email);
    setPhone(self?.phone);
  }

  const inputProps = {
    color: "primary",
    style: styles.input,
    inputProps: { maxLength: 50 },
  };

  console.log("SELF in AccountInfo", self);

  return (
    <CSSTransition in={fade} timeout={700} classNames="my-node">
      {fade ? (
        <div className={classes.root}>
          <div className={classes.form}>
            <p className={classes.section}>Credentials</p>
            <div
              style={{ position: "relative" }}
              onClick={() => {
                setEditContactInfo(false);
                setEditLoginInfo(true);
              }}
            >
              <TextField
                label="Email Address"
                value={email}
                fullWidth
                disabled={!editLoginInfo && true}
                name="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  !validations.emailValidation(email) &&
                    setValidateEmail(false);
                }}
                onBlur={(e) => {
                  validations.emailValidation(email)
                    ? setValidateEmail(true)
                    : setValidateEmail(false);
                }}
                {...inputProps}
              />
              {email && validateEmail && (
                <p
                  style={{
                    margin: "0px 0px 1px",
                    fontSize: 12,
                    color: "#ff7070",
                    textAlign: "left",
                  }}
                >
                  Invalid email address format
                </p>
              )}
              <div className={classes.iconWrapper}>
                <img className={classes.icon} src={edit} alt="Edit Icon" />
              </div>
              <div style={{ position: "relative" }}>
                <TextField
                  type="password"
                  label="Password"
                  value="password"
                  fullWidth
                  disabled
                  name="password"
                  {...inputProps}
                />
                <div className={classes.passwordWrapper}>
                  <button
                    disabled={!editLoginInfo && true}
                    className={classes.changePassword}
                    onClick={() => setChangePasswordModal(true)}
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.divider} />
          <div className={classes.form}>
            <p className={classes.section}>Contact Information</p>
            <div
              style={{ position: "relative" }}
              onClick={() => {
                setEditContactInfo(true);
                setEditLoginInfo(false);
              }}
            >
              <TextField
                label="First Name"
                value={firstName}
                name="firstName"
                fullWidth
                inputProps={{
                  style: { textTransform: "capitalize" },
                  maxLength: 50,
                }}
                style={styles.input}
                color="primary"
                onChange={(e) =>
                  (validateName(e.target.value) || !e.target.value) &&
                  setFirstName(e.target.value)
                }
                disabled={!editContactInfo}
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
              <div className={classes.iconWrapper}>
                <img className={classes.icon} src={edit} alt="Edit Icon" />
              </div>
              <TextField
                label="Last Name"
                value={lastName}
                name="lastName"
                fullWidth
                inputProps={{
                  style: { textTransform: "capitalize" },
                  maxLength: 50,
                }}
                style={styles.input}
                color="primary"
                disabled={!editContactInfo && true}
                onChange={(e) =>
                  (validateName(e.target.value) || !e.target.value) &&
                  setLastName(e.target.value)
                }
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
              <TextField
                label="Email Address"
                value={email}
                fullWidth
                disabled={!editContactInfo && true}
                onChange={(e) => {
                  setEmail(e.target.value);
                  !validations.emailValidation(email) &&
                    setValidateEmail(false);
                }}
                onBlur={(e) => {
                  validations.emailValidation(email)
                    ? setValidateEmail(true)
                    : setValidateEmail(false);
                }}
                name="email"
                {...inputProps}
              />
              {email && validateEmail && (
                <p
                  style={{
                    margin: "0px 0px 8px",
                    fontSize: 12,
                    color: "#ff7070",
                    textAlign: "left",
                  }}
                >
                  Invalid email address format
                </p>
              )}
              <PhoneFormatter
                label="Phone"
                value={phone}
                disabled={!editContactInfo && true}
                fullWidth
                onChange={(e) => setPhone(e.target.value)}
                {...inputProps}
              />
            </div>
          </div>
          <div className={classes.buttons}>
            <Button
              className="btn clear account-btn"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              className="btn light-blue account-btn"
              onClick={() => updateSelf()}
              disabled={loading || validateEmail}
              style={{
                color: "#fff",
                width: "40%",
                alignSelf: "center",
                textTransform: "none",
                margin: "3% 0",
              }}
            >
              {loading ? (
                <div
                  className="sweet-loading"
                  style={{
                    position: "absolute",
                    width: 15,
                    height: 15,
                    top: 8,
                  }}
                >
                  <ClipLoader size={20} color="#fff" loading={loading} />
                </div>
              ) : (
                <span>Save Changes</span>
              )}
            </Button>
          </div>
          <div>
            {changePasswordModal && (
              <ChangePassword
                setOpen={setChangePasswordModal}
                notify={notify}
              />
            )}
          </div>
        </div>
      ) : (
        <div />
      )}
    </CSSTransition>
  );
}

const useStyles = makeStyles((theme) => ({
  form: {
    [theme.breakpoints.up(1080)]: {
      maxWidth: "50%",
    },
  },
  section: {
    textAlign: "left",
    fontSize: 24,
    fontWeight: "700",
    color: "rgba(92,92,92)",
    margin: "30px auto 10px",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down(350)]: {
      fontSize: "10px !important",
    },
  },
  passwordWrapper: {
    position: "absolute",
    bottom: 30,
    left: "15rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down(1080)]: {
      right: 0,
      bottom: 41,
    },
    [theme.breakpoints.down(530)]: {
      bottom: 30,
    },
    [theme.breakpoints.down(415)]: {
      bottom: 28,
    },
  },
  changePassword: {
    backgroundColor: "transparent",
    border: "none",
    color: "#2A528C",
    size: 14,
    whiteSpace: "nowrap",
    cursor: "pointer",
  },
  divider: {
    borderBottom: "1px solid #E3E3E3",
    margin: "24px 0",
    maxWidth: 689,
  },
  iconWrapper: {
    position: "absolute",
    top: 20,
    right: 24,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  icon: {
    width: 12,
    height: 12,
  },
  buttons: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    [theme.breakpoints.down(350)]: {
      fontSize: "10px !important",
    },
  },
}));

const styles = {
  input: {
    margin: "4.1% auto",
  },
};
