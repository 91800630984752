import React, { useState, useEffect } from "react";
import {
  Modal,
  FormControl,
  TextField,
  Button,
  useMediaQuery,
  Grid,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AiOutlineLeft } from "react-icons/ai";
import { PhoneFormatter } from "../../../lib/NumberFormatter";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import InputMask from 'react-input-mask';


export default function ClientDetails(props) {
  const classes = useStyles();
  let caseProp = props.location.state?.caseObj;
  const [caseObj, setCaseObj] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [referredBy, setReferredBy] = useState('');
  const [dob, setDob] = useState('');
  const [ssn, setSSN] = useState('');
  const [address, setAddress] = useState('');
  const [hospital, setHospital] = useState('');
  const [policeDpt, setPoliceDpt] = useState('');
  const [location, setLocation] = useState('');
  const [injuries, setInjuries] = useState('');
  const [priorInjuries, setPriorInjuries] = useState('');
  const [vehicleOwner, setVehicleOwner] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [vehicleLocation, setVehicleLocation] = useState('');
  const [companionWith, setCompanionWith] = useState('');
  const [employer, setEmployer] = useState('');
  const [placeBorn, setPlaceBorn] = useState('');
  const [timeInFL, setTimeInFL] = useState('');
  const [pipCompany, setPipCompany] = useState('');
  const [pipInsured, setPipInsured] = useState('');
  const [pipClaimNo, setPipClaimNo] = useState('');
  const [pipPolicyNo, setPipPolicyNo] = useState('');
  const [tortCompany, setTortCompany] = useState('');
  const [tortInsured, setTortInsured] = useState('');
  const [tortDriver, setTortDriver] = useState('');
  const [tortClaimNo, setTortClaimNo] = useState('');
  const [tortPolicyNo, setTortPolicyNo] = useState('');

  const [loading, setLoading] = useState(false);
  const { notify } = props;
  const isSampleCase = props.location.state?.isSampleCase;

  console.log("props in clientDetails", caseObj);

  const mobile = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    axios.get(`/posts/post/${caseProp.id}`)
      .then(resp => {
        console.log("Case fetched!", resp)
        setCaseObj(resp.data.post)
      })
      .catch(e => console.log("Error fetching case", e))
  }, []);

  console.log("CASE OBJ", caseObj)

  useEffect(() => {
    if (caseObj) {
      setFirstName(caseObj.author ? caseObj.author.firstName : "");
      setLastName(caseObj.author ? caseObj.author.lastName : "");
      setEmail(caseObj.author ? caseObj.author.email : "");
      setPhone(caseObj.author ? caseObj.author.phone : "");
      setReferredBy(caseObj ? caseObj.referredBy : "");
      setDob(caseObj ? caseObj.dob : "");
      setSSN(caseObj ? caseObj.ssn : "");
      setAddress(caseObj ? caseObj.address : "");
      setHospital(caseObj ? caseObj.hospital : "");
      setPoliceDpt(caseObj ? caseObj.policeDpt : "");
      setLocation(caseObj ? caseObj.location : "");
      setInjuries(caseObj ? caseObj.injuries : "");
      setPriorInjuries(caseObj ? caseObj.priorInjuries : "");
      setVehicleOwner(caseObj ? caseObj.vehicleOwner : "");
      setVehicleType(caseObj ? caseObj.vehicleType : "");
      setVehicleLocation(caseObj ? caseObj.vehicleLocation : "");
      setCompanionWith(caseObj ? caseObj.companionWith : "");
      setEmployer(caseObj ? caseObj.employer : "");
      setPlaceBorn(caseObj ? caseObj.placeBorn : "");
      setTimeInFL(caseObj ? caseObj.timeInFL : "");
      setPipCompany(caseObj ? caseObj.pipCompany : "");
      setPipInsured(caseObj ? caseObj.pipInsured : "");
      setPipClaimNo(caseObj ? caseObj.pipClaimNo : "");
      setPipPolicyNo(caseObj ? caseObj.pipPolicyNo : "");
      setTortCompany(caseObj ? caseObj.tortCompany : "");
      setTortInsured(caseObj ? caseObj.tortInsured : "");
      setTortDriver(caseObj ? caseObj.tortDriver : "");
      setTortClaimNo(caseObj ? caseObj.tortClaimNo : "");
      setTortPolicyNo(caseObj ? caseObj.tortPolicyNo : "");
    }
  }, [caseObj]);

  const inputProps = {
    variant: "outlined",
    color: "primary",
    className: classes.input,
    // inputProps: { maxLength: 50 },
  };

  const updateClient = async () => {
    setLoading(true);
    console.log("userId updateClient", caseObj.userId);

    try {
      const userUpdatePromise = axios.put("/users/user", {
        firstName,
        lastName,
        email,
        phone,
        userId: caseObj.userId,
      });

      const postUpdatePromise = axios.put(`/posts/post/${caseObj.id}`, {
        referredBy, dob, ssn, address, hospital, policeDpt, location, injuries, priorInjuries, vehicleOwner, vehicleType, vehicleLocation, companionWith, employer, placeBorn, timeInFL, pipCompany, pipInsured, pipClaimNo, pipPolicyNo, tortCompany, tortInsured, tortDriver, tortClaimNo, tortPolicyNo
      });

      await Promise.all([userUpdatePromise, postUpdatePromise]);

      console.log("Client details updated!");
      notify("Client details updated!", true);
    } catch (err) {
      console.log("Error updating client details", err);
      notify("Error updating client details", false);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div>
      <div className={classes.root}>
        <Box px={mobile ? 2 : 10}>
          <Grid container direction="row" justifyContent="flex-start">
            <button
              onClick={(e) => {
                e.stopPropagation();
                props.history.push("/case", { caseObj: caseObj });
              }}
              style={{
                display: "flex",
                alignItems: "center",
                background: "none",
                border: "none",
                cursor: "pointer",
                textDecoration: "underline",
                paddingTop: 40,
              }}
            >
              <AiOutlineLeft style={{ marginRight: ".4rem" }} /> Return to Case
            </button>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <h2 className={classes.heading}>Client Details</h2>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  width: 500,
                  maxWidth: "100%",
                  margin: "auto",
                }}
              >
                <TextField
                  placeholder="First Name"
                  value={firstName}
                  fullWidth
                  name="first name"
                  onChange={(e) => setFirstName(e.target.value)}
                  {...inputProps}
                />
                <TextField
                  placeholder="Last Name"
                  value={lastName}
                  fullWidth
                  name="last name"
                  onChange={(e) => setLastName(e.target.value)}
                  {...inputProps}
                />

                <PhoneFormatter
                  placeholder="Phone Number"
                  value={phone}
                  fullWidth
                  onChange={(e) => setPhone(e.target.value)}
                  {...inputProps}
                />

                <TextField
                  placeholder="Email Address"
                  value={email}
                  fullWidth
                  name="email address"
                  onChange={(e) => setEmail(e.target.value)}
                  {...inputProps}
                />

                <p style={{ textAlign: 'right', cursor: 'pointer', color: 'blue', fontSize: 13 }}>
                  Additional Client Fields
                </p>

                <TextField
                  label="Referred By"
                  value={referredBy}
                  fullWidth multiline
                  name="referredBy"
                  onChange={(e) => setReferredBy(e.target.value)}
                  {...inputProps}
                />

                <TextField
                  label="Companion with"
                  value={companionWith}
                  fullWidth multiline
                  name="companionWith"
                  onChange={(e) => setCompanionWith(e.target.value)}
                  {...inputProps}
                />

                <TextField
                  label="D/Birth"
                  value={dob}
                  fullWidth
                  name="dob"
                  onChange={(e) => setDob(e.target.value)}
                  {...inputProps}
                />

                <InputMask
                  mask="999-99-9999"
                  maskChar=""
                  value={ssn}
                  onChange={e => setSSN(e.target.value)}
                  placeholder="Enter SSN"
                >
                  {() => <TextField
                    label="SSN"
                    fullWidth
                    name="ssn"
                    {...inputProps}
                  />}
                </InputMask>

                <TextField
                  label="Address"
                  value={address}
                  fullWidth multiline
                  name="address"
                  onChange={(e) => setAddress(e.target.value)}
                  {...inputProps}
                />


                <TextField
                  label="Place Born"
                  value={placeBorn}
                  fullWidth multiline
                  name="placeBorn"
                  onChange={(e) => setPlaceBorn(e.target.value)}
                  {...inputProps}
                />

                <TextField
                  label="Time In FL"
                  value={timeInFL}
                  fullWidth multiline
                  name="timeInFL"
                  onChange={(e) => setTimeInFL(e.target.value)}
                  {...inputProps}
                />

                <TextField
                  label="Employer"
                  value={employer}
                  fullWidth multiline
                  name="employer"
                  onChange={(e) => setEmployer(e.target.value)}
                  {...inputProps}
                />



                <TextField
                  label="Police Dpt."
                  value={policeDpt}
                  fullWidth multiline
                  name="policeDpt"
                  onChange={(e) => setPoliceDpt(e.target.value)}
                  {...inputProps}
                />

                <TextField
                  label="Location of Incident"
                  value={location}
                  fullWidth multiline
                  name="location"
                  onChange={(e) => setLocation(e.target.value)}
                  {...inputProps}
                />

                <TextField
                  label="Hospital"
                  value={hospital}
                  fullWidth multiline
                  name="hospital"
                  onChange={(e) => setHospital(e.target.value)}
                  {...inputProps}
                />


                <TextField
                  label="Injuries"
                  value={injuries}
                  fullWidth multiline
                  name="injuries"
                  onChange={(e) => setInjuries(e.target.value)}
                  {...inputProps}
                />

                <TextField
                  label="Prior Injuries"
                  value={priorInjuries}
                  fullWidth multiline
                  name="priorInjuries"
                  onChange={(e) => setPriorInjuries(e.target.value)}
                  {...inputProps}
                />

                <TextField
                  label="Vehicle (type)"
                  value={vehicleType}
                  fullWidth multiline
                  name="vehicleType"
                  onChange={(e) => setVehicleType(e.target.value)}
                  {...inputProps}
                />

                <TextField
                  label="Vehicle Owner"
                  value={vehicleOwner}
                  fullWidth multiline
                  name="vehicleOwner"
                  onChange={(e) => setVehicleOwner(e.target.value)}
                  {...inputProps}
                />



                <TextField
                  label="Vehicle Location"
                  value={vehicleLocation}
                  fullWidth multiline
                  name="vehicleLocation"
                  onChange={(e) => setVehicleLocation(e.target.value)}
                  {...inputProps}
                />





                <TextField
                  label="PIP Company"
                  value={pipCompany}
                  fullWidth multiline
                  name="pipCompany"
                  onChange={(e) => setPipCompany(e.target.value)}
                  {...inputProps}
                />

                <TextField
                  label="PIP Insured"
                  value={pipInsured}
                  fullWidth multiline
                  name="pipInsured"
                  onChange={(e) => setPipInsured(e.target.value)}
                  {...inputProps}
                />

                <TextField
                  label="PIP Policy No."
                  value={pipPolicyNo}
                  fullWidth multiline
                  name="pipPolicyNo"
                  onChange={(e) => setPipPolicyNo(e.target.value)}
                  {...inputProps}
                />
                <TextField
                  label="PIP Claim No."
                  value={pipClaimNo}
                  fullWidth multiline
                  name="pipClaimNo"
                  onChange={(e) => setPipClaimNo(e.target.value)}
                  {...inputProps}
                />



                <TextField
                  label="Tort Company"
                  value={tortCompany}
                  fullWidth multiline
                  name="tortCompany"
                  onChange={(e) => setTortCompany(e.target.value)}
                  {...inputProps}
                />

                <TextField
                  label="Tort Insured"
                  value={tortInsured}
                  fullWidth multiline
                  name="tortInsured"
                  onChange={(e) => setTortInsured(e.target.value)}
                  {...inputProps}
                />

                <TextField
                  label="Tort Driver"
                  value={tortDriver}
                  fullWidth multiline
                  name="tortDriver"
                  onChange={(e) => setTortDriver(e.target.value)}
                  {...inputProps}
                />

                <TextField
                  label="Tort Policy No."
                  value={tortPolicyNo}
                  fullWidth multiline
                  name="tortPolicyNo"
                  onChange={(e) => setTortPolicyNo(e.target.value)}
                  {...inputProps}
                />
                <TextField
                  label="Tort Claim No."
                  value={tortClaimNo}
                  fullWidth multiline
                  name="tortClaimNo"
                  onChange={(e) => setTortClaimNo(e.target.value)}
                  {...inputProps}
                />



              </Box>
            </Grid>
          </Grid>
          <Button
            className="btn light-blue add-btn"
            disabled={loading || isSampleCase}
            onClick={() => updateClient()}
          >
            {loading ? (
              <div
                className="sweet-loading"
                style={{
                  position: "absolute",
                  width: 15,
                  height: 15,
                  top: 13,
                }}
              >
                <ClipLoader size={20} color="#fff" loading={loading} />
              </div>
            ) : (
              <span>Update Details</span>
            )}
          </Button>
        </Box>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
    width: "100%",
    height: "calc(100vh - 60px)",
    position: "fixed",
    top: 60,
    margin: 0,
    paddingBottom: 100,
    overflow: "auto",
    zIndex: 999,
    [theme.breakpoints.up(1600)]: {
      width: 1600,
    },
  },
  heading: {
    margin: "36px auto",
    color: "#000",
    [theme.breakpoints.down(769)]: {
      margin: "30px auto 20px auto",
    },
  },
  input: {
    margin: "2.8% 0",
  },
}));
