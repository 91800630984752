import React, { useState } from "react";
import { Table, Column, AutoSizer, SortDirection } from "react-virtualized";
import "react-virtualized/styles.css";
import "../../App.css";


export default function CaseTable(props) {
  const {
    data,
    label1,
    label2,
    label3,
    label4,
    buttonLabel,
    dataKey1,
    dataKey2,
    dataKey3,
    dataKey4,
    buttonDataKey,
    handleClick
  } = props;


  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('ASC');

  const list = data?.map((el) => el);
  const MIN_TABLE_WIDTH = 1170;

  const getValue = (rowData, dataKey, defaultValue) => {
    return dataKey.split('.').reduce((value, key) => value?.[key], rowData) ?? defaultValue;
  };

  // Sorting function to handle both simple and nested data keys
  const sortedList = [...data].sort((a, b) => {
    const aValue = getValue(a, sortBy);
    const bValue = getValue(b, sortBy);

    console.log("A", aValue, bValue)

    if (aValue < bValue) return sortDirection === SortDirection.ASC ? -1 : 1;
    if (aValue > bValue) return sortDirection === SortDirection.ASC ? 1 : -1;
    return 0;
  });


  const handleSort = ({ sortBy: newSortBy, sortDirection: newSortDirection }) => {
    setSortBy(newSortBy);
    setSortDirection(newSortDirection);
  };

  const rowClassName = ({ index }) => {
    if (index < 0) {
      return "headerRow";
    } else {
      return index % 2 === 0 ? "evenRow" : "oddRow";
    }
  };

  const cellRendererWrapper = (dataKeyFunc) => ({ rowData }) => {
    return dataKeyFunc({ rowData });
  };

  console.log("DATA KEYS", dataKey3, dataKey4)

  return (
    <AutoSizer>
      {({ width, height }) => (
        <Table
          width={width < MIN_TABLE_WIDTH ? MIN_TABLE_WIDTH : width}
          height={height}
          headerHeight={50}
          rowHeight={96} f
          rowCount={sortedList?.length}
          // rowCount={list?.length}
          rowClassName={rowClassName}
          rowStyle={{ cursor: 'pointer' }}
          // rowGetter={({ index }) => list[index]}
          rowGetter={({ index }) => sortedList[index]}
          sort={handleSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          onRowClick={({ rowData }) => handleClick(rowData)}
        >
          <Column
            label={label1}
            dataKey="title"
            cellRenderer={dataKey1}
            cellDataGetter={(data) => dataKey1(data)}
            width={240}
            style={{ textTransform: "capitalize" }}
          />
          <Column
            label={label2}
            dataKey='author.lastName'
            // cellRenderer={dataKey2}
            // cellDataGetter={({ rowData }) => getNestedValue(rowData, dataKey2)}
            cellDataGetter={({ rowData }) => dataKey2({ rowData })}
            cellRenderer={cellRendererWrapper(dataKey2)}
            width={240}
            style={{ textTransform: "capitalize" }}
          />
          <Column
            label={label3}
            dataKey='caseType'
            cellRenderer={dataKey3}
            cellDataGetter={(data) => dataKey3(data)}
            width={240}
            style={{ textTransform: "capitalize" }}
          />
          <Column
            label={label4}
            dataKey="status"
            cellRenderer={dataKey4}
            cellDataGetter={(data) => dataKey4(data)}
            width={240}
            style={{ textTransform: "capitalize" }}
          />
          <Column
            label={buttonLabel}
            cellRenderer={buttonDataKey}
            cellDataGetter={(data) => buttonDataKey(data)}
            width={190}
          />
        </Table>
      )}
    </AutoSizer>
  );
}
