import React, { useState, useEffect } from "react";
import { TextField, TextareaAutosize, Button, Box, useMediaQuery } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ClearIcon from "@material-ui/icons/Clear";
import "../../App.css";
import axios from "axios";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import validation from "../../lib/validations";
import { ClipLoader } from "react-spinners";
import { PhoneFormatter } from "../../lib/NumberFormatter";
import Modal from "@material-ui/core/Modal";
import useKochava from "../../hooks/useKochava";
import DatePickerInput from "./DateInput";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import moment from 'moment';
import Collapsible from 'react-collapsible';
import { debounce } from 'lodash'
import InputMask from 'react-input-mask'


export default function AddCase(props) {
  const { notify, firms, modal, setModal, self, cases, setCases } = props;
  const [caseType, setCaseType] = useState("");
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [content, setContent] = useState("");
  const [email, setEmail] = useState("");
  const [exClient, setExClient] = useState("");
  const [phone, setPhone] = useState("");
  const [type, setType] = useState("");
  const [dateOfAccident, setDateOfAccident] = useState("");
  const [healthInsurance, setHealthInsurance] = useState("");
  const [existingClients, setExistingClients] = useState([]);
  const [open, setOpen] = useState(false)
  const [additionalClientFields, setAdditionalClientFields] = useState(false)
  const [referredBy, setReferredBy] = useState('');
  const [dob, setDob] = useState('');
  const [ssn, setSSN] = useState('');
  const [address, setAddress] = useState('');
  const [hospital, setHospital] = useState('');
  const [policeDpt, setPoliceDpt] = useState('');
  const [location, setLocation] = useState('');
  const [injuries, setInjuries] = useState('');
  const [priorInjuries, setPriorInjuries] = useState('');
  const [vehicleOwner, setVehicleOwner] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [vehicleLocation, setVehicleLocation] = useState('');
  const [companionWith, setCompanionWith] = useState('');
  const [employer, setEmployer] = useState('');
  const [placeBorn, setPlaceBorn] = useState('');
  const [timeInFL, setTimeInFL] = useState('');
  const [pipCompany, setPipCompany] = useState('');
  const [pipInsured, setPipInsured] = useState('');
  const [pipClaimNo, setPipClaimNo] = useState('');
  const [pipPolicyNo, setPipPolicyNo] = useState('');
  const [tortCompany, setTortCompany] = useState('');
  const [tortInsured, setTortInsured] = useState('');
  const [tortDriver, setTortDriver] = useState('');
  const [tortClaimNo, setTortClaimNo] = useState('');
  const [tortPolicyNo, setTortPolicyNo] = useState('');

  const mobile = useMediaQuery("(max-width:850px)");

  const { recordActivity } = useKochava();
  let userId = exClient;

  const fetchExistingClients = debounce(async (query) => {
    let resp = await axios.get(`/firm/clients/${firms[0].Firm.id}?name=${query}`)
    setExistingClients([...resp.data.clients]);
  }, 300);

  useEffect(() => {
    if (exClient) {
      setDob(exClient.dob)
      setSSN(exClient.ssn)
    }
  }, [exClient])

  const addCase = async (e) => {
    setLoading(true);

    if (!exClient && validation.emailValidation(email)) {
      setLoading(false);
      console.log("Invalid email address format");
    } else {
      if (exClient) userId = exClient.id;

      axios
        .post("/posts", {
          // title,
          caseType,
          content,
          // status: "In Negotiation",
          status: "Investigation",
          userId: userId ? userId : null,
          user: { firstName, lastName, email, phone },
          firmId: firms[0].Firm.id,
          healthInsurance,
          dateOfAccident: moment(dateOfAccident).format("MM/DD/YYYY"),
          // Additional client fields
          referredBy, dob, ssn, address, hospital, policeDpt, location, injuries, priorInjuries, vehicleOwner, vehicleType, vehicleLocation, companionWith, employer, placeBorn, timeInFL, pipCompany, pipInsured, pipClaimNo, pipPolicyNo, tortCompany, tortInsured, tortDriver, tortClaimNo, tortPolicyNo

        })
        .then((resp) => {
          setLoading(false);
          window.location = "/cases";
          let post = resp.data.post;
          post.author = {
            firstName: exClient ? exClient.firstName : firstName,
            lastName: exClient ? exClient.lastName : lastName,
            id: exClient ? exClient.id : post.userId,
          };
          post.userId = post.userId;
          cases.unshift(post);
          setCases([...cases]);
          notify("Case created!", true);
        })
        .catch((err) => {
          console.log(
            "Error creating case",
            err.response ? err.response.data.message : err
          );
          if (
            err?.response?.data?.message ===
            "users.Users_email_unique must be unique"
          ) {
            notify("Email must be unique", false);
          } else {
            notify(
              err?.response?.data?.message
                ? err.response.data.message
                : "Error creating case. Please try again later.",
              false
            );
          }
          setLoading(false);
        });
      process.env.REACT_APP_TRACKING_ENABLED === "true" &&
        recordActivity("Case Uploaded");
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const inputProps = {
    variant: "outlined",
    color: "primary",
    style: styles.input,
    // inputProps: { maxLength: 50 },
  };

  const format = (v) => {
    v = v.slice(0, 11).replace(/-/g, "");
    if (v.length <= 3) {
      return v;
    }
    if (v.length > 3 && v.length <= 5) {
      return `${v.slice(0, 3)}-${v.slice(3)}`;
    }
    if (v.length > 5) {
      return `${v.slice(0, 3)}-${v.slice(3, 5)}-${v.slice(5)}`;
    }
  };

  const mask = (v) => {
    const masked = v.slice(0, 7).replace(/[0-9]/g, "*");
    const final = masked + v.slice(7);
    return final;
  };

  console.log("DOA", dateOfAccident)

  return (
    <div>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h1
          style={{
            fontSize: 24,
            color: "#5C5C5C",
            display: "flex",
            alignSelf: "flex-start",
            marginBottom: 28,
          }}
        >
          Create New Case
        </h1>

        <div>
          <div>
            {/* TABS */}
            <div>
              <p
                style={{
                  paddingBottom: 8,
                  margin: 0,
                  textAlign: "left",
                  fontWeight: 600,
                  fontSize: 16,
                }}
              >
                Client Information
              </p>
              <div style={{ paddingBottom: 5 }}>
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#000",
                      borderWidth: 10,
                    },
                  }}
                  textColor="primary"
                  centered
                  className="add-case-tabs"
                >
                  <Tab
                    label="New Client"
                    {...a11yProps(0)}
                    onClick={() => setExClient("")}
                    style={styles.tab}
                    className="add-case-tab"
                  />
                  <Tab
                    label="Existing Client"
                    {...a11yProps(1)}
                    onClick={() => {
                      setFirstName("");
                      setLastName("");
                      setEmail("");
                      setPhone("");
                    }}
                    style={styles.tab}
                    className="add-case-tab"
                  />
                </Tabs>
              </div>

              {/* NEW CLIENT */}
              <div>
                <TabPanel value={value} index={0}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      label="First Name"
                      name="firstName"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      {...inputProps}
                      inputProps={{ style: { textTransform: "capitalize" } }}
                    />
                    <TextField
                      label="Last Name"
                      name="lastName"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      {...inputProps}
                      inputProps={{ style: { textTransform: "capitalize" } }}
                    />
                    <PhoneFormatter
                      label="Phone Number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      {...inputProps}
                    />
                    <TextField
                      label="Email Address"
                      name="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      {...inputProps}
                    />

                    {!exClient &&
                      <Collapsible
                        onTriggerOpening={() => setOpen(true)}
                        onTriggerClosing={() => setOpen(false)}
                        trigger={(
                          <div>
                            Additional Client Fields
                            &nbsp;
                            {open ? <i class="fas fa-chevron-up"></i> : <i class="fas fa-chevron-down"></i>}
                          </div>
                        )}
                        triggerStyle={{ textAlign: 'right', cursor: 'pointer', color: 'blue', fontSize: 13 }}
                      >
                        <div>
                          <TextField
                            label='Referred By'
                            name='referredBy'
                            value={referredBy}
                            onChange={(e) => setReferredBy(e.target.value)}
                            {...inputProps}
                            fullWidth
                            multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='Companion with'
                            name='companionWith'
                            value={companionWith}
                            onChange={(e) => setCompanionWith(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='D/Birth'
                            name='dob'
                            value={dob}
                            onChange={(e) => setDob(e.target.value)}
                            {...inputProps}
                            fullWidth
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <InputMask
                            mask="999-99-9999"
                            maskChar=""
                            value={ssn}
                            onChange={e => setSSN(e.target.value)}
                            placeholder="Enter SSN"
                          >
                            {() => <TextField
                              label="SSN"
                              fullWidth
                              name="ssn"
                              {...inputProps}
                            />}
                          </InputMask>
                          <TextField
                            label='Address'
                            name='address'
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='Place Born'
                            name='placeBorn'
                            value={placeBorn}
                            onChange={(e) => setPlaceBorn(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='Time In FL'
                            name='timeInFL'
                            value={timeInFL}
                            onChange={(e) => setTimeInFL(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='Employer'
                            name='employer'
                            value={employer}
                            onChange={(e) => setEmployer(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='Police Dpt.'
                            name='policeDpt'
                            value={policeDpt}
                            onChange={(e) => setPoliceDpt(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='Location'
                            name='location'
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='Hospital'
                            name='hospital'
                            value={hospital}
                            onChange={(e) => setHospital(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='Injuries'
                            name='injuries'
                            value={injuries}
                            onChange={(e) => setInjuries(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='Prior Injuries'
                            name='priorInjuries'
                            value={priorInjuries}
                            onChange={(e) => setPriorInjuries(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='Vehicle (type)'
                            name='vehicleType'
                            value={vehicleType}
                            onChange={(e) => setVehicleType(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='Vehicle Owner'
                            name='vehicleOwner'
                            value={vehicleOwner}
                            onChange={(e) => setVehicleOwner(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='Vehicle Location'
                            name='vehicleLocation'
                            value={vehicleLocation}
                            onChange={(e) => setVehicleLocation(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='PIP Company'
                            name='pipCompany'
                            value={pipCompany}
                            onChange={(e) => setPipCompany(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='PIP Insured'
                            name='pipInsured'
                            value={pipInsured}
                            onChange={(e) => setPipInsured(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='PIP Policy No.'
                            name='pipPolicyNo'
                            value={pipPolicyNo}
                            onChange={(e) => setPipPolicyNo(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='PIP Claim No.'
                            name='pipClaimNo'
                            value={pipClaimNo}
                            onChange={(e) => setPipClaimNo(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='Tort Company'
                            name='tortCompany'
                            value={tortCompany}
                            onChange={(e) => setTortCompany(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='Tort Insured'
                            name='tortInsured'
                            value={tortInsured}
                            onChange={(e) => setTortInsured(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='Tort Driver'
                            name='tortDriver'
                            value={tortDriver}
                            onChange={(e) => setTortDriver(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='Tort Policy No.'
                            name='tortPolicyNo'
                            value={tortPolicyNo}
                            onChange={(e) => setTortPolicyNo(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                          <TextField
                            label='Tort Claim No.'
                            name='tortClaimNo'
                            value={tortClaimNo}
                            onChange={(e) => setTortClaimNo(e.target.value)}
                            {...inputProps}
                            fullWidth multiline
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                          />
                        </div>
                      </Collapsible>}
                  </div>
                </TabPanel>

                {/* EXISTING CLIENT */}
                <TabPanel value={value} index={1}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={existingClients}
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName}`
                    }
                    renderOption={(option) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            padding: "8px 0",
                            textTransform: "capitalize",
                          }}
                        >
                          {`${option.firstName} ${option.lastName}`}
                        </div>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search Client Name"
                        variant="outlined"
                        fullWidth
                        style={{ margin: "2.8% 0" }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <SearchIcon style={{ marginRight: 4 }} />
                          ),
                          style: { paddingLeft: 15 },
                        }}
                        inputProps={{
                          ...params.inputProps,
                          style: { textTransform: "capitalize" },
                        }}
                      />
                    )}
                    onInputChange={e => fetchExistingClients(e.target.value)}
                    onChange={(e, user) => {
                      setExClient(user ? user : "");
                    }}
                  />

                  {exClient &&
                    <Collapsible
                      onTriggerOpening={() => setOpen(true)}
                      onTriggerClosing={() => setOpen(false)}
                      trigger={(
                        <div>
                          Additional Client Fields
                          &nbsp;
                          {open ? <i class="fas fa-chevron-up"></i> : <i class="fas fa-chevron-down"></i>}
                        </div>
                      )}
                      triggerStyle={{ textAlign: 'right', cursor: 'pointer', color: 'blue', fontSize: 13 }}
                    >
                      <div>
                        {console.log("EX", exClient)}

                        <TextField
                          label='Referred By'
                          name='referredBy'
                          value={referredBy}
                          onChange={(e) => setReferredBy(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='Companion with'
                          name='companionWith'
                          value={companionWith}
                          onChange={(e) => setCompanionWith(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='D/Birth'
                          name='dob'
                          value={dob}
                          onChange={(e) => setDob(e.target.value)}
                          {...inputProps}
                          fullWidth
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <InputMask
                          mask="999-99-9999"
                          maskChar=""
                          value={ssn}
                          onChange={e => setSSN(e.target.value)}
                          placeholder="Enter SSN"
                        >
                          {() => <TextField
                            label="SSN"
                            fullWidth
                            name="ssn"
                            {...inputProps}
                          />}
                        </InputMask>
                        <TextField
                          label='Address'
                          name='address'
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='Place Born'
                          name='placeBorn'
                          value={placeBorn}
                          onChange={(e) => setPlaceBorn(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='Time In FL'
                          name='timeInFL'
                          value={timeInFL}
                          onChange={(e) => setTimeInFL(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='Employer'
                          name='employer'
                          value={employer}
                          onChange={(e) => setEmployer(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='Police Dpt.'
                          name='policeDpt'
                          value={policeDpt}
                          onChange={(e) => setPoliceDpt(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='Location'
                          name='location'
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='Hospital'
                          name='hospital'
                          value={hospital}
                          onChange={(e) => setHospital(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='Injuries'
                          name='injuries'
                          value={injuries}
                          onChange={(e) => setInjuries(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='Prior Injuries'
                          name='priorInjuries'
                          value={priorInjuries}
                          onChange={(e) => setPriorInjuries(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='Vehicle (type)'
                          name='vehicleType'
                          value={vehicleType}
                          onChange={(e) => setVehicleType(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='Vehicle Owner'
                          name='vehicleOwner'
                          value={vehicleOwner}
                          onChange={(e) => setVehicleOwner(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='Vehicle Location'
                          name='vehicleLocation'
                          value={vehicleLocation}
                          onChange={(e) => setVehicleLocation(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='PIP Company'
                          name='pipCompany'
                          value={pipCompany}
                          onChange={(e) => setPipCompany(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='PIP Insured'
                          name='pipInsured'
                          value={pipInsured}
                          onChange={(e) => setPipInsured(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='PIP Policy No.'
                          name='pipPolicyNo'
                          value={pipPolicyNo}
                          onChange={(e) => setPipPolicyNo(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='PIP Claim No.'
                          name='pipClaimNo'
                          value={pipClaimNo}
                          onChange={(e) => setPipClaimNo(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='Tort Company'
                          name='tortCompany'
                          value={tortCompany}
                          onChange={(e) => setTortCompany(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='Tort Insured'
                          name='tortInsured'
                          value={tortInsured}
                          onChange={(e) => setTortInsured(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='Tort Driver'
                          name='tortDriver'
                          value={tortDriver}
                          onChange={(e) => setTortDriver(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='Tort Policy No.'
                          name='tortPolicyNo'
                          value={tortPolicyNo}
                          onChange={(e) => setTortPolicyNo(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='Tort Claim No.'
                          name='tortClaimNo'
                          value={tortClaimNo}
                          onChange={(e) => setTortClaimNo(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                        <TextField
                          label='Tort Policy No.'
                          name='tortPolicyNo'
                          value={tortPolicyNo}
                          onChange={(e) => setTortPolicyNo(e.target.value)}
                          {...inputProps}
                          fullWidth multiline
                          inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                      </div>
                    </Collapsible>
                  }
                </TabPanel>
              </div>
            </div>

            <p
              style={{
                textAlign: "left",
                padding: "10% 0% 4.4% 0%",
                margin: 0,
                fontWeight: 600,
                fontSize: 16,
              }}
            >
              Case Information
            </p>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {/* <TextField
                label="Case Name"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                {...inputProps}
                inputProps={{ style: { textTransform: "capitalize" } }}
              /> */}
              <TextField
                label="Case Type"
                name="case type"
                value={caseType}
                onChange={(e) => setCaseType(e.target.value)}
                {...inputProps}
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
              <DatePickerInput
                placeholder="Date of Loss"
                startDate={dateOfAccident}
                setStartDate={setDateOfAccident}
              />
              <TextField
                label="Summary"
                name="content"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                // {...inputProps, {maxLength: 1000}}
                variant="outlined"
                color="primary"
                style={styles.input}
                maxLength={1000}
                multiline
                minRows={7}
              />
            </div>
            <div
              style={{
                maxWidth: 340,
                width: "100%",
                margin: "15px auto",
              }}
            >
              <p
                style={{
                  fontSize: 16,
                  margin: 0,
                  lineHeight: "24px",
                  fontWeight: 400,
                  color: "#5C5C5C",
                }}
              >
                By adding this client you will be notifying them to download the
                app.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: mobile ? "column" : "row",
          width: mobile ? "100%" : 514,
          marginLeft: "auto",
          marginRight: "auto",
          paddingBottom: 40,
        }}
      >
        <Button
          className="btn clear add-btn add-case"
          onClick={() => (window.location = "/cases")}
        >
          Cancel
        </Button>
        <Button
          className="btn light-blue add-btn add-case"
          onClick={() => addCase()}
          disabled={
            loading || !caseType || exClient
              ? !exClient
              : !firstName || !lastName || !email || !phone || !dateOfAccident
          }
        >
          {loading ? (
            <div
              className="sweet-loading"
              style={{
                position: "absolute",
                width: 15,
                height: 15,
                top: 13,
              }}
            >
              <ClipLoader size={20} color="#fff" loading={loading} />
            </div>
          ) : (
            <span>Save</span>
          )}
        </Button>
      </div>
    </div>
  );
}

const styles = {
  input: {
    margin: "2.8% 0",
    textAlign: "left",
  },
  tab: {
    minWidth: "40%",
    textTransform: "capitalize",
    fontSize: 16,
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// TAB PANEL
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
