import React, { useState, useEffect } from "react";
import { Button, TextField } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "../../App.css";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import validations from "../../lib/validations";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { CSSTransition } from "react-transition-group";
import { useParams } from "react-router-dom";
import axios from "axios";

export default function CreateNewPassword(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [isPasswordSecure, setIsPasswordSecure] = useState(null);
  const [unmatchedPassword, setUnmatchedPassword] = useState(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);
  const [hasNumericValue, setHasNumericValue] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasPasswordLength, setHasPasswordLength] = useState(false);
  const [fade, setFade] = useState(false);
  const { notify } = props;

  const inputProps = {
    variant: "outlined",
    color: "primary",
    className: classes.input,
    inputProps: { maxLength: 50 },
  };

  useEffect(() => {
    setFade(true);
  }, []);

  const handleCancel = () => (window.location = "/");

  const override = css`
    position: absolute;
  `;

  const handleChange = (value) => {
    setPassword(value);
    validatePassword(value);
    handleMatchingPassword(value);
  };

  const handleMatchingPassword = (value) => {
    if (password === value || passwordConfirmation === value) {
      setUnmatchedPassword(false);
    } else {
      setUnmatchedPassword(true);
    }
  };

  const validatePassword = (value) => {
    validations.hasSpecialCharacter(value)
      ? setHasSpecialCharacter(true)
      : setHasSpecialCharacter(false);
    validations.hasNumericValue(value)
      ? setHasNumericValue(true)
      : setHasNumericValue(false);
    validations.hasUppercase(value)
      ? setHasUpperCase(true)
      : setHasUpperCase(false);
    value.length > 7 ? setHasPasswordLength(true) : setHasPasswordLength(false);
  };

  const handleClick = () => {
    if (
      !hasUpperCase ||
      !hasNumericValue ||
      !hasPasswordLength ||
      !hasSpecialCharacter
    ) {
      setIsPasswordSecure(false);
    } else if (password != passwordConfirmation) {
      setUnmatchedPassword(true);
    } else {
      handleSubmit();
    }
  };

  let { id } = useParams();

  console.log("ID", id);

  const handleSubmit = () => {
    setLoading(true);
    let userId = id;
    console.log("userId", userId);

    axios
      .post(
        "/auth/reset/password",
        {
          userId,
          password,
        },
        { headers: { Authorization: process.env.REACT_APP_AUTH_TOKEN } }
      )
      .then((resp) => {
        console.log("New password created", resp);
        window.location = "/";
      })
      .catch((err) => {
        setLoading(false);
        console.log(
          "Error creating a new password",
          err?.response ? err.response : err
        );
        notify("Error creating a new password. Please try again.", false);
      });
  };

  const enterPressed = (event) => {
    if (password && passwordConfirmation) {
      var code = event.keyCode || event.which;
      if (code === 13) {
        handleSubmit();
      }
    }
  };

  return (
    <CSSTransition in={fade} timeout={700} classNames="my-node">
      {fade ? (
        <div className="sign-in">
          {!loading ? (
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div>
                <h1 style={{ margin: "17px auto", fontSize: 32 }}>
                  Create New Password
                </h1>

                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div className={classes.relative}>
                    <TextField
                      fullWidth
                      label="New Password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onKeyPress={enterPressed}
                      onChange={(e) => handleChange(e.target.value)}
                      InputLabelProps={{ className: classes.label }}
                      {...inputProps}
                    />
                    <div
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {showPassword ? (
                        <AiOutlineEyeInvisible
                          size={20}
                          className={classes.absolute}
                        />
                      ) : (
                        <AiOutlineEye size={20} className={classes.absolute} />
                      )}
                    </div>
                  </div>

                  <div className={classes.relative}>
                    <TextField
                      fullWidth
                      label="Confirm New Password"
                      type={showPasswordConfirmation ? "text" : "password"}
                      value={passwordConfirmation}
                      onKeyPress={enterPressed}
                      onChange={(e) => {
                        setPasswordConfirmation(e.target.value);
                        handleMatchingPassword(e.target.value);
                      }}
                      InputLabelProps={{ className: classes.label }}
                      {...inputProps}
                    />
                    <div
                      onClick={() =>
                        setShowPasswordConfirmation(!showPasswordConfirmation)
                      }
                    >
                      {showPasswordConfirmation ? (
                        <AiOutlineEyeInvisible
                          size={20}
                          className={classes.absolute}
                        />
                      ) : (
                        <AiOutlineEye size={20} className={classes.absolute} />
                      )}
                    </div>
                  </div>

                  {passwordConfirmation && unmatchedPassword && (
                    <p className={classes.errorMessage}>
                      The passwords entered do not match
                    </p>
                  )}

                  {isPasswordSecure === false && (
                    <p className={classes.errorMessage}>
                      Your new password must meet the four conditions below:
                    </p>
                  )}
                </Box>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    margin: "16px 0",
                  }}
                >
                  <Box my={1} className="passwordValidationText">
                    <div
                      className={classes.icon}
                      style={{ visibility: !hasPasswordLength && "hidden" }}
                    >
                      <i className="checkmark fas fa-check" />
                    </div>
                    <p className={classes.text}>8 Characters Minimum</p>
                  </Box>
                  <Box my={1} className="passwordValidationText">
                    <div className={classes.icon}>
                      <i
                        className={"checkmark fas fa-check"}
                        style={{ visibility: !hasUpperCase && "hidden" }}
                      />
                    </div>
                    <p className={classes.text}>At least 1 capital letter</p>
                  </Box>
                  <Box my={1} className="passwordValidationText">
                    <div className={classes.icon}>
                      <i
                        className={"checkmark fas fa-check"}
                        style={{ visibility: !hasNumericValue && "hidden" }}
                      />
                    </div>
                    <p className={classes.text}>At least 1 numeric value</p>
                  </Box>
                  <Box my={1} className="passwordValidationText">
                    <div className={classes.icon}>
                      <i
                        className={"checkmark fas fa-check"}
                        style={{
                          visibility: !hasSpecialCharacter && "hidden",
                        }}
                      />
                    </div>
                    <p className={classes.text}>At least 1 special character</p>
                  </Box>
                </div>

                <Box
                  className={classes.buttons}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    className="submit-btn btn green"
                    onClick={() => {
                      handleClick();
                    }}
                    disabled={
                      !password ||
                      !passwordConfirmation ||
                      unmatchedPassword ||
                      loading
                    }
                  >
                    Submit
                  </Button>
                  <button
                    className={classes.button}
                    style={{ marginTop: "35px", fontSize: 16 }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </Box>
              </div>
            </Box>
          ) : (
            <div
              className="sweet-loading"
              style={{
                height: "calc(100vh - 60px)",
                margin: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ClipLoader
                css={override}
                size={75}
                color={"rgb(47, 86, 138)"}
                loading={loading}
              />
            </div>
          )}
        </div>
      ) : (
        <div />
      )}
    </CSSTransition>
  );
}

const useStyles = makeStyles((theme) => ({
  input: {
    margin: "16px 0",
    width: 350,
    [theme.breakpoints.down(376)]: {
      width: "100%",
    },
  },
  buttons: {
    marginTop: 84,
    [theme.breakpoints.down(376)]: {
      marginTop: 54,
    },
  },
  button: {
    fontWeight: "bold",
    textTransform: "none",
    color: "#2A528C",
    fontFamily: "inherit",
    border: "none",
    background: "none",
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      color: "#6db0eb",
    },
  },
  relative: {
    position: "relative",
    width: "100%",
  },
  absolute: {
    position: "absolute",
    top: 34,
    right: 16,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  text: {
    color: "#4A4A4A",
  },
  errorMessage: {
    margin: "0px 0px 8px",
    fontSize: 12,
    color: "#ff7070",
    textAlign: "left",
  },
}));
