import React, { useState, useEffect } from "react";
import { Button, TextField } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "../../App.css";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import validations from "../../lib/validations";
import { CSSTransition } from "react-transition-group";

export default function ForgotPassword(props) {
  const classes = useStyles();
  const [validateEmail, setValidateEmail] = useState(false);
  const [fade, setFade] = useState(false);
  const {
    email,
    setEmail,
    onChange,
    onSubmit,
    enterPressed,
    loading,
    setLoading,
  } = props;

  useEffect(() => {
    setFade(true);
  }, []);

  const inputProps = {
    variant: "outlined",
    color: "primary",
    inputProps: { maxLength: 50 },
  };

  const handleCancel = () => (window.location = "/");

  const override = css`
    position: absolute;
  `;

  return (
    <CSSTransition in={fade} timeout={700} classNames="my-node">
      {fade ? (
        <>
          {!loading ? (
            <Box
              className="sign-in"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div>
                <h1 style={{ margin: "21px 19.2px", fontSize: 32 }}>
                  Forgot Password
                </h1>
                <p
                  style={{
                    lineHeight: "24px",
                    margin: "0 1.2rem",
                    color: "#4A4A4A",
                    fontWeight: "500",
                  }}
                >
                  Enter your email and we will send you a new password.
                </p>

                <Box
                  className={classes.form}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    label="Email Address"
                    name="email"
                    onKeyPress={enterPressed}
                    className={classes.input}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      !validations.emailValidation(email) &&
                        setValidateEmail(false);
                    }}
                    onBlur={() => {
                      validations.emailValidation(email)
                        ? setValidateEmail(true)
                        : setValidateEmail(false);
                    }}
                    {...inputProps}
                  />
                  {email && validateEmail && (
                    <p
                      style={{
                        margin: "8px 0",
                        marginLeft: 0,
                        fontSize: 14,
                        color: "#ff7070",
                        textAlign: "left",
                      }}
                    >
                      Invalid email address format
                    </p>
                  )}
                </Box>

                <Box
                  className={classes.buttons}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Button
                    className="submit-btn btn green"
                    onClick={() => {
                      setLoading(true);
                      onSubmit();
                    }}
                    disabled={!email || validateEmail}
                  >
                    Submit
                  </Button>
                  <button
                    className={classes.button}
                    style={{ marginTop: "20px", fontSize: 16 }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </Box>
              </div>
            </Box>
          ) : (
            <div
              className="sweet-loading"
              style={{
                height: "calc(100vh - 60px)",
                margin: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ClipLoader
                css={override}
                size={75}
                color={"rgb(47, 86, 138)"}
                loading={loading}
              />
            </div>
          )}
        </>
      ) : (
        <div />
      )}
    </CSSTransition>
  );
}

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "#fff",
  },
  form: {
    [theme.breakpoints.up("sm")]: {
      margin: "45px 38px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "38px 38px",
    },
  },
  buttons: {
    [theme.breakpoints.up("sm")]: {
      margin: "168px 16px 0",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "145px 16px 0",
    },
  },
  button: {
    fontWeight: "bold",
    textTransform: "none",
    color: "#2A528C",
    fontFamily: "inherit",
    border: "none",
    background: "none",
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      color: "#6db0eb",
    },
  },
}));
