import React, { useEffect, useState } from "react";
import "./App.css";
import axios from "axios";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";
import data from "./lib/data";
import Logo from "./nav-logo.svg";
import Providers from "./components/Providers/Providers";
import Cases from "./components/Cases/Cases";
import Case from "./components/Cases/Case";
import Account from "./components/Account/Account";
import Users from "./components/Users/Users";
import Signature from "./components/Auth/Signature";
import Login from "./components/SignIn/Login";
import ForgotPassword from "./components/Password/index";
import Navigation from "./components/Navigation/Navigation";
import Templates from "./components/Templates/Templates";
import { Elements, StripeProvider } from "react-stripe-elements";
import ReactDOM from "react-dom";
import { SettingsSystemDaydreamOutlined } from "@material-ui/icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RegistrationNavigation from "./components/Navigation/RegistrationNavigation";
import CreateNewPassword from "./components/Password/CreateNewPassword";
import ClientDetails from "./components/Cases/Tabs/ClientDetails";
import { KochavaProvider } from "./context/kochavaContext";
import Billing from "./components/SignUp/Billing/Billing";
import SignUp from "./components/SignUp/index.js";
import { ClipLoader } from "react-spinners";
import AddCase from "./components/Cases/AddCase";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-MZ3TPZR",
};

TagManager.initialize(tagManagerArgs);

// Global defaults
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
// global.awsURL = 'https://attorney-portal-test.s3.us-west-1.amazonaws.com/images'


function App() {
  const [auth, setAuth] = useState(null);
  const [verified, setVerified] = useState(null);
  const [user, setUser] = useState(null);
  const [modal, setModal] = useState(null);
  const [team, setTeam] = useState([]);
  const [firms, setFirms] = useState([]);
  const [providers, setProviders] = useState([]);
  const [cases, setCases] = useState([]);
  const [users, setUsers] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [status, setStatus] = useState("");
  const [casesLoaded, setCasesLoaded] = useState(false);
  const [templatesLoaded, setTemplatesLoaded] = useState(false);
  const [providersLoaded, setProvidersLoaded] = useState(false);
  const [teamLoaded, setTeamLoaded] = useState(false);
  const [planDetails, setPlanDetails] = useState(null);
  const [cancelled, setCancelled] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [payment, setPayment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);


  // Initiates new or active session and fetches the data store
  useEffect(() => {
    (async () => {
      let auth = localStorage.getItem("auth");

      if (auth) {
        setAuth(auth);
        // Sets default authorization
        axios.defaults.headers.common["Authorization"] = auth;

        try {
          let user = await data.getSelf();
          console.log("Logged in user:", user);
          setUser(user);
        } catch (err) {
          console.log("Error fetching user data:", err);
          // Logs out user if not found
          localStorage.clear();
          window.location = "/";
        }
      } else setVerified(false);
    })();
  }, []);

  useEffect(() => {
    if (user?.stripeSubscriptionId) {
      setIsSubscribed(true);
    }
  }, [user]);

  function checkVerification(auth) {
    data
      .getFirm(auth)
      .then((resp) => {
        // console.log("Firms found:", resp);
        getPayment();
        getPlanDetails();
        if (resp.length > 0) {
          setVerified(true);
          setFirms(resp);
          getPayment();

          let { firmId } = resp[0];
          data.getProviders(null, firmId).then((providers) => {
            setProvidersLoaded(true);
            setProviders(providers);
          });

          data.getTeam(null, firmId).then((data) => {
            console.log("TEAM DATA", data)
            setTeamLoaded(true);
            setTeam(data);
          });

          data.getCases(null, null, firmId).then((cases) => {
            setCases(cases);
            setCasesLoaded(true);
            data.getTemplates(firmId).then((templates) => {
              setTemplatesLoaded(true);
              setTemplates(templates);
            });
          });
        } else {
          setVerified(false);
        }
      })
      .catch((err) => {
        console.log(
          "Error checking user firms",
          err.response ? err.response.data.message : err
        );
        setVerified(false);
      });
  }

  useEffect(() => {
    if (auth) {
      axios.defaults.headers.common["Authorization"] = auth;
      setVerified(null);
      checkVerification(auth);
      data.getSelf().then((user) => setUser(user));
    }
  }, [auth]);

  function fetchUser() {
    if (user && !user) setUser(user);
  }

  //React Toastify
  const notify = (msg, isSuccess) => {
    toast(
      <div
        style={{
          color: isSuccess ? "#0f9a15" : "#e72525",
          textAlign: "center",
        }}
      >
        {msg}
      </div>
    );
  };

  const getPlanDetails = () => {
    setIsLoading(true);
    axios
      .get("/stripe/subscription")
      .then((resp) => {
        console.log(
          "Plan details resp",
          resp,
          resp.data.data.cancel_at_period_end
        );
        setPlanDetails(resp.data.data);
        setIsLoading(false);

        if (resp.data.data.cancel_at_period_end) {
          setCancelled(true);
        } else {
          setCancelled(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setCancelled(false);
        console.log("Error getting subscription data", err.response);
      });
  };

  function getPayment() {
    axios
      .post("/stripe/payment")
      .then((resp) => {
        console.log("Payment resp", resp);
        setPayment(resp.data.data);
      })
      .catch((err) => console.log("Error getting payment data", err.response));
  }

  return (
    <div className="App">
      <KochavaProvider>
        <ToastContainer
          style={{
            width: "100%",
            margin: "0 auto",
            position: "fixed",
            top: 0,
          }}
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          closeButton={false}
          limit={1}
        />

        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
          <Elements>
            <Router>
              <header className="App-header">
                <div style={{ width: "100%", flex: 1 }}>
                  <div style={styles.navigation}>
                    <img
                      src={Logo}
                      onClick={() => (window.location = "/")}
                      alt="attorney-jared-bossola"
                      style={{ cursor: "pointer", marginLeft: "2%" }}
                    />

                    {auth ? (
                      <Navigation verified={verified} cancelled={cancelled} />
                    ) : (
                      <RegistrationNavigation />
                    )}
                  </div>
                </div>
              </header>

              <div
                className={
                  window.location.pathname === "/sign-up"
                    ? "App-lg-width"
                    : "App-content"
                }
              >
                {verified != null && (
                  <Switch>
                    {!auth && [
                      <Route
                        exact
                        path="/"
                        component={() => (
                          <Login
                            setAuth={(auth) => setAuth(auth)}
                            notify={notify}
                          />
                        )}
                      />,

                      <Route
                        exact
                        path="/signature/:docId"
                        component={Signature}
                      />,
                      <Route
                        exact
                        path="/sign-up"
                        render={(props) => (
                          <SignUp
                            notify={notify}
                            firms={firms}
                            getFirm={async (token) =>
                              setFirms(await data.getFirm(token))
                            }
                            setAuth={(auth) => setAuth(auth)}
                            {...props}
                          />
                        )}
                      />,

                      <Route
                        exact
                        path="/forgot-password"
                        render={() => <ForgotPassword notify={notify} />}
                      />,
                      <Route
                        exact
                        path="/new-password/:id"
                        render={() => <CreateNewPassword notify={notify} />}
                      />,
                      <Route path="/signature/:docId" component={Signature} />,
                      <Route
                        render={() => {
                          return <Redirect to="/" />;
                        }}
                      />,
                    ]}
                    {!isLoading ? (
                      auth ? (
                        [
                          <Route
                            exact
                            path="/providers"
                            render={() => (
                              <Providers
                                self={user}
                                cases={cases}
                                firms={firms}
                                searchProviders={async (query) => {
                                  console.log("query here", query);
                                  setProviders(
                                    await data.getProviders(query, firms[0].id)
                                  );
                                }}
                                providersLoaded={providersLoaded}
                                // searchUsers={async (query, order) => setCases(await data.getCases(query, order))}
                                modal={modal}
                                setModal={setModal}
                                providers={providers}
                                setProviders={setProviders}
                                notify={notify}
                                isSubscribed={isSubscribed}
                              />
                            )}
                          />,
                          <Route
                            // exact
                            path="/account"
                            render={(props) => (
                              <Account
                                cancelled={cancelled}
                                auth={auth}
                                // setAuth={setAuth}
                                self={user}
                                getSelf={() =>
                                  data.getSelf().then((user) => setUser(user))
                                }
                                // getFirm={() =>
                                //   data.getFirm().then((firms) => setFirms(firms))
                                // }
                                fetchUser={fetchUser}
                                modal={modal}
                                setModal={setModal}
                                verified={verified}
                                checkVerification={checkVerification}
                                firms={firms}
                                notify={notify}
                                payment={payment}
                                {...props}
                                getFirm={async (token) =>
                                  setFirms(await data.getFirm(token))
                                }
                                setAuth={(auth) => setAuth(auth)}
                                planDetails={planDetails}
                                isLoading={isLoading}
                                setIsSubscribed={setIsSubscribed}
                              />
                            )}
                          />,
                          <Route
                            path="/team"
                            render={() => (
                              <Users
                                self={user}
                                team={team}
                                firms={firms}
                                teamLoaded={teamLoaded}
                                setTeam={setTeam}
                                modal={modal}
                                setModal={setModal}
                                searchUsers={async (query, order) =>
                                  setUsers(await data.getUsers(query, order))
                                }
                                notify={notify}
                                isSubscribed={isSubscribed}
                              />
                            )}
                          />,

                          // {auth && (
                          <Route
                            path="/case"
                            render={(props) => (
                              <Case
                                {...props}
                                modal={modal}
                                setModal={setModal}
                                status={status}
                                setStatus={setStatus}
                                statuses={statuses}
                                setStatuses={setStatuses}
                                providers={providers}
                                templates={templates}
                                setTemplates={setTemplates}
                                notify={notify}
                                firms={firms}
                                user={user}
                                cases={cases}
                                setCases={setCases}
                              />
                            )}
                          />,
                          // )}
                          <Route
                            path="/cases"
                            render={(props) => (
                              <Cases
                                firms={firms}
                                users={users}
                                cases={cases}
                                setCases={setCases}
                                modal={modal}
                                setModal={setModal}
                                notify={notify}
                                self={user}
                                casesLoaded={casesLoaded}
                                isSubscribed={isSubscribed}
                                {...props}
                              />
                            )}
                          />,
                          <Route
                            path="/add-case"
                            render={(props) => (
                              <AddCase
                                {...props}
                                modal={modal}
                                setModal={setModal}
                                status={status}
                                setStatus={setStatus}
                                statuses={statuses}
                                setStatuses={setStatuses}
                                providers={providers}
                                templates={templates}
                                setTemplates={setTemplates}
                                notify={notify}
                                firms={firms}
                                user={user}
                                cases={cases}
                                setCases={setCases}
                              />
                            )}
                          />,
                          <Route
                            path="/client-details"
                            render={(props) => (
                              <ClientDetails {...props} notify={notify} />
                            )}
                          />,
                          <Route
                            path="/templates"
                            render={() => (
                              <Templates
                                self={user}
                                templatesLoaded={templatesLoaded}
                                setTemplatesLoaded={setTemplatesLoaded}
                                firms={firms}
                                templates={templates}
                                setTemplates={setTemplates}
                                notify={notify}
                                user={user}
                                isSubscribed={isSubscribed}
                              />
                            )}
                          />,
                          <Route
                            path="/"
                            render={() => {
                              if (verified == null || cancelled == null) return null
                              return (
                                <Redirect
                                  to={
                                    verified === false || cancelled
                                      ? "/account"
                                      : "/cases"
                                  }
                                />
                              );
                            }}
                          />
                        ]
                      ) : (
                        [
                          <Route
                            path="/"
                            render={() => (
                              // console.log("EVAL", verified == false || cancelled, verified == false, cancelled)
                              <Redirect
                                to={
                                  verified == false || cancelled
                                    ? "/account"
                                    : "/cases"
                                }
                              />
                            )}
                          />,
                        ]
                      )
                    ) : (
                      <div
                        className="sweet-loading"
                        style={{
                          width: 100,
                          height: 75,
                          margin: "10% auto",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ClipLoader
                          style={{ position: "absolute" }}
                          size={75}
                          color={"rgb(47, 86, 138)"}
                        />
                      </div>
                    )}
                  </Switch>
                )}

                {/* Modal */}
                {/* {modal && (
                <div
                  onClick={() => setModal(false)}
                  style={{
                    position: "fixed",
                    top: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(0,0,0,0.4)",
                  }}
                />
              )} */}
              </div>
            </Router>
          </Elements>
        </StripeProvider>
      </KochavaProvider>
    </div>
  );
}

const styles = {
  navigation: {
    backgroundColor: "#2f568a",
    height: 60,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 999,
  },
};

export default App;
