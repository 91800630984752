import React, { useEffect, useState } from "react";
import "../../App.css";
import { Autocomplete } from "@material-ui/lab";
import {
  TextField,
  Button,
  InputAdornment,
  Input,
  Box,
  Grid,
  IconButton,
  FormControlLabel,
} from "@material-ui/core";
import axios from "axios";
import ClearIcon from "@material-ui/icons/Clear";
import Modal from "@material-ui/core/Modal";
import { CSSTransition } from "react-transition-group";
import "react-notifications/lib/notifications.css";
import AddProvider from "./AddProvider";
import { PowerSettingsNew, SettingsBluetoothSharp } from "@material-ui/icons";
import { ClipLoader } from "react-spinners";
import data from "../../lib/data";
import check from "../../assets/check.svg";
import { makeStyles } from "@material-ui/core/styles";
import EditProvider from "./EditProvider";
import Table from "./ProvidersTable";
import ZeroStateModal from "../Modal/ZeroStateModal";

export default function Providers(props) {
  const {
    cases,
    getCases,
    modal,
    setModal,
    firms,
    notify,
    searchProviders,
    self,
    providersLoaded,
    isSubscribed,
  } = props;
  const classes = useStyles();

  const [providers, setProviders] = useState([]);
  const [activeObject, setActiveObject] = useState(null);
  const [fade, setFade] = useState(false);
  const [addProviderModal, setAddProviderModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [casesArr, setCasesArr] = useState([]);
  const [businessId, setBusinessId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [caseTitle, setCaseTitle] = useState(null);
  const [caseId, setCaseId] = useState(null);
  const [providerCases, setProviderCases] = useState(null);
  const [render, setRender] = useState(false);
  const [editProviderModal, setEditProviderModal] = useState(false);
  const [provider, setProvider] = useState();
  const [query, setQuery] = useState(null);
  const [filteredProviders, setFilteredProviders] = useState([]);

  // Receives business data when ready
  useEffect(() => {
    setFade(true);
    fetchBusinesses();
  }, []);

  useEffect(() => {
    setProviders(props.providers);
    //resets filteredProviders when a new provider is added
    setFilteredProviders([]);
  }, [props.providers]);

  useEffect(() => {
    if (props.providers.length > 0 && query) {
      fetchFilteredProviders(query);
    }
  }, [query]);

  useEffect(() => {
    fetchBusinesses();
    if (cases) {
      for (let i = 0; i < cases.length; i++) {
        console.log("ITEM", cases[i])
        if (cases[i].author) {
          cases[i].name =
            cases[i].author.firstName + " " + cases[i].author.lastName;
          cases[i].userId = cases[i].author.id;
          console.log("cases[i].author.id", cases[i].author.id);
          console.log("cases[i]", cases[i]);
        }
      }
      setCasesArr(cases);
    }
  }, [cases]);

  const fetchProviderCases = (providerId) => {
    data.getProviderCases(providerId).then((provider) => {
      setProviderCases(provider);
    });
  };

  const fetchBusinesses = () => {
    if (props.providers && props.providers.length !== providers.length) {
      setProviders(props.providers);
    }
  };

  const assignProvider = () => {
    setLoading(true);
    axios
      .post("/usersBusiness", {
        userId,
        postId: caseId,
        businessId,
        firmId: firms[0].firmId,
      })
      .then((resp) => {
        notify("Provider assigned!", true);
        setModal(false);
        setCaseId("");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(
          "Error assigning provider",
          err?.response ? err.response : err
        );
        notify("Error assigning provider. Please try again.", false);
        setModal(false);
      });
  };

  const fetchFilteredProviders = (searchResult) => {
    const query = searchResult.toLowerCase();
    const firmId = cases[0]?.firmId;
    if (firmId) {
      data.getProviders(searchResult, firmId).then((providers) => {
        setFilteredProviders([...providers]);
      });
    }
  };

  const getProviderId = (option) => {
    let provider = providerCases?.find(
      (provider) => provider.userId == option.userId
    );
    return provider;
  };

  const handleClose = () => {
    setModal(false);
  };

  const editAndAssignProvider = (data) => {
    const { rowData } = data;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginRight: 10,
        }}
      >
        {/* EDIT PROVIDER */}
        <div
          onMouseEnter={() => {
            let obj = rowData;
            setProvider(obj);
            setRender(!render);
          }}
          onMouseLeave={() => {
            let obj = rowData;
            setProvider(obj);
            setRender(!render);
          }}
        >
          <FormControlLabel
            onClick={(event) => {
              event.stopPropagation();
              setSelected(rowData);
              setEditProviderModal(true);
            }}
            onFocus={(event) => event.stopPropagation()}
            control={
              <i className="fas fa-pen edit-icon" />
            }
          />
        </div>

        {/* ASSIGN PROVIDER*/}
        <div>
          <Button
            className="view-case"
            onClick={(e) => {
              e.stopPropagation();
              setModal(true);
              setSelected(rowData);
              setActiveObject(rowData);
              setBusinessId(rowData.id);
              fetchProviderCases(rowData.id);
              console.log("selected", selected);
            }}
          >
            Assign to Case
          </Button>
        </div>
      </div>
    );
  };

  console.log("cases", cases);
  return (
    <CSSTransition in={fade} timeout={700} classNames="my-node">
      <>
        <div>
          {fade ? (
            <div
              className="body"
              onClick={() => {
                setModal(false);
              }}
            >
              <div
                className="main"
                style={{
                  padding: "2rem 0",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 0.55,
                    flexDirection: "row",
                  }}
                >
                  <p
                    className="heading"
                    style={{ fontSize: 24, fontWeight: "bold", margin: 0 }}
                  >
                    Recent Providers
                  </p>
                </div>
                <div>
                  <Input
                    disableUnderline={true}
                    className="search"
                    placeholder="Search"
                    onChange={(e) => {
                      setQuery(e.target.value);
                      // searchProviders(e.target.value);
                    }}
                    style={{ flex: 0.6, padding: "0 20px", color: "#000" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <i className="fas fa-search search-icon"></i>
                      </InputAdornment>
                    }
                  />
                  <Button
                    onClick={() => setAddProviderModal(true)}
                    className="btn light-blue"
                    style={{ marginLeft: 25, flex: 0.35 }}
                  >
                    + Add Provider
                  </Button>
                </div>
              </div>

              {providersLoaded ? (
                <div className="table-wrapper">
                  <div className="table">
                    <Table
                      data={query ? filteredProviders : providers}
                      label1="Name"
                      dataKey1={(row) => row.rowData.name}
                      label2="Phone Number"
                      dataKey2={(row) => row.rowData.phone}
                      label3="Address"
                      dataKey3={(row) => row.rowData.address}
                      buttonDataKey={(row) => editAndAssignProvider(row)}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="sweet-loading"
                  style={{
                    width: 100,
                    height: 75,
                    margin: "10% auto",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ClipLoader
                    style={{ position: "absolute" }}
                    size={75}
                    color={"rgb(47, 86, 138)"}
                  />
                </div>
              )}
            </div>
          ) : (
            <div />
          )}
        </div>

        {modal && (
          <Modal
            open
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            onClick={handleClose}
          >
            <div className="modal">
              <div
                className="modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <ClearIcon className="close" onClick={handleClose} />
                <h2 className="modal-header">Assign Provider to Case</h2>
                <div className="modal-form">
                  <Box
                    style={{
                      backgroundColor: "#F5F7F9",
                      padding: "8% 3%",
                      marginBottom: "11%",
                      lineHeight: 2,
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>{selected?.name}</div>
                    <div>{selected?.address}</div>
                  </Box>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={cases}
                      getOptionDisabled={(option) => {
                        let provider = getProviderId(option);
                        console.log(
                          "option and provider userId",
                          option?.userId,
                          provider?.userId
                        );
                        if (
                          option &&
                          provider &&
                          option.userId == provider.userId
                        )
                          return true;
                      }}
                      getOptionLabel={(option) => {
                        console.log("HERE", option)
                        return option?.author?.firstName + ' ' + option?.author?.lastName + ' - ' + option?.title
                      }}
                      renderOption={(option) => {
                        let provider = getProviderId(option);
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                              padding: "8px 0",
                            }}
                          >
                            {option?.author?.firstName} {option?.author?.lastName} - {option?.title}
                            {option?.userId &&
                              provider?.userId &&
                              option.userId == provider.userId && (
                                <IconButton
                                  color="primary"
                                  style={{ padding: 0 }}
                                >
                                  <img src={check} />
                                </IconButton>
                              )}
                          </div>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search Case"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                      style={{ background: "#fff" }}
                      onChange={(e, option) => {
                        console.log("option in providerModal", option);
                        setUserId(option?.userId);
                        setCaseTitle(option?.title);
                        setCaseId(option?.id);
                      }}
                    />
                    <Button
                      onClick={() => assignProvider()}
                      className="btn light-blue add-btn"
                      disabled={!caseId || loading}
                    >
                      {loading ? (
                        <div
                          className="sweet-loading"
                          style={{
                            position: "absolute",
                            width: 15,
                            height: 15,
                            top: 13,
                          }}
                        >
                          <ClipLoader
                            size={20}
                            color="#fff"
                            loading={loading}
                          />
                        </div>
                      ) : (
                        <span>Assign Provider</span>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}

        {editProviderModal && (
          <EditProvider
            setEditProviderModal={setEditProviderModal}
            provider={selected}
            providers={providers}
            setProviders={props.setProviders}
            notify={notify}
            fetchBusinesses={fetchBusinesses}
          />
        )}

        {addProviderModal &&
          (isSubscribed ? (
            <AddProvider
              open={addProviderModal}
              setOpen={setAddProviderModal}
              firms={firms}
              providers={providers}
              setProviders={props.setProviders}
              notify={notify}
            />
          ) : (
            <ZeroStateModal
              modalVisible={addProviderModal}
              headerText="To unlock SimpleFile you’ll need to add your payment
                  information to start your subscription."
              paragraphText="Manage your clients. No Commitment. Cancel anytime."
              firstButton={true}
              firstButtonText="Start subscription"
              handleClose={() => setAddProviderModal(false)}
            />
          ))}
      </>
    </CSSTransition>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    textAlign: "center",
  },
  content: {
    background: "#fff",
    width: 336,
    borderRadius: 30,
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    padding: "50px 100px",
    [theme.breakpoints.down(960)]: {
      width: 466,
      padding: "30px 50px 60px",
    },
    [theme.breakpoints.down(541)]: {
      width: "80%",
    },
  },
  header: {
    fontSize: 24,
    margin: "40px auto",
    [theme.breakpoints.down(541)]: {
      fontSize: 22,
      margin: "20px auto",
    },
  },
}));
