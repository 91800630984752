import React, { useState } from "react";
import validation from "../../lib/validations";
import axios from "axios";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

export default function Password(prop) {
  const [view, setView] = useState("ForgotPassword");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { notify } = prop;

  const handleSubmit = (event) => {
    axios
      .post("/auth/forgot/password", { email })
      .then((resp) => {
        setLoading(false);
        console.log("resp", resp);
        if (view === "ForgotPassword") {
          setView("ResetPassword");
        } else if (view === "ResetPassword") {
          window.location = "/";
        }
      })
      .catch((err) => {
        console.log("Error sending password reset email", err);
        console.log("err.response", err.response);
        setLoading(false);
        if (!err.response.data.success) {
          notify(
            err.response?.data?.message
              ? err.response.data.message
              : "Error logging in",
            false
          );
        }
      });
  };

  const enterPressed = (event) => {
    if (email) {
      var code = event.keyCode || event.which;
      if (code === 13) {
        handleSubmit();
      }
    }
  };

  const props = {
    view,
    email,
    password,
    setView,
    setEmail,
    setPassword,
    loading,
    setLoading,
    onSubmit: handleSubmit,
    enterPressed: enterPressed,
    notify,
  };

  switch (view) {
    case "ForgotPassword":
      return <ForgotPassword {...props} />;
    case "ResetPassword":
      return <ResetPassword {...props} />;
    default:
      return null;
  }
}
