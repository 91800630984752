import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Grid, Box, Button, useMediaQuery } from "@material-ui/core";
import DocumentImg from "../../assets/document.svg";
import Logo from "../../assets/logo.svg";
import HelloSignGif from "../../assets/hellosign.gif";
import SignatureImg from "../../assets/signature.png";
import CarouselItem from "./CarouselItem";
import Signature from "../Auth/Signature";

export default function Carousel({ open, setOpen, setOpenProviderModal }) {
  const classes = useStyles();

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    cssEase: "linear",
    pauseOnFocus: true,
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpenProviderModal(true);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        margin: "1rem",
      }}
      onClick={handleClose}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
        }}
      >
        <div className={classes.root} onClick={(e) => e.stopPropagation()}>
          <ClearIcon className="close" onClick={handleClose} />
          <div style={{ margin: "16px auto" }}>
            <img src={Logo} alt="Logo" style={{ width: 126, height: 25 }} />
          </div>
          <Slider {...settings} className={classes.slider}>
            <div>
              <CarouselItem
                step="Step 1"
                heading1="Upload your firm’s eSignature"
                heading2="documents. You can always"
                heading3="access and resuse them."
                text="Upload as many as you’d like."
                handleClick={handleClick}
                src={DocumentImg}
                alt="Document Image"
                button="Add your First Template"
              />
            </div>
            <div>
              <CarouselItem
                step="Step 2"
                heading1="Simply drag and drop"
                heading2="signature, date & initials"
                heading3="fields to your document."
                text="Upload as many as your document needs."
                handleClick={handleClick}
                src={HelloSignGif}
                alt="Hello Sign Gif"
                button="Add your First Template"
              />
            </div>
            <div>
              <CarouselItem
                step="Step 3"
                heading1="Then easily assign your"
                heading2="documents to any new or"
                heading3="preexisting case."
                text="Assign any of your docs from anywhere."
                handleClick={handleClick}
                src={SignatureImg}
                alt="Signature Image"
                button="Add your First Template"
              />
            </div>
          </Slider>
        </div>
      </div>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "40px 0",
    background: "#fff",
    maxWidth: 1068,
    minHeight: 448,
    borderRadius: 30,
    margin: "auto",
    position: "relative",
    [theme.breakpoints.down(1091)]: {
      width: "95%",
    },
    "& > *": {
      width: "80%",
      display: "flex",
      justifyContent: "center",
      margin: "40px auto",
      textAlign: "center",
      [theme.breakpoints.down(768)]: {
        margin: "32px auto",
      },
    },
  },
  slider: {
    margin: "16px auto",
  },
}));
