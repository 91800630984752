import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  TextField,
  Button,
  useMediaQuery,
  Tooltip,
} from "@material-ui/core";
import signUpImage from "../../assets/sign-up-image.jpg";
import "../../App.css";
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import validations from "../../lib/validations";
import { useHistory } from "react-router-dom";
import { ReactComponent as SmileIcon } from "../../assets/smile-icon.svg";
import { ReactComponent as HandshakeIcon } from "../../assets/handshake-icon.svg";
import { ReactComponent as CommunicationIcon } from "../../assets/communication-icon.svg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import informationIcon from "../../assets/informationIcon.svg";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import Password from "../Password";
import { UncheckedIcon } from "../../lib/checkboxIcons";
import rating from "../../assets/rating.svg";
import arrow from "../../assets/arrow.svg";
import LoadingSkeleton from "../LoadingSkeleton";

const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        padding: "16px 24px",
        fontSize: 14,
      },
    },
  },
});

export default function CreateAccount(props) {
  const classes = useStyles();
  const inputProps = {
    variant: "outlined",
    className: classes.input,
    inputProps: {
      maxLength: 50,
      style: { height: 50, paddingTop: 0, paddingBottom: 0 },
    },
  };
  const inputPropsCapitalized = {
    variant: "outlined",
    className: classes.input,
    inputProps: {
      maxLength: 50,
      style: {
        height: 50,
        paddingTop: 0,
        paddingBottom: 0,
        textTransform: "capitalize",
      },
    },
  };

  const history = useHistory();
  const [unmatchedPassword, setUnmatchedPassword] = useState(false);
  const [validateEmail, setValidateEmail] = useState(false);
  const [validatePhone, setValidatePhone] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const mobile = useMediaQuery("(max-width:850px)");
  const lgDevice = useMediaQuery("(min-width:1200px)");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);
  const [hasNumericValue, setHasNumericValue] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasPasswordLength, setHasPasswordLength] = useState(false);
  const [isPasswordSecure, setIsPasswordSecure] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageDidLoad, setImageDidLoad] = useState(false);

  const { validateName } = validations;

  const {
    swapView,
    firstName,
    email,
    phone,
    lastName,
    firmName,
    password,
    passwordConfirmation,
    setFirstName,
    setLastName,
    setFirmName,
    setEmail,
    setPhone,
    setPassword,
    setPasswordConfirmation,
    notify,
  } = props;

  useEffect(() => {
    validatePassword(password);
  }, []);

  const handleMatchingPassword = (value) => {
    if (password === value || passwordConfirmation === value) {
      setUnmatchedPassword(false);
    } else {
      setUnmatchedPassword(true);
    }
  };

  const onLoad = () => {
    setImageDidLoad(true);
  };

  const imageStyle = imageDidLoad
    ? { objectFit: "cover" }
    : { visibility: "hidden" };

  const validatePassword = (value) => {
    validations.hasSpecialCharacter(value)
      ? setHasSpecialCharacter(true)
      : setHasSpecialCharacter(false);
    validations.hasNumericValue(value)
      ? setHasNumericValue(true)
      : setHasNumericValue(false);
    validations.hasUppercase(value)
      ? setHasUpperCase(true)
      : setHasUpperCase(false);
    value.length > 7 ? setHasPasswordLength(true) : setHasPasswordLength(false);
  };

  const handleChange = (value) => {
    setTooltipOpen(true);
    setPassword(value);
    validatePassword(value);
    handleMatchingPassword(value);
  };

  const handleClick = () => {
    setLoading(true);
    if (
      !hasUpperCase ||
      !hasNumericValue ||
      !hasPasswordLength ||
      !hasSpecialCharacter
    ) {
      setIsPasswordSecure(false);
      setTooltipOpen(true);
      setLoading(false);
    } else if (password != passwordConfirmation) {
      setUnmatchedPassword(true);
      setLoading(false);
    } else {
      axios
        .post("/auth/email", {
          email,
          firstName,
          lastName,
          firmName,
          phone,
        })
        .then((resp) => {
          console.log("Email checked!", resp);
          if (resp.data.success) {
            swapView();
            setLoading(false);
          } else {
            setLoading(false);
            notify(
              resp?.data?.message
                ? resp.data.message
                : "Email already exists. Please try again.",
              false
            );
          }
        })
        .catch((err) => {
          console.log(
            "Issue checking email",
            err?.response ? err.response : err
          );
        });
    }
  };

  function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const enterPressed = (event) => {
    if (
      firstName &&
      lastName &&
      email &&
      !validateEmail &&
      firmName &&
      password &&
      passwordConfirmation &&
      !unmatchedPassword
    ) {
      var code = event.keyCode || event.which;
      if (code === 13) {
        handleClick();
      }
    }
  };

  return (
    <Grid
      container
      className={classes.root}
      style={{ overflowY: !mobile ? "hidden" : "auto" }}
    >
      {!mobile && (
        <Grid item xs={!mobile && 6} style={{ flex: 1, position: "relative" }}>
          {!imageDidLoad && (
            <LoadingSkeleton
              style={{ width: "100%", height: "calc(100vh - 60px)" }}
            />
          )}
          <div
            style={{
              height: "calc(100vh - 60px)",
              backgroundPositionY: "100%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              position: "relative",
            }}
          >
            <img
              src={signUpImage}
              width="100%"
              height="100%"
              alt={"Man on laptop"}
              style={imageStyle}
              onLoad={onLoad}
            />
            <div className="blur-modal">
              <div>
                <p
                  style={{
                    textAlign: "left",
                    color: "#fff",
                    fontSize: 27,
                    fontWeight: "500",
                    lineHeight: "28px",
                    fontStyle: "italic",
                  }}
                >
                  "SimpleFile has been a total game changer! My firm was
                  crushing it before and now we've taken things to a whole new
                  level. Our close ratio is crazy high and clients love us even
                  more."
                </p>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <p
                      style={{
                        textAlign: "left",
                        color: "#fff",
                        fontWeight: "600",
                        fontSize: 24,
                        margin: 0,
                        paddingRight: 10,
                      }}
                    >
                      - Jared Bossola, Esq.
                    </p>
                    <p
                      style={{
                        textAlign: "left",
                        color: "#fff",
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SimpleFile customer
                    </p>
                  </div>
                  <div style={{ marginTop: 8 }}>
                    <img src={rating} alt="Information Icon" />
                    <img src={rating} alt="Information Icon" />
                    <img src={rating} alt="Information Icon" />
                    <img src={rating} alt="Information Icon" />
                    <img src={rating} alt="Information Icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      )}
      <Grid item xs={mobile ? 12 : 6}>
        <Box className={classes.signUpPage}>
          <Box
            style={{
              fontFamily: "SFProDisplay-Regular",
            }}
          >
            <h1
              style={{
                textAlign: "center",
                color: "#000",
                fontSize: 34,
                maxWidth: 430,
                lineHeight: "48px",
                margin: "0 auto",
                fontFamily: "SFProDisplay-Black",
              }}
            >
              SET UP YOUR FREE ON-BOARDING TODAY!
            </h1>
            <p
              style={{
                fontSize: 16,
                fontFamily: "SFProDisplay-Bold",
                color: "#5C5C5C",
                marginTop: 10,
              }}
            >
              45-DAY MONEY BACK GUARANTEE
            </p>
            <div className="icon-wrapper">
              <SmileIcon fill="red" />
              <span style={{ width: "80%" }}>
                Increase your close rate with new clients from a one-of-a-kind
                app experience that they’ll love.
              </span>
            </div>
            <div className="icon-wrapper">
              <CommunicationIcon fill="red" />
              <span style={{ width: "80%" }}>
                Create simple, seamless communication with all of your clients,
                all from one place.
              </span>
            </div>
            <div className="icon-wrapper">
              <HandshakeIcon fill="red" />
              <span style={{ width: "80%" }}>
                Equip your team with a more effective and efficient way to
                manage client relationships.
              </span>
            </div>
          </Box>
          <div
            style={{
              margin: "0px 0 31px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex" }}>
              <TextField
                label="First Name"
                value={firstName}
                style={{ flex: 1, marginRight: 11 }}
                onChange={(e) =>
                  (validateName(e.target.value) || !e.target.value) &&
                  setFirstName(e.target.value)
                }
                onKeyPress={enterPressed}
                InputLabelProps={{ className: classes.label }}
                {...inputPropsCapitalized}
              />
              <TextField
                label="Last Name"
                value={lastName}
                style={{ flex: 1 }}
                onChange={(e) =>
                  (validateName(e.target.value) || !e.target.value) &&
                  setLastName(e.target.value)
                }
                onKeyPress={enterPressed}
                InputLabelProps={{ className: classes.label }}
                {...inputPropsCapitalized}
              />
            </div>
            <div style={{ marginBottom: 16 }}>
              <TextField
                fullWidth
                label="Firm Name"
                value={firmName}
                onChange={(e) => setFirmName(e.target.value)}
                onKeyPress={enterPressed}
                InputLabelProps={{ className: classes.label }}
                {...inputPropsCapitalized}
              />
            </div>
            <div>
              <TextField
                fullWidth
                label="Email Address"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  !validations.emailValidation(email) &&
                    setValidateEmail(false);
                }}
                onKeyPress={enterPressed}
                onBlur={(e) => {
                  validations.emailValidation(email)
                    ? setValidateEmail(true)
                    : setValidateEmail(false);
                }}
                InputLabelProps={{ className: classes.label }}
                {...inputProps}
              />
              {email && validateEmail && (
                <p
                  style={{
                    margin: "0px 0px 8px",
                    fontSize: 12,
                    color: "#ff7070",
                    textAlign: "left",
                  }}
                >
                  Invalid email address format
                </p>
              )}
            </div>
            {/* <TextField
              fullWidth
              label="Phone Number"
              value={phone}
              onChange={(e) => {
                const formattedPhoneNumber = formatPhoneNumber(e.target.value);
                setPhone(formattedPhoneNumber);

                phone.length !== 14 && setValidatePhone(false);
              }}
              onBlur={(e) => {
                phone.length !== 14
                  ? setValidatePhone(true)
                  : setValidatePhone(false);
              }}
              InputLabelProps={{ className: classes.label }}
              {...inputProps}
            />
            {phone && validatePhone && (
              <p
                style={{
                  margin: "0px 0px 8px",
                  fontSize: 12,
                  color: "#ff7070",
                  textAlign: "left",
                }}
              >
                Invalid phone number format
              </p>
            )} */}
            <div className={classes.relative}>
              <TextField
                fullWidth
                label="Create a password"
                autocomplete="new-password"
                autoComplete="new-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => handleChange(e.target.value)}
                onBlur={() => {
                  setTooltipOpen(false);
                }}
                onKeyPress={enterPressed}
                InputLabelProps={{ className: classes.label }}
                {...inputProps}
              />
              {isPasswordSecure === false && (
                <p
                  style={{
                    margin: "0px 0px 8px",
                    fontSize: 12,
                    color: "#ff7070",
                    textAlign: "left",
                  }}
                >
                  Invalid password
                </p>
              )}

              <div className={classes.absolute}>
                <MuiThemeProvider theme={defaultTheme}>
                  <MuiThemeProvider theme={theme}>
                    <Tooltip
                      open={tooltipOpen}
                      onOpen={() => setTooltipOpen(true)}
                      onClose={() => setTooltipOpen(false)}
                      onClick={() => setTooltipOpen(true)}
                      placement="top"
                      title={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                          }}
                        >
                          <p style={{ fontWeight: "bold" }}>
                            Password Must Contain
                          </p>
                          <Box my={1} className="passwordValidationText">
                            {password ? (
                              <div className={classes.icon}>
                                <i
                                  className={
                                    hasPasswordLength
                                      ? "checkmark fas fa-check"
                                      : "fas fa-times"
                                  }
                                />
                              </div>
                            ) : (
                              <div className={classes.icon}>
                                <i className="fas fa-times"></i>
                              </div>
                            )}
                            <p className="passwordValidationText">
                              8 Characters Minimum
                            </p>
                          </Box>
                          <Box my={1} className="passwordValidationText">
                            {password ? (
                              <div className={classes.icon}>
                                <i
                                  className={
                                    hasUpperCase
                                      ? "checkmark fas fa-check"
                                      : "fas fa-times"
                                  }
                                />
                              </div>
                            ) : (
                              <div className={classes.icon}>
                                <i className="fas fa-times"></i>
                              </div>
                            )}
                            <p>At least 1 capital letter</p>
                          </Box>
                          <Box my={1} className="passwordValidationText">
                            {password ? (
                              <div className={classes.icon}>
                                <i
                                  className={
                                    hasNumericValue
                                      ? "checkmark fas fa-check"
                                      : "fas fa-times"
                                  }
                                />
                              </div>
                            ) : (
                              <div className={classes.icon}>
                                <i className="fas fa-times"></i>
                              </div>
                            )}
                            <p>At least 1 numeric value</p>
                          </Box>
                          <Box my={1} className="passwordValidationText">
                            {password ? (
                              <div className={classes.icon}>
                                <i
                                  className={
                                    hasSpecialCharacter
                                      ? "checkmark fas fa-check"
                                      : "fas fa-times"
                                  }
                                />
                              </div>
                            ) : (
                              <div className={classes.icon}>
                                <i className="fas fa-times"></i>
                              </div>
                            )}
                            <p>At least 1 special character</p>
                          </Box>
                        </div>
                      }
                    >
                      <div
                        style={{
                          marginRight: 5,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img src={informationIcon} alt="Information Icon" />
                      </div>
                    </Tooltip>
                  </MuiThemeProvider>
                </MuiThemeProvider>
                <div
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {showPassword ? (
                    <AiOutlineEyeInvisible size={20} />
                  ) : (
                    <AiOutlineEye size={20} />
                  )}
                </div>
              </div>
            </div>
            <div className={classes.relative}>
              <TextField
                fullWidth
                label="Re-enter password"
                type={showPasswordConfirmation ? "text" : "password"}
                value={passwordConfirmation}
                onChange={(e) => {
                  setPasswordConfirmation(e.target.value);
                  handleMatchingPassword(e.target.value);
                }}
                onKeyPress={enterPressed}
                InputLabelProps={{ className: classes.label }}
                {...inputProps}
              />
              <div
                onClick={() =>
                  setShowPasswordConfirmation(!showPasswordConfirmation)
                }
              >
                {showPasswordConfirmation ? (
                  <AiOutlineEyeInvisible
                    size={20}
                    className={classes.absolute}
                  />
                ) : (
                  <AiOutlineEye size={20} className={classes.absolute} />
                )}
              </div>
            </div>
          </div>
          <div>
            {passwordConfirmation && unmatchedPassword && (
              <Box my={1} style={{ position: "relative" }}>
                <p
                  style={{
                    fontSize: 12,
                    position: "absolute",
                    bottom: 15,
                    margin: 0,
                    color: "#ff7070",
                  }}
                >
                  The passwords entered do not match
                </p>
              </Box>
            )}
          </div>

          <Button
            className={classes.button}
            disabled={
              !firstName ||
              !lastName ||
              !email ||
              validateEmail ||
              !firmName ||
              !password ||
              !passwordConfirmation ||
              unmatchedPassword ||
              loading
            }
            onClick={() => handleClick()}
          >
            {loading ? (
              <ClipLoader size={20} color="#fff" loading={loading} />
            ) : (
              <div style={{ display: "flex" }}>
                <span>Next&nbsp;&nbsp;&nbsp;</span>
                <img src={arrow} />
              </div>
            )}
          </Button>
          <div style={{ opacity: "65%", fontSize: 13 }}>
            Cancel anytime. No commitments.
          </div>
        </Box>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#5C5C5C",
    height: "calc(100vh - 60px)",
  },
  button: {
    margin: "0 0 15px !important",
    textTransform: "capitalize",
    boxShadow: "-4px 4px 25px rgba(0, 0, 0, 0.25)",
    color: "#fff !important",
    fontWeight: "bold !important",
    width: "100%",
    height: 50,
    borderRadius: 25,
    backgroundColor: "#2a528c !important",
  },
  signUpPage: {
    [theme.breakpoints.up("851")]: {
      height: "calc(100vh - 60px - 100px)",
      overflowY: "auto",
      maxWidth: "573px",
      margin: "53px auto",
      padding: "0 2.5rem",
    },
    [theme.breakpoints.down("851")]: {
      width: "90%",
      margin: "53px auto",
    },
  },
  header: {
    fontWeight: 400,
    fontSize: 25,
    lineHeight: "40px",
    margin: "24px auto",
    color: "#000",
  },
  input: {
    margin: "5px 0",
  },
  label: {
    color: "rgba(181, 181, 181, 0.7)",
    top: -3,
  },
  relative: {
    position: "relative",
    width: "100%",
  },
  absolute: {
    position: "absolute",
    top: 22,
    right: 16,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
}));
