import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  InputAdornment,
  Input,
  Grid,
  Box,
} from "@material-ui/core";
import "../../App.css";
import AddCase from "./Modals/AddCase";
import { CSSTransition } from "react-transition-group";
import { Redirect } from "react-router";
import Table from "./CaseTable";
import { CellMeasurer } from "react-virtualized";
import ZeroStateModal from "../Modal/ZeroStateModal";
import data from "../../lib/data";
import { ClipLoader } from "react-spinners";
import { debounce } from 'lodash';


export default function Cases(props) {
  const [clients, setClients] = useState([]);
  const [hover, setHover] = useState([]);
  const [order, setOrder] = useState("date");
  const [fade, setFade] = useState(false);
  const [targetCase, setTargetCase] = useState({ case: null });
  const [viewCase, setViewCase] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [viewSampleCase, setViewSampleCase] = useState(false);
  const {
    cases,
    setCases,
    casesLoaded,
    modal,
    setModal,
    notify,
    firms,
    self,
    users,
    searchUsers,
    isSubscribed,
  } = props;

  const [sampleCasesModal, setSampleCasesModal] = useState(true);
  const [filteredCases, setFilteredCases] = useState([]);
  const [query, setQuery] = useState(null);

  // Receives client data when ready
  useEffect(() => {
    setFade(true);
    fetchClients();
  }, []);

  // useEffect(() => {
  //   if (cases.length > 0 && query) {
  //     fetchFilteredCases(query);
  //   }
  // }, [query]);

  // Call debounced function when query changes


  useEffect(() => {
    setCases(cases);
    //resets filteredCases when a new case is added
    setFilteredCases([]);
  }, [cases]);

  // const fetchFilteredCases = (searchResult) => {
  //   const query = searchResult.toLowerCase();
  //   const { firmId } = cases[0];
  //   data.getCases(null, null, firmId).then((cases) => {
  //     let arr = [];
  //     for (let i = 0; i < cases.length; i++) {
  //       let { author, title, status, caseType } = cases[i];
  //       let name = `${author.firstName} ${author.lastName}`;
  //       name = name.toLowerCase();
  //       if (name.includes(query)) {
  //         arr.push(cases[i]);
  //       } else if (title.toLowerCase().includes(query)) {
  //         arr.push(cases[i]);
  //       } else if (caseType.toLowerCase().includes(query)) {
  //         arr.push(cases[i]);
  //       } else if (status.toLowerCase().includes(query)) {
  //         arr.push(cases[i]);
  //       }
  //     }
  //     setFilteredCases([...arr]);
  //   });
  // };

  // Debounced fetchFilteredCases function
  const debouncedFetchFilteredCases = React.useCallback(
    debounce(async (searchResult) => {
      const queryLower = searchResult.toLowerCase();
      const { firmId } = cases[0];
      const casesData = await data.getCases(searchResult, null, firmId);

      let arr = [];

      // for (let i = 0; i < casesData.length; i++) {
      //   let { author, title, status, caseType } = casesData[i];
      //   let name = `${author.firstName} ${author.lastName}`;
      //   name = name.toLowerCase();

      //   if (name.includes(queryLower) || title.toLowerCase().includes(queryLower) || caseType.toLowerCase().includes(queryLower) || status.toLowerCase().includes(queryLower)) {
      //     arr.push(casesData[i]);
      //   }
      // }

      setFilteredCases([...casesData]);
    }, 300),
    [cases]
  );

  useEffect(() => {
    if (cases.length > 0 && query) {
      debouncedFetchFilteredCases(query);
    }
  }, [query, debouncedFetchFilteredCases]);

  const fetchClients = () => {
    if (props.clients && props.clients.length !== clients.length) {
      setModal(false);
      setClients(props.clients);
    }
    if (
      props.clients &&
      JSON.stringify(clients) !== JSON.stringify(props.clients)
    )
      setClients(props.clients);
  };

  const handleSelect = (e) => {
    setOrder(e.target.value);
    if (e.target.value === "name") searchUsers("", "name");
    else searchUsers();
  };

  const handleClick = (el) => {
    console.log("INSIDE", el)
    if (cases.length === 0) {
      setViewSampleCase(true);
      setTargetCase({ case: el });
      props.history.push("/case", { caseObj: el, isSampleCase: true });
    } else {
      setViewCase(true);
      setTargetCase({ case: el });
      props.history.push("/case", { caseObj: el });
    }
  };

  const sampleCases = [
    {
      id: "sample-case-01",
      title: "Sample Case 01",
      date: "2/14/21",
      author: {
        firstName: "William",
        lastName: "Johnson",
      },
      caseType: "Slip & Fall",
      status: "In Negotiation",
    },
    {
      id: "sample-case-02",
      title: "Sample Case 02",
      date: "11/25/20",
      author: {
        firstName: "Mary",
        lastName: "Smith",
      },
      caseType: "Horse Accident",
      status: "In Negotiation",
    },
  ];

  const getFullName = (data) => {
    const { rowData } = data;
    let clientName = `${rowData?.author?.firstName} ${rowData?.author?.lastName}`;
    return clientName;
  };

  const viewCaseButton = (data) => {
    const { rowData } = data;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: 10,
        }}
      >
        <Button
          className="view-case"
          onClick={(e) => {
            e.stopPropagation();
            handleClick(rowData);
          }}
        >
          View Case
        </Button>
      </div>
    );
  };

  return (
    <CSSTransition in={fade} timeout={700} classNames="my-node">
      {fade ? (
        <div className="body">
          <div
            className="main"
            style={{
              padding: "2rem 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 0.55,
                flexDirection: "row",
              }}
            >
              <p
                className="heading"
                style={{ fontSize: 24, fontWeight: "bold", margin: 0 }}
              >
                Cases
              </p>
            </div>
            <div>
              <Input
                disableUnderline={true}
                className="search"
                placeholder="Search"
                disabled={cases.length == 0}
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                  debouncedFetchFilteredCases(e.target.value);
                }}
                style={{ flex: 0.6, padding: "0 20px" }}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="fas fa-search search-icon"></i>
                  </InputAdornment>
                }
              />
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  if (isSubscribed) {
                    window.location = "/add-case";
                  } else {
                    setModal(true);
                  }
                }}
                className="btn light-blue"
                style={{ marginLeft: 25, flex: 0.35 }}
              >
                + Add Case
              </Button>
            </div>
          </div>
          {/* RENDER CASES */}
          {casesLoaded ? (
            <div className="table-wrapper">
              <div className="table">
                <Table
                  data={
                    cases.length != 0
                      ? query
                        ? filteredCases
                        : cases
                      : sampleCases
                  }
                  label1="Case"
                  dataKey1={(row) => row.rowData.title}
                  label2="Client"
                  dataKey2={(row) =>
                    cases.length != 0
                      ? query
                        ? getFullName(row, filteredCases)
                        : getFullName(row, cases)
                      : getFullName(row, sampleCases)
                  }
                  label3="Case Type"
                  dataKey3={(row) => row.rowData.caseType}
                  label4="Status"
                  dataKey4={(row) => row.rowData.status}
                  buttonLabel=""
                  buttonDataKey={(row) => viewCaseButton(row)}
                  handleClick={rowData => handleClick(rowData)}
                />
              </div>
            </div>
          ) : (
            <div
              className="sweet-loading"
              style={{
                width: 100,
                height: 75,
                margin: "10% auto",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ClipLoader
                style={{ position: "absolute" }}
                size={75}
                color={"rgb(47, 86, 138)"}
              />
            </div>
          )}
          {/* )} */}
          {/* ZERO STATE */}
          {casesLoaded && cases.length === 0 && (
            <>
              <ZeroStateModal
                modalVisible={sampleCasesModal}
                headerText="You’re ready to add your first case! View some sample cases or get right to it and add your first case."
                paragraphText="Add as many cases as you’d like."
                firstButton={true}
                firstButtonText="Add your First Case"
                secondButton={true}
                secondButtonText="View Sample Cases"
                handleClose={() => setSampleCasesModal(false)}
                handleClick={() => {
                  setSampleCasesModal(false);
                  if (isSubscribed) {
                    window.location = "/add-case";
                  } else {
                    setModal(true);
                  }
                }}
              />
            </>
          )}
          {isSubscribed ? (
            <div />
          ) : (
            // <AddCase
            //   firms={firms}
            //   modal={modal}
            //   setModal={setModal}
            //   clients={props.clients}
            //   users={users}
            //   notify={notify}
            //   cases={cases}
            //   self={self}
            //   setCases={setCases}
            // />
            <ZeroStateModal
              modalVisible={modal}
              headerText="To unlock SimpleFile you’ll need to add your payment
                  information to start your subscription."
              paragraphText="Manage your clients. No Commitment. Cancel anytime."
              firstButton={true}
              firstButtonText="Start Subscription"
              handleClose={() => setModal(false)}
            />
          )}
        </div>
      ) : (
        <div />
      )}
    </CSSTransition>
  );
}
