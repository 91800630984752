import React, { useEffect, useState } from "react";
import {
  Button,
  InputAdornment,
  Input,
  Grid,
  Box,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@material-ui/core";
import TemplateModal from "./TemplateModal";
import TemplateTitleModal from "./TemplateTitleModal";
import moment from "moment";
import pdf from "../../assets/pdf-icon.svg";
import png from "../../assets/png-icon.svg";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import preview from "../../assets/preview.svg";
import download from "../../assets/download.svg";
import edit from "../../assets/edit.svg";
import remove from "../../assets/remove.svg";
import { makeStyles } from "@material-ui/core/styles";
import { CSSTransition } from "react-transition-group";
import Carousel from "./Carousel";
import axios from "axios";
import HelloSign from "hellosign-embedded";
import Table from "./TemplateTable";
import ZeroStateModal from "../Modal/ZeroStateModal";
import data from "../../lib/data";
import { ClipLoader } from "react-spinners";

var FileSaver = require("file-saver");

const useStyles = makeStyles((theme) => ({
  menuItem: {
    padding: "7px 40px 7px 17px",
  },
  menuIcon: {
    width: 12,
    height: 12,
    marginRight: 12,
  },
  icon: {
    width: 31,
    height: 31,
    marginRight: 17,
  },
}));

const sampleTemplate = [
  {
    title: "Sample Document",
    creator: {
      firstName: "David",
      lastName: "Smith",
    },
    createdAt: "Sep 23, 2021",
  },
];

export default function Templates(props) {
  const classes = useStyles();
  const mobileScreen = useMediaQuery("(max-width:1024px)");
  const [open, setOpen] = useState(false);
  const [openCarousel, setOpenCarousel] = useState(true);
  const [titleOpen, setTitleOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fade, setFade] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState(null);
  // const [templates, setTemplates] = useState([]);
  const [isSampleTemplate, setIsSampleTemplate] = useState(false);
  const [query, setQuery] = useState(null);
  const [filteredTemplates, setFilteredTemplates] = useState([]);

  const {
    notify,
    templatesLoaded,
    setTemplatesLoaded,
    self,
    templates,
    setTemplates,
    isSubscribed,
  } = props;
  const client = new HelloSign();

  useEffect(() => {
    setFade(true);
    setTemplates(props.templates);
  }, []);

  useEffect(() => {
    if (templates.length === 0) {
      setIsSampleTemplate(true);
    } else {
      setIsSampleTemplate(false);
    }
  }, [templates]);

  useEffect(() => {
    if (templates.length > 0 && query) {
      fetchFilteredTemplates(query);
    }
  }, [query]);

  useEffect(() => {
    //resets filteredTemplates when a new template is added
    setFilteredTemplates([]);
  }, [props.templates]);

  const handleMouseOver = (event, el) => {
    setActiveTemplate(el);
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchFilteredTemplates = (searchResult) => {
    const query = searchResult.toLowerCase();
    const { firmId } = templates[0];
    data.getTemplates(firmId).then((templates) => {
      let arr = [];
      for (let i = 0; i < templates.length; i++) {
        let { title, creator, createdAt } = templates[i];
        let name = `${creator.firstName} ${creator.lastName}`;
        name = name.toLowerCase();
        let formattedDate = moment(createdAt).format("MMM DD, YYYY");
        formattedDate = formattedDate.toLocaleLowerCase();
        if (name.includes(query)) {
          arr.push(templates[i]);
        } else if (title.toLowerCase().includes(query)) {
          arr.push(templates[i]);
        } else if (formattedDate.includes(query)) {
          arr.push(templates[i]);
        }
      }
      setFilteredTemplates([...arr]);
    });
  };

  const handleDelete = (id) => {
    axios
      .delete(
        `/template/delete?firmId=${props.firms[0].Firm.id}&templateId=${id}`
      )
      .then((resp) => {
        notify("Template successfully removed!", true);
        console.log("Template deleted!", resp);
        let arr = [...templates];
        let index = arr.findIndex((el) => el.id == id);
        console.log("INDEX", index, arr);
        arr.splice(index, 1);
        props.setTemplates([...arr]);
        setAnchorEl(null);
      })
      .catch((err) => {
        notify("Issue deleting template. Please try again later", false);
        console.log("Error deleting template", err, err.response, err.message);
      });
  };

  const handleEdit = (templateId) => {
    axios
      .post("/hellosign/edit/template", { templateId })
      .then((resp) => {
        console.log("Template link received", resp);
        client.open(resp.data.results.edit_url, {
          clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
          testMode: true,
          skipDomainVerification: false,
        });

        client.on("sign", (data) => {
          console.log("The document has been updated!");
          console.log("Signature ID: " + data.signatureId);
        });

        client.on("createTemplate", (data) => {
          console.log("The document has been updated!", data);
          notify("Template updated!", true);
        });

        client.on("error", (err) => {
          console.log("HelloSign error", err);
          notify("Issue previewing template. Please try again later.", false);
        });
      })
      .catch((err) => {
        notify("Issue previewing template. Please try again later", false);
        console.log(
          "Error fetching template link",
          err?.response ? err.response : err
        );
      });
  };

  const handleDownload = (templateId) => {
    axios
      .post("/hellosign/download/template", { templateId })
      .then((resp) => {
        console.log("Template downloaded", resp);
        // window.open(resp.data.file, 'hello')

        FileSaver.saveAs(resp.data.file, `${resp.data.templateTitle}.pdf`);
      })
      .catch((err) => {
        notify("Issue downloading template. Please try again later", false);
        console.log(
          "Error downloading template",
          err?.response ? err.response : err
        );
      });
  };

  const viewOptions = (data) => {
    const { rowData } = data;
    return (
      <div
        onClick={(e) => mobileScreen && handleMouseOver(e, rowData)}
        onMouseOver={(e) => !mobileScreen && handleMouseOver(e, rowData)}
        className="ellipses-icon"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FiberManualRecordIcon />
        <FiberManualRecordIcon />
        <FiberManualRecordIcon />
      </div>
    );
  };

  return (
    <CSSTransition in={fade} timeout={700} classNames="my-node">
      {fade ? (
        <div className="body">
          <div
            className="main"
            style={{
              padding: "2rem 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flex: 0.55, flexDirection: "row" }}>
              <p
                className="heading"
                style={{ fontSize: 24, fontWeight: "bold", margin: 0 }}
              >
                Templates
              </p>
            </div>
            <div>
              <Input
                disableUnderline={true}
                className="search"
                placeholder="Search"
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                disabled={templates.length == 0}
                style={{ flex: 0.6, padding: "0 20px", color: "#000" }}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="fas fa-search search-icon"></i>
                  </InputAdornment>
                }
              />
              <Button
                onClick={() => setOpen(true)}
                className="btn light-blue"
                style={{ marginLeft: 25, flex: 0.35 }}
              >
                + Upload Template
              </Button>
            </div>
          </div>

          {/* RENDER TEMPLATES */}
          {templatesLoaded ? (
            <div className="table-wrapper">
              <div className="table">
                <Table
                  data={
                    templates.length != 0
                      ? query
                        ? filteredTemplates
                        : templates
                      : sampleTemplate
                  }
                  label1="Name"
                  dataKey1={(row) => row.rowData.title}
                  label2="Owner"
                  dataKey2={(row) =>
                    `${row.rowData.creator.firstName} ${row.rowData.creator.lastName}`
                  }
                  label3="Last Modified"
                  dataKey3={(row) =>
                    moment(row.rowData.createdAt).format("MMM DD, YYYY")
                  }
                  buttonLabel=""
                  buttonDataKey={(row) => viewOptions(row)}
                />
              </div>
            </div>
          ) : (
            <div
              className="sweet-loading"
              style={{
                width: 100,
                height: 75,
                margin: "10% auto",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ClipLoader
                style={{ position: "absolute" }}
                size={75}
                color={"rgb(47, 86, 138)"}
              />
            </div>
          )}

          {/* SHOW MENU ON HOVER */}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            MenuListProps={{ onMouseLeave: handleClose }}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem
              onClick={() => handleEdit(activeTemplate.id)}
              className={classes.menuItem}
              disabled={isSampleTemplate}
            >
              <img
                className={classes.menuIcon}
                src={preview}
                alt="Preview Icon"
              />
              Preview/Edit
            </MenuItem>
            <MenuItem
              onClick={() => setTitleOpen(true)}
              className={classes.menuItem}
              disabled={isSampleTemplate}
            >
              <img
                className={classes.menuIcon}
                src={edit}
                alt="Edit Icon"
                style={{ width: 12, height: 12, marginRight: 12 }}
              />
              Rename
            </MenuItem>
            <MenuItem
              onClick={() => handleDownload(activeTemplate.id)}
              className={classes.menuItem}
              disabled={isSampleTemplate}
            >
              <img
                className={classes.menuIcon}
                src={download}
                alt="Download Icon"
                style={{ width: 12, height: 12, marginRight: 12 }}
              />
              Download
            </MenuItem>
            <MenuItem
              onClick={() => handleDelete(activeTemplate.id)}
              style={{
                borderTop: "1px solid #D3D3D3",
                marginTop: 20,
              }}
              className={classes.menuItem}
              disabled={isSampleTemplate}
            >
              <img
                className={classes.menuIcon}
                src={remove}
                alt="Remove Icon"
                style={{
                  width: 12,
                  height: 12,
                  marginRight: 12,
                }}
              />
              Remove
            </MenuItem>
          </Menu>

          {open &&
            (isSubscribed ? (
              <TemplateModal
                open={open}
                setOpen={setOpen}
                firms={props.firms}
                notify={props.notify}
                templates={templates}
                setTemplates={setTemplates}
                user={props.user}
              />
            ) : (
              <ZeroStateModal
                modalVisible={open}
                headerText="To unlock SimpleFile you’ll need to add your payment
                  information to start your subscription."
                paragraphText="Manage your clients. No Commitment. Cancel anytime."
                firstButton={true}
                firstButtonText="Start subscription"
                handleClose={() => setOpen(false)}
              />
            ))}

          {activeTemplate && (
            <TemplateTitleModal
              open={titleOpen}
              setOpen={setTitleOpen}
              notify={props.notify}
              firmId={props.firms[0].Firm.id}
              templateId={activeTemplate.id}
              template={activeTemplate}
              firms={props.firms}
              templates={templates}
              setTemplates={props.setTemplates}
              notify={props.notify}
            />
          )}

          {/* ZERO STATE */}
          {templatesLoaded && templates.length === 0 && (
            <>
              <Carousel
                open={openCarousel}
                setOpen={setOpenCarousel}
                setOpenProviderModal={setOpen}
              />
            </>
          )}
        </div>
      ) : (
        <div />
      )}
    </CSSTransition>
  );
}
