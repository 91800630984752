import React from "react";
import { Grid, Box, Button, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "../../App.css";
import { BiParagraph } from "react-icons/bi";

export default function CarouselItem(props) {
  const classes = useStyles();
  const mobile = useMediaQuery("(max-width:799px)");

  return (
    <Grid container className={classes.grid}>
      <Grid item xs={mobile ? 12 : 6} className={classes.leftColumnGrid}>
        <p
          className={classes.paragraph}
          style={{
            color: "#5C5C5C",
            textTransform: "uppercase",
            margin: 0,
            fontWeight: 400,
          }}
        >
          {props.step}
        </p>
        <div className={classes.heading}>
          <h3>{props.heading1}</h3>
          <h3>{props.heading2}</h3>
          <h3>{props.heading3}</h3>
        </div>

        <p
          className={classes.paragraph}
          style={{ color: "#5C5C5C", margin: 0 }}
        >
          {props.text}
        </p>
        <Button
          className="modal-btn carousel-modal-btn"
          onClick={props.handleClick}
        >
          {props.button}
        </Button>
      </Grid>
      <Grid item xs={6} className={classes.rightColumnGrid}>
        <img src={props.src} className={classes.image} alt={props.alt} />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  leftColumnGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "left",
    alignItems: "flex-start",
    [theme.breakpoints.down(800)]: {
      alignItems: "center",
      textAlign: "center",
      margin: "2rem auto",
    },
  },
  rightColumnGrid: {
    display: "flex !important",
    alignItems: "center !important",
    height: "500px !important",
    [theme.breakpoints.down(800)]: {
      display: "none !important",
    },
  },
  image: {
    maxWidth: "100%",
  },
  heading: {
    margin: "16px 0",
    "& > *": {
      fontSize: "24px !important",
      margin: 0,
      padding: "8px 0",
      [theme.breakpoints.down(1465)]: {
        fontSize: "22px !important",
        padding: "7px 0",
      },
      [theme.breakpoints.down(1350)]: {
        fontSize: "20px !important",
        padding: "6px 0",
      },
      [theme.breakpoints.down(1262)]: {
        fontSize: "19px !important",
        padding: "5px 0",
      },
      [theme.breakpoints.down(1169)]: {
        fontSize: "18px !important",
        padding: "4px 0",
      },
      [theme.breakpoints.down(9)]: {
        fontSize: "17px !important",
        padding: "3px 0",
      },
      [theme.breakpoints.down(875)]: {
        fontSize: "16px !important",
        padding: "2px 0",
      },
      [theme.breakpoints.down(800)]: {
        fontSize: "19px !important",
        padding: "2px 0",
      },
      [theme.breakpoints.down(600)]: {
        fontSize: "18px !important",
        padding: "2px 0",
      },
      [theme.breakpoints.down(400)]: {
        fontSize: "16px !important",
        padding: "2px 0",
      },
    },
  },
  paragraph: {
    [theme.breakpoints.down(1169)]: {
      fontSize: "14px !important",
    },
  },
}));
