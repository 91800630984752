import React from "react";
import NumberFormat from "react-number-format";
import { TextField } from "@material-ui/core";

function NumberFormatter(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      customInput={TextField}
      getInputRef={inputRef ? inputRef : null}
      onValueChange={
        onChange
          ? (values) => {
              onChange({
                target: {
                  name: props.name,
                  value: values.value,
                },
              });
            }
          : () => {}
      }
    />
  );
}

export const PriceFormatter = (props) => {
  return (
    <NumberFormatter
      thousandSeparator
      isNumericString
      prefix="$"
      style={{ border: "none", textAlign: "center", outline: "none" }}
      {...props}
    />
  );
};

export const PhoneFormatter = (props) => {
  return (
    <NumberFormatter
      format={props.value !== "-" && "+1 (###) ###-####"}
      {...props}
    />
  );
};

export default NumberFormatter;
