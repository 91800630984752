import React from "react";
import HttpsRedirect from "react-https-redirect";
import App from "./App.js";

export default function HttpsApp(props) {
  return (
    // <HttpsRedirect>
      <App />
    // </HttpsRedirect>
  );
}
