import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Billing from "./Billing/Billing";
import CreateAccount from "./CreateAccount";
import { useHistory } from "react-router-dom";
import useKochava from "../../hooks/useKochava";

export default function SignUp(props) {
  const { recordActivity } = useKochava();
  const history = useHistory();
  const [view, setView] = useState("signUp");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firmName, setFirmName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    let value = params.referrer; // "some_value"
    if (value == "demo") {
      process.env.REACT_APP_TRACKING_ENABLED === "true" &&
        recordActivity("Lead From Demo: ", { Source: "demo" });
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
      window.history.pushState({ path: newurl }, "", newurl);
    }
    if (value == "launch") {
      process.env.REACT_APP_TRACKING_ENABLED === "true" &&
        recordActivity("Lead From Launch: ", { Source: "launch" });
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
      window.history.pushState({ path: newurl }, "", newurl);
    }
  }, []);

  const swapView = () => {
    if (view === "billing") setView("signUp");
    else setView("billing");
  };

  const { firms, getFirm, setAuth, notify } = props;

  switch (view) {
    case "signUp":
      return (
        <CreateAccount
          notify={notify}
          swapView={swapView}
          firstName={firstName}
          lastName={lastName}
          firmName={firmName}
          email={email}
          phone={phone}
          password={password}
          passwordConfirmation={passwordConfirmation}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setFirmName={setFirmName}
          setEmail={setEmail}
          setPhone={setPhone}
          setPassword={setPassword}
          setPasswordConfirmation={setPasswordConfirmation}
        />
      );
    case "billing":
      return (
        <Billing
          swapView={swapView}
          firstName={firstName}
          lastName={lastName}
          firmName={firmName}
          email={email}
          phone={phone}
          password={password}
          passwordConfirmation={passwordConfirmation}
          firms={firms}
          getFirm={getFirm}
          setAuth={setAuth}
          {...props}
        />
      );
    default:
      return null;
  }
}
