import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Box, Button, TextField } from "@material-ui/core";
import axios from "axios";
import Logo from "../../logo.svg";
import { ClipLoader } from "react-spinners";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { CSSTransition } from "react-transition-group";
import useKochava from "../../hooks/useKochava";

export default function Login(props) {
  // const { recordPage } = useKochava();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { notify } = props;
  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);
  const handleClick = (event) => (window.location = "/forgot-password");
  const inputProps = {
    variant: "outlined",
    color: "primary",
    inputProps: { maxLength: 50 },
  };
  const [fade, setFade] = useState(false);

  // useEffect(() => {
  //   recordPage();
  // }, []);

  useEffect(() => {
    setFade(true);
  }, []);

  const signIn = () => {
    setLoading(true);
    axios
      .post("/auth/portal/login", { email, password })
      .then((resp) => {
        localStorage.setItem("userId", resp.data.userId);
        localStorage.setItem("auth", resp.headers.authorization);
        console.log("resp log in", resp);
        props.setAuth(resp.headers.authorization);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error logging in", err?.response ? err.response : err);
        setLoading(false);
        notify(
          err.response?.data?.message
            ? err.response.data.message
            : "Error logging in",
          false
        );
      });
  };

  const enterPressed = (event) => {
    if (email && password) {
      var code = event.keyCode || event.which;
      if (code === 13) {
        signIn();
      }
    }
  };

  return (
    <CSSTransition in={fade} timeout={700} classNames="my-node">
      {fade ? (
        <div
          className="sign-in"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div>
            <img src={Logo} alt="Simple File Logo" style={{ width: 212 }} />
            <p
              style={{
                lineHeight: "24px",
                width: "66%",
                margin: "13px auto",
                color: "#4A4A4A",
                fontWeight: "500",
              }}
            >
              Welcome to your personal portal. Sign In to continue!
            </p>

            <form
              m={"2.3rem 1.2rem"}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <TextField
                className={classes.input}
                label="Email Address"
                autoComplete="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                onKeyPress={enterPressed}
                {...inputProps}
              />
              <div className={classes.relative}>
                <TextField
                  fullWidth
                  className={classes.input}
                  label="Password"
                  value={password}
                  name="password"
                  autoComplete="new-password"
                  type={showPassword ? "text" : "password"}
                  onChange={handlePasswordChange}
                  onKeyPress={enterPressed}
                  {...inputProps}
                />
                <div onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? (
                    <AiOutlineEyeInvisible
                      size={20}
                      className={classes.absolute}
                    />
                  ) : (
                    <AiOutlineEye size={20} className={classes.absolute} />
                  )}
                </div>
              </div>
            </form>

            <p
              onClick={handleClick}
              style={{
                cursor: "pointer",
                color: "#5CA1F2",
                textAlign: "right",
                margin: ".5rem 0",
                marginBottom: "4rem",
              }}
            >
              Forgot Password?
            </p>

            <Box>
              <Button
                className="auth-btn btn green"
                disabled={!email || !password || loading}
                onClick={signIn}
                style={{
                  width: 260,
                  height: 50,
                  alignSelf: "center",
                  textTransform: "none",
                }}
              >
                {loading ? (
                  <ClipLoader size={20} color="#fff" loading={loading} />
                ) : (
                  "Sign In"
                )}
              </Button>
            </Box>

            <Box>
              <button
                className={classes.button}
                style={{ marginTop: "20px", fontSize: 16 }}
                onClick={() => (window.location = "/sign-up")}
              >
                Sign Up
              </button>
            </Box>
          </div>
        </div>
      ) : (
        <div />
      )}
    </CSSTransition>
  );
}

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "#fff",
    margin: "3% 0",
    height: 50,
  },
  button: {
    fontWeight: "bold",
    textTransform: "none",
    color: "#2A528C",
    fontFamily: "inherit",
    border: "none",
    background: "none",
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      color: "#6db0eb",
    },
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
    top: 29,
    right: 16,
    cursor: "pointer",
  },
}));
