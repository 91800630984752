import React, { useState, useCallback } from "react";
import { Modal, TextField, Grid } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import { FiUpload } from "react-icons/fi";
import axios from "axios";
import { AddCircleSharp } from "@material-ui/icons";
import { ClipLoader } from "react-spinners";

export default function CommentModal(props) {
  const classes = useStyles();
  const {
    open,
    comments,
    setComments,
    setOpen,
    notify,
    user,
    caseObj,
    firms,
    loading,
    setLoading,
    fetchDocs,
    selectedComment,
    setAnchorEl,
  } = props;
  const [commentDocument, setCommentDocument] = useState(
    selectedComment?.documents || null
  );
  const [newComment, setNewComment] = useState(
    selectedComment?.comment || null
  );

  //uploads document to comments
  function uploadDocument(selectedComment, newComment, newDocument) {
    setLoading(true);
    let file = new FormData();
    file.append("postId", caseObj.id);
    file.append("firmId", firms[0].firmId);
    //if document exists when updating comment, send up documentId
    if (newDocument) {
      file.append("documentId", selectedComment.documents.id);
    }
    if (commentDocument.type.includes("pdf")) {
      file.append("files", commentDocument);
      //updating image to document - updates selectedComment document's fileName from jpg to pdf
      if (selectedComment?.documents?.fileName) {
        file.append(
          "documentName",
          selectedComment.documents.fileName.replace("jpg", "pdf")
        );
      }
      axios
        .post("/upload/documents", file, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((resp) => {
          setLoading(false);
          if (selectedComment) {
            editComment(selectedComment, newComment, resp.data.response);
          } else {
            addComment(resp.data.documents.id, resp.data.response);
          }
        })
        .catch((err) => {
          console.log(
            "Error uploading document",
            err.response ? err.response : err
          );
          setLoading(false);
          notify("Error adding document. Please try again later.", false);
        });
    } else {
      file.append("photos", commentDocument);
      //updating document to image - updates selectedComment document's fileName from pdf to jpg
      if (selectedComment?.documents?.fileName) {
        file.append(
          "documentName",
          selectedComment.documents.fileName.replace("pdf", "jpg")
        );
      }
      axios
        .post("/upload/images", file, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((resp) => {
          setLoading(false);
          if (selectedComment) {
            editComment(selectedComment, newComment, resp.data.response);
          } else {
            addComment(resp.data.documents.id, resp.data.response);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(
            "Error uploading document",
            err.response ? err.response : err
          );
        });
    }
  }

  const addComment = (documentId, document) => {
    setLoading(true);
    setOpen(false);
    try {
      let { id, userId } = props.caseObj;

      axios
        .post("/comment/firm", {
          documentId,
          postId: id,
          clientId: userId,
          comment: newComment,
        })
        .then((resp) => {
          setLoading(false);
          let arr = [...comments];
          let comment = resp.data.comment;
          comment.author = {
            firstName: user.firstName,
            lastName: user.lastName,
          };
          comment.documents = document;
          comment.documentId = resp.data.comment.id;
          arr.unshift(comment);
          setComments([...arr]);
          notify("Comment submitted!", true);
          fetchDocs();
        })
        .catch((err) => {
          setLoading(false);
          notify("Error submitting comment. Please try again later.", false);
          console.log(
            "Error adding comment",
            err.response ? err.response : err
          );
        });
    } catch (err) {
      console.log("Error adding comment or document", err);
    }
  };

  const editComment = (data, newComment, newDocument) => {
    console.log("data here", data, newComment, newDocument);
    setLoading(true);
    setOpen(false);
    try {
      axios
        .put(`/comment/${data.id}`, {
          comment: newComment,
          documentId: newDocument?.id,
        })
        .then((resp) => {
          setLoading(false);
          let arr = [...comments];
          let index = arr.findIndex((el) => el.id == data.id);
          let comment = resp.data.post;
          comment.author = {
            firstName: data.author.firstName,
            lastName: data.author.lastName,
          };
          if (newDocument) {
            comment.documents = newDocument;
            comment.documentId = data?.documents?.id
              ? data.documents.id
              : newDocument.id;
          }
          arr[index] = comment;
          setComments([...arr]);
          notify("Comment updated!", true);
        })
        .catch((err) => {
          setLoading(false);
          notify("Error submitting comment. Please try again later.", false);
          console.log(
            "Error updating comment",
            err.response ? err.response : err
          );
        });
    } catch (err) {
      console.log("Error updating comment or document", err);
    }
  };

  const handleSubmitComment = () => {
    //edit comment
    if (selectedComment) {
      if (selectedComment?.documents) {
        if (selectedComment?.documents.fileName == commentDocument.fileName) {
          //updates only comment if no new document is attached to a comment with document
          editComment(selectedComment, newComment, commentDocument);
        } else {
          //updates document to existing comment
          uploadDocument(selectedComment, newComment, commentDocument);
        }
      } else {
        if (commentDocument) {
          //uploads new document to existing comment
          uploadDocument(selectedComment, newComment);
        } else {
          //edit comment with no document
          editComment(selectedComment, newComment, commentDocument);
        }
      }
    }
    //submit comment
    else {
      if (commentDocument) {
        //posts new comment with new document
        uploadDocument();
      } else {
        //posts comment with no document
        addComment();
      }
    }
  };

  //React Dropzone
  const onDrop = useCallback((acceptedFiles) => {
    setCommentDocument(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ margin: "1rem" }}
      onClick={handleClose}
    >
      <div className="modal">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-form">
            <div className={classes.root}>
              <ClearIcon className="close" onClick={handleClose} />
              <h2 className={classes.heading}>
                {selectedComment ? "Edit Comment" : "Add a Comment"}
              </h2>
              <TextField
                id="outlined-multiline-static"
                label="Comment"
                multiline
                fullWidth
                rows={3}
                variant="outlined"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />
              <Grid
                container
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={4}>
                  <input
                    type="file"
                    className="file-upload"
                    style={{ fontSize: 16, cursor: "pointer" }}
                    onChange={(e) => {
                      setCommentDocument(e.target.files[0]);
                    }}
                    style={{ display: "none" }}
                    id="file"
                  />
                  <label for="file" className="choose-file-input">
                    Choose File
                  </label>
                </Grid>
                <Grid item xs={8}>
                  <p className="choose-file-text">
                    {commentDocument ? (
                      commentDocument.name || commentDocument.fileName
                    ) : (
                      <span style={{ color: "#5C5C5C" }}>
                        No PDF File Chosen
                      </span>
                    )}
                  </p>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                {...getRootProps()}
                className="drop-file-section"
              >
                <Grid item xs={12}>
                  <FiUpload />
                </Grid>
                <Grid item xs={12}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the file here..</p>
                  ) : (
                    <p>You can also drop your file here.</p>
                  )}
                </Grid>
              </Grid>
              <button
                className="btn light-blue add-btn"
                style={{
                  alignItems: "center",
                  border: "none",
                }}
                disabled={!newComment || loading}
                onClick={handleSubmitComment}
              >
                {loading ? (
                  <div
                    className="sweet-loading"
                    style={{
                      position: "absolute",
                      width: 15,
                      height: 15,
                      top: 13,
                    }}
                  >
                    <ClipLoader
                      size={20}
                      color={"rgb(47, 86, 138)"}
                      loading={loading}
                    />
                  </div>
                ) : (
                  <span>Submit</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      display: "flex",
      justifyContent: "center",
      margin: "40px auto",
      textAlign: "center",
      [theme.breakpoints.down(768)]: {
        margin: "24px auto",
      },
      [theme.breakpoints.down(415)]: {
        width: "100%",
      },
    },
  },
  heading: {
    fontSize: 32,
    fontWeight: 700,
    marginTop: 16,
    marginBottom: 16,
    [theme.breakpoints.down(768)]: {
      fontSize: 24,
    },
  },
}));
