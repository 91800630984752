import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Box,
  useMediaQuery,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ClipLoader } from "react-spinners";
import pdf from "../../../assets/pdf-icon.svg";
import png from "../../../assets/png-icon.svg";
import DocumentModal from "../Modals/DocumentModal";
import TimeAgo from "react-timeago";
import { CSSTransition } from "react-transition-group";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import remove from "../../../assets/remove.svg";
import axios from "axios";

export default function ClientDocuments(props) {
  const classes = useStyles();
  const {
    loading,
    timeAgo,
    open,
    setOpen,
    caseObj,
    notify,
    firms,
    setModal,
    fetchDocs,
    clientDocuments,
    setClientDocuments,
    isSampleCase,
    user,
  } = props;
  const smScreen = useMediaQuery("(max-width:768px)");
  const mdScreen = useMediaQuery("(max-width:1024px)");
  const [fade, setFade] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeDocument, setActiveDocument] = React.useState(null);

  useEffect(() => {
    fetchDocs();
    setFade(true);
  }, []);

  const handleMouseOver = (event, el) => {
    setActiveDocument(el);
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    console.log("active document", activeDocument);
    const { id } = activeDocument;
    console.log("id here", id);
    axios
      .delete(`/documents/document/${id}`, {
        documentId: id,
      })
      .then((resp) => {
        notify("Document successfully removed!", true);
        console.log("E-Sign Request deleted!", resp);
        let arr = [...clientDocuments];
        let index = arr.findIndex((el) => el.id == activeDocument.id);
        arr.splice(index, 1);
        setClientDocuments([...arr]);
        setAnchorEl(null);
      })
      .catch((err) => {
        console.log("Error deleting document", err, err.response, err.message);
      });
  };

  return (
    <CSSTransition in={fade} timeout={700} classNames="my-node">
      <div>
        {fade ? (
          <Grid
            className={
              clientDocuments.length !== 0 || isSampleCase
                ? classes.withDocuments
                : classes.withoutDocuments
            }
          >
            {!loading ? (
              <div>
                {!isSampleCase && (
                  <p
                    style={{
                      margin: "14px 0",
                      display: clientDocuments.length !== 0 && "none",
                    }}
                  >
                    There are currently no documents
                  </p>
                )}

                <div className={classes.withDocuments}>
                  <Button
                    className="btn case"
                    onClick={() => !isSampleCase && setOpen(true)}
                  >
                    + Add Document
                  </Button>
                </div>

                <Box
                  className="auto-height-mobile"
                  style={{
                    display: clientDocuments.length === 0 && "none",
                  }}
                >
                  <Box p={smScreen ? 0 : 2} className={classes.scroll}>
                    {clientDocuments?.map((el, i) => (
                      <Box key={i} className={classes.comment}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "bold",
                              textTransform: "capitalize",
                            }}
                          >
                            {el.author?.firstName} {el.author?.lastName}
                          </p>
                          <Grid item xs={1} key={i}>
                            <div
                              onClick={(e) =>
                                mdScreen && handleMouseOver(e, el)
                              }
                              onMouseOver={(e) =>
                                !mdScreen && handleMouseOver(e, el)
                              }
                              className="ellipses-icon"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: 40,
                              }}
                            >
                              <FiberManualRecordIcon />
                              <FiberManualRecordIcon />
                              <FiberManualRecordIcon />
                            </div>
                          </Grid>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 8,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              marginRight: 16,
                            }}
                          >
                            {el.fileName.includes("pdf") ? (
                              <img
                                src={pdf}
                                alt="PDF icon"
                                className={classes.icon}
                              />
                            ) : (
                              <img
                                src={png}
                                alt="PNG icon"
                                className={classes.icon}
                              />
                            )}
                          </div>
                          <p>{el.title}</p>
                        </div>
                        <Grid item xs={12}>
                          {el?.fileName?.includes("pdf") ? (
                            <div className={classes.image}>
                              <embed
                                src={el.document}
                                width={"100%"}
                                height={500}
                                style={{ margin: "16px auto" }}
                              />
                            </div>
                          ) : (
                            <div className={classes.image}>
                              <img
                                src={el.document}
                                alt="Image"
                                style={{
                                  width: "100%",
                                  height: 500,
                                  objectFit: "cover",
                                  margin: "16px auto",
                                }}
                              />
                            </div>
                          )}
                        </Grid>
                        <p
                          style={{
                            color: "#D3D3D3",
                            fontWeight: "600",
                            fontSize: 12,
                            margin: 0,
                          }}
                        >
                          {timeAgo(el.createdAt)}
                          {/* <TimeAgo date={timeAgo(el.createdAt)} /> */}
                        </p>
                      </Box>
                    ))}
                    {/* SHOW MENU ON HOVER */}
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      MenuListProps={{ onMouseLeave: handleClose }}
                      onClose={handleClose}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <MenuItem
                        onClick={() => handleDelete()}
                        className={classes.menuItem}
                      >
                        <img
                          className={classes.menuIcon}
                          src={remove}
                          alt="Remove Icon"
                          style={{
                            width: 12,
                            height: 12,
                            marginRight: 12,
                          }}
                        />
                        Remove
                      </MenuItem>
                    </Menu>
                  </Box>
                </Box>
              </div>
            ) : (
              <div
                className="sweet-loading"
                style={{
                  width: 100,
                  height: 75,
                  margin: "5% auto",
                  minHeight: "calc(100vh)",
                  display: clientDocuments ? "flex" : "none",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ClipLoader
                  style={{ position: "absolute" }}
                  size={75}
                  color={"rgb(47, 86, 138)"}
                />
              </div>
            )}
            {open && (
              <DocumentModal
                caseId={caseObj?.id}
                open={open}
                setOpen={setOpen}
                notify={notify}
                firms={firms}
                caseObj={caseObj}
                setModal={setModal}
                clientDocuments={clientDocuments}
                setClientDocuments={setClientDocuments}
                user={user}
              />
            )}
          </Grid>
        ) : (
          <div />
        )}
      </div>
    </CSSTransition>
  );
}

const useStyles = makeStyles((theme) => ({
  withDocuments: {
    display: "flex",
    backgroundColor: "#FAFAFA",
    flexDirection: "column",
    justifyContent: "center",
  },
  withoutDocuments: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    [theme.breakpoints.up(896)]: {
      height: "100vh",
    },
  },
  scroll: {
    margin: "auto",
    height: "85vh",
    overflowY: "auto",
  },
  comment: {
    width: "90%",
    backgroundColor: "#fff",
    borderRadius: 10,
    margin: "0 auto 5%",
    padding: "2% 5%",
  },
  icon: {
    width: 31,
    height: 31,
  },
}));
