import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Button, TextField, Avatar, useMediaQuery } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as AccountIcon } from "../../assets/account.svg";
import { ReactComponent as BusinessInfoIcon } from "../../assets/businessInfo.svg";
import { ReactComponent as PlanAndBillingIcon } from "../../assets/planAndBilling.svg";
import AccountInfo from "./Tabs/AccountInfo";
import BusinessInfo from "./Tabs/BusinessInfo";
import PlansBilling from "./Tabs/PlansBilling";
import ZeroStateModal from "../Modal/ZeroStateModal";
import { Switch, Route, Link, BrowserRouter, Redirect } from "react-router-dom";

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;
//   const mobile = useMediaQuery("(max-width:769px)");

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`vertical-tabpanel-${index}`}
//       aria-labelledby={`vertical-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box py={!mobile && 15} px={mobile ? 2 : 7}>
//           {children}
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `vertical-tab-${index}`,
//     "aria-controls": `vertical-tabpanel-${index}`,
//   };
// }

export default function Account(props) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState("");
  const [value, setValue] = useState(props.verified ? 0 : 1);
  const [fade, setFade] = useState(false);
  const mobile = useMediaQuery("(max-width:769px)");
  const {
    notify,
    self,
    getSelf,
    fetchUser,
    getFirm,
    checkVerification,
    auth,
    setAuth,
    planDetails,
    firms,
    cancelled,
    payment,
    isLoading,
    modal,
    setModal,
    setIsSubscribed,
  } = props;

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      background: "#fff !important",
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
      },
    },
    tabpanelRoot: {
      padding: 0,
      height: `calc(100vh - 52px)`,
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    setFade(true);
    fetchUser();
    if (!props.verified) props.setModal(true);
  }, []);
  // Receives user data when ready
  // componentDidUpdate() { this.fetchUser() }

  function handleChange(e) {
    user[e.target.name] = e.target.value;
    setUser(user);
  }

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function cancelSubscription() {
    axios
      .post("/stripe/cancel")
      .then((resp) => console.log("Subscription cancelled", resp))
      .catch((err) =>
        console.log("Error cancelling subscription", err.response.data)
      );
  }

  let tabStyle = {};
  const allTabs = ["/account", "/account/business", "/account/plans"];

  if (mobile) {
    tabStyle.minWidth = 50;
    tabStyle.padding = "0 8px";
    tabStyle.letterSpacing = "-.04em";
    tabStyle.margin = "0 auto";
  }

  return (
    <CSSTransition in={fade} timeout={700} classNames="my-node">
      {fade ? (
        <div key="root" className={classes.root}>
          <BrowserRouter>
            <Route
              path="/"
              render={({ location }) => (
                <Fragment>
                  {/* <Tabs value={location.pathname}> */}
                  <Grid container>
                    <Grid item xs={mobile ? 12 : 3}>
                      <Box py={4} pb={mobile && 0}>
                        <p
                          style={{
                            fontSize: 24,
                            color: "#000",
                            fontWeight: "700",
                            margin: mobile ? "0 0 .6rem" : "23px auto 10px",
                          }}
                        >
                          My Account
                        </p>
                        <div
                          className="tabs-wrapper"
                          style={{ marginTop: mobile ? 16 : 24 }}
                        >
                          <Tabs
                            orientation={!mobile && "vertical"}
                            variant="scrollable"
                            value={location.pathname}
                            indicatorColor="transparent"
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            className={classes.tabs}
                            classes={{
                              indicator: classes.indicator,
                            }}
                          >
                            <Tab
                              label="Item One"
                              value="/account"
                              component={Link}
                              to={allTabs[0]}
                              style={tabStyle}
                              icon={
                                <AccountIcon
                                  fill={
                                    value == allTabs[0] ||
                                    window.location.pathname == allTabs[0]
                                      ? "#498CC2"
                                      : "#5C5C5C"
                                  }
                                  // fill={
                                  //   value == allTabs[0] ||
                                  //   window.location.pathname == allTabs[0]
                                  //     ? "#498CC2"
                                  //     : "#5C5C5C"
                                  // }
                                />
                              }
                              label={
                                <span
                                  style={{
                                    textTransform: "capitalize",
                                    fontWeight: "500",
                                    fontSize: 16,
                                    color:
                                      (value == allTabs[0] ||
                                        window.location.pathname ==
                                          allTabs[0]) &&
                                      "rgba(73,140,194,1)",
                                  }}
                                >
                                  Account
                                </span>
                              }
                              // disabled={!props.verified}
                            />
                            <Tab
                              label="Item Two"
                              value="/account/business"
                              component={Link}
                              to={allTabs[1]}
                              style={tabStyle}
                              icon={
                                <BusinessInfoIcon
                                  fill={
                                    value == allTabs[1] ||
                                    window.location.pathname == allTabs[1]
                                      ? "#498CC2"
                                      : "#5C5C5C"
                                  }
                                />
                              }
                              label={
                                <span
                                  style={{
                                    textTransform: "capitalize",
                                    fontWeight: "500",
                                    fontSize: 16,
                                    color:
                                      (value == allTabs[1] ||
                                        window.location.pathname ==
                                          allTabs[1]) &&
                                      "rgba(73,140,194,1)",
                                  }}
                                >
                                  Business Info
                                </span>
                              }
                            />
                            <Tab
                              value="/account/plans"
                              label="Item Three"
                              component={Link}
                              to={allTabs[2]}
                              style={tabStyle}
                              icon={
                                <PlanAndBillingIcon
                                  fill={
                                    value == allTabs[2] ||
                                    window.location.pathname == allTabs[2]
                                      ? "#498CC2"
                                      : "#5C5C5C"
                                  }
                                />
                              }
                              label={
                                <span
                                  style={{
                                    textTransform: "capitalize",
                                    fontWeight: "500",
                                    fontSize: 16,
                                    color:
                                      (value == allTabs[2] ||
                                        window.location.pathname ==
                                          allTabs[2]) &&
                                      "rgba(73,140,194,1)",
                                  }}
                                >
                                  Plans & Billing
                                </span>
                              }
                            />
                          </Tabs>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xs={mobile ? 12 : 9}>
                      <Box py={mobile ? 2 : 12} pb={mobile && 0} px={3}>
                        <Switch>
                          <Route
                            path={allTabs[1]}
                            render={() => (
                              <BusinessInfo
                                checkVerification={checkVerification}
                                verified={props.verified}
                                firms={firms}
                                fade={value == allTabs[1] ? true : false}
                                notify={notify}
                                getFirm={getFirm}
                                cancelled={cancelled}
                                self={self}
                              />
                            )}
                          />
                          <Route
                            path={allTabs[2]}
                            render={() => (
                              <PlansBilling
                                fade={value == allTabs[2] ? true : false}
                                auth={auth}
                                set={setAuth}
                                getFirm={getFirm}
                                user={self}
                                notify={notify}
                                planDetails={planDetails}
                                isLoading={isLoading}
                                payment={payment}
                                checkVerification={checkVerification}
                                setIsSubscribed={setIsSubscribed}
                                {...props}
                              />
                            )}
                          />
                          <Route
                            path={allTabs[0]}
                            render={() => (
                              <AccountInfo
                                self={self}
                                getSelf={getSelf}
                                fade={value == allTabs[0] ? true : false}
                                notify={notify}
                              />
                            )}
                          />
                        </Switch>
                      </Box>
                    </Grid>
                  </Grid>
                </Fragment>
              )}
            />
          </BrowserRouter>
          {props.modal && (
            <ZeroStateModal
              modalVisible={modal}
              headerText="Congratulations!"
              headerText2="Your account is now active."
              paragraphText="To start using SimpleFile right now, simply add the details of your business, practice or firm."
              firstButton={true}
              firstButtonText="Got it!"
              handleClick={() => setModal(false)}
              handleClose={() => setModal(false)}
              paragraphWidth={366}
            />
          )}
        </div>
      ) : (
        <div />
      )}
    </CSSTransition>
  );
}

const styles = {
  input: {
    margin: "1% 0",
  },
  checkbox: {
    borderRadius: 0,
    margin: "1% 0",
  },
  section: {
    textAlign: "left",
    fontSize: 24,
    fontWeight: "700",
    color: "rgba(92,92,92)",
  },
  icon: {
    width: 16,
    height: 16,
  },
};
