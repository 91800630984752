import { PinDropSharp } from "@material-ui/icons";
import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { TextField, Grid } from "@material-ui/core";

export default function Autocomplete(props) {
  return (
    <PlacesAutocomplete
      value={props.value}
      onChange={props.handleChange}
      onSelect={props.handleSelect}
      inputProps={{ disabled: props.disabled }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ position: "relative" }}>
          <Grid container className={props.classNameGrid}>
            <TextField
              fullWidth
              label={props.label}
              variant={props.variant}
              color={props.color}
              className={props.classNameInput}
              placeholder={props.placeholder}
              {...getInputProps({
                autoComplete: "none",
                disabled: props.disabled,
                placeholder: props.placeholder,
                className: "location-search-input",
              })}
            />
          </Grid>
          <div
            className="autocomplete-dropdown-container"
            style={{
              position: "absolute",
              backgroundColor: "white",
              zIndex: 2,
              width: "100%",
              textAlign: "left",
              boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
            }}
          >
            {loading && (
              <div
                style={{
                  padding: "10px 8px",
                }}
              >
                Loading...
              </div>
            )}
            {suggestions.map((suggestion, key) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? {
                    backgroundColor: "#fafafa",
                    cursor: "pointer",
                    padding: "10px 8px",
                  }
                : {
                    backgroundColor: "#ffffff",
                    cursor: "pointer",
                    padding: "10px 8px",
                  };
              return (
                <div
                  key={key}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}
