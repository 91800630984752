import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  OutlinedInput,
  FormControl,
  Tooltip,
} from "@material-ui/core";
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import "../stripe.css";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
} from "react-stripe-elements";
import { ClipLoader } from "react-spinners";
import { ReactComponent as QuestionIcon } from "../../../assets/question-icon.svg";
import "../../../App.css";
import check from "../../../assets/check.svg";
import arrow from "../../../assets/arrow.svg";

const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        padding: "16px",
        fontSize: 14,
      },
    },
  },
});

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: "#424770",
        letterSpacing: "0.025em",
        fontWeight: "300",
        fontSize: "16px",
        fontFamily: "sans-serif",
        "::placeholder": {
          color: "rgba(181, 181, 181, 0.7)",
          fontWeight: 300,
          fontSize: "16px",
          letterSpacing: "0.025em",
        },
        padding,
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };
};

function CheckoutForm(props) {
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cardholderName, setCardholderName] = useState("");
  const [isCardComplete, setIsCardComplete] = useState(false);
  const [isExpirationComplete, setIsExpirationComplete] = useState(false);
  const [isCvcComplete, setIsCvcComplete] = useState(false);
  const [zip, setZip] = useState("");
  const [promoCode, setPromoCode] = useState(null);
  const [tooltip, setTooltip] = useState(true);
  const [promoCodes, setPromoCodes] = useState([]);
  const [validatedPromoCode, setValidatedPromoCode] = useState(null);

  const classes = useStyles();

  const inputProps = {
    variant: "outlined",
    className: classes.input,
    inputProps: {
      maxLength: 50,
      style: {
        textTransform: "capitalize",
        height: 50,
        paddingTop: 0,
        paddingBottom: 0,
        boxShadow:
          "rgb(50 50 93 / 15%) 0px 1px 3px, rgb(0 0 0 / 2%) 0px 1px 0px",
      },
    },
  };

  const inputPropsZip = {
    variant: "outlined",
    className: `${classes.input}`,
    inputProps: {
      maxLength: 5,
      style: {
        height: 50,
        boxShadow:
          "rgb(50 50 93 / 15%) 0px 1px 3px, rgb(0 0 0 / 2%) 0px 1px 0px",
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  };

  const {
    getFirm,
    setAuth,
    firstName,
    lastName,
    email,
    phone,
    password,
    notify,
    isSignedIn = false,
    stripeCustomerId,
    auth,
    checkVerification,
    setIsSubscribed,
  } = props;

  React.useEffect(() => {
    axios
      .get("/stripe/promo")
      .then((resp) => {
        setPromoCodes(resp.data.promotionCodes.data);
      })
      .catch((err) => console.log("Error fetching coupons", err));
  }, []);

  function handleSubmit(customerId, token) {
    const cardNumberElement = props.elements.getElement("cardNumber");

    //create payment method
    props.stripe
      .createPaymentMethod({
        type: "card",
        card: cardNumberElement,
        billing_details: {
          name: cardholderName,
        },
      })
      .then((response) => {
        if (response?.error) {
          setLoading(false);
          notify(
            response?.error?.message
              ? response.error.message
              : "Issue processing card. Please double check the information or try again later.",
            false
          );
          getFirm(token).then(() => {
            setAuth(token);
          });
        } else {
          let { paymentMethod } = response;

          let body = {
            paymentMethodId: paymentMethod.id,
            customerId,
            priceId: process.env.REACT_APP_STRIPE_PRICE_ID,
          };

          if (
            promoCode?.toLowerCase() ===
            process.env.REACT_APP_STRIPE_60_DAY_PROMO_CODE_VALUE
          ) {
            body.promoCode =
              process.env.REACT_APP_STRIPE_60_DAY_PROMO_CODE_VALUE;
          } else if (
            promoCode?.toLowerCase() ===
            process.env.REACT_APP_STRIPE_30_DAY_PROMO_CODE_VALUE
          ) {
            body.promoCode =
              process.env.REACT_APP_STRIPE_30_DAY_PROMO_CODE_VALUE;
          }

          // if (validatedPromoCode) body.promoCode = validatedPromoCode;

          axios
            .post("/stripe/subscription", body, {
              headers: {
                Authorization: token,
              },
            })
            .then((resp) => {
              setLoading(false);
              getFirm(token).then(() => {
                setAuth(token);
              });
              window.location = "/account/business";
            })
            .catch((err) => {
              console.log("Error setting up subscription", err.response);
              notify(
                err.response
                  ? err.response.data.message
                  : "Issue processing card. Please double check the information or try again later",
                false
              );
              setLoading(false);
              getFirm(token).then(() => {
                setAuth(token);
              });
            });
        }
      });
  }

  function checkCouponValidity() {
    if (
      promoCode.toLowerCase() ===
        process.env.REACT_APP_STRIPE_60_DAY_PROMO_CODE_VALUE ||
      promoCode.toLowerCase() ===
        process.env.REACT_APP_STRIPE_30_DAY_PROMO_CODE_VALUE
    ) {
      setValidatedPromoCode(true);
    } else {
      setValidatedPromoCode(false);
    }
    // let found = promoCodes.find((el) => el.code === promoCode);
    // if (found) setValidatedPromoCode(found.id);
    // else {
    //   setValidatedPromoCode(false);
    //   setPromoCode("");
    // }
  }

  function createAccount() {
    try {
      setLoading(true);

      axios
        .post("/auth/portal/signup", {
          firstName,
          lastName,
          email,
          phone,
          password,
        })
        .then((resp) => {
          localStorage.setItem("userId", resp.data.userId);
          localStorage.setItem("auth", resp.headers.authorization);

          //create Clio contact
          axios
            .post("/clio/createContact", {
              name: `${firstName} ${lastName}`,
            })
            .then((clioResp) => {
              console.log("Clio resp here", clioResp.data);
            })
            .catch((err) => {
              console.log(
                "Error creating Clio contact",
                err.response ? err.response.data.message : err
              );
            });

          handleSubmit(
            resp.data.user.stripeCustomerId,
            resp.headers.authorization
          );
        })
        .catch((err) => {
          console.log(
            "Error signing up",
            err.response ? err.response.data.message : err
          );
          notify(
            err.response
              ? err.response.data.message
              : "Error signing up. Please try again",
            false
          );
          setLoading(false);
        });
    } catch (e) {
      console.log("Error signing up", e);
      notify(
        e.response
          ? e.response.data.message
          : "Error signing up. Please try again",
        false
      );
    }
  }

  function createPlan() {
    const cardNumberElement = props.elements.getElement("cardNumber");
    setLoading(true);

    //create payment method
    props.stripe
      .createPaymentMethod({
        type: "card",
        card: cardNumberElement,
        billing_details: {
          name: cardholderName,
        },
      })
      .then((response) => {
        if (response?.error) {
          setLoading(false);
          notify(
            response?.error?.message
              ? response.error.message
              : "Issue processing card. Please double check the information or try again later.",
            false
          );
        } else {
          let { paymentMethod } = response;

          let body = {
            paymentMethodId: paymentMethod.id,
            customerId: stripeCustomerId,
            priceId: process.env.REACT_APP_STRIPE_PRICE_ID,
          };

          if (
            promoCode?.toLowerCase() ===
            process.env.REACT_APP_STRIPE_PROMO_CODE_VALUE
          ) {
            body.promoCode = process.env.REACT_APP_STRIPE_PROMO_CODE_VALUE;
          }
          // if (validatedPromoCode) body.promoCode = validatedPromoCode;

          axios
            .post("/stripe/subscription", body, {
              headers: {
                Authorization: auth,
              },
            })
            .then((resp) => {
              setLoading(false);
              console.log("subscription successfully created", resp);
              // window.location = "/account/business";
              setIsSubscribed(true);
              checkVerification(auth);
              notify("Payment Successful! You are now subscribed.", true);
            })
            .catch((err) => {
              console.log("Error setting up subscription", err.response);
              notify(
                err.response
                  ? err.response.data.message
                  : "Issue processing card. Please double check the information or try again later",
                false
              );
              setLoading(false);
            });
        }
      });
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <h2
          style={{
            fontFamily: "SFProDisplay-Medium",
            color: "#000",
            fontSize: 16,
            margin: "0 0 15px",
          }}
        >
          Enter Credit Card Info
        </h2>
        <Grid item xs={12} style={{ marginBottom: 29 }}>
          <FormControl fullWidth>
            <OutlinedInput
              placeholder="Cardholder Name"
              value={cardholderName}
              {...inputProps}
              onChange={(e) => setCardholderName(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 29 }}>
          <CardNumberElement
            placeholder="Credit Card Number"
            {...createOptions()}
            className={"card-input"}
            onChange={(e) => setIsCardComplete(e.complete)}
          />
        </Grid>

        <div style={{ marginBottom: 15, display: "flex", width: "100%" }}>
          <Grid item xs={4} style={{ marginRight: 8 }}>
            <CardExpiryElement
              placeholder="Exp (MM/YY)"
              {...createOptions()}
              className={"card-input"}
              onChange={(e) => setIsExpirationComplete(e.complete)}
            />
          </Grid>
          <Grid item xs={4} style={{ position: "relative" }}>
            <CardCVCElement
              {...createOptions()}
              className={"card-input"}
              onChange={(e) => setIsCvcComplete(e.complete)}
            />

            <div className={classes.icon}>
              <MuiThemeProvider theme={defaultTheme}>
                <MuiThemeProvider theme={theme}>
                  <Tooltip
                    placement="top"
                    title="The CVC (Card Validation Code) is the last three digits on the
              back of your card."
                  >
                    <QuestionIcon />
                  </Tooltip>
                </MuiThemeProvider>
              </MuiThemeProvider>
            </div>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              flex: 1,
              marginLeft: 8,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl fullWidth>
              <OutlinedInput
                placeholder="Zip Code"
                {...inputPropsZip}
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              />
            </FormControl>
          </Grid>
        </div>

        <Grid
          item
          xs={4}
          style={{
            marginRight: 8,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {validatedPromoCode != null && (
            <Box my={1} style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: 12,
                  margin: 0,
                  color: validatedPromoCode == false ? "#ff7070" : "#20528c",
                }}
              >
                {validatedPromoCode == false
                  ? "Promo code invalid"
                  : "Applied!"}
              </p>
            </Box>
          )}
        </Grid>

        <Grid
          item
          xs={4}
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControl fullWidth {...inputProps}>
            <OutlinedInput
              placeholder="Promo Code"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              {...inputProps}
            />
            <div className={classes.promoIcon}>
              <MuiThemeProvider theme={defaultTheme}>
                <MuiThemeProvider theme={theme}>
                  <Tooltip
                    placement="top"
                    title="Promotional codes are optional and used for special discounts and features."
                  >
                    <QuestionIcon />
                  </Tooltip>
                </MuiThemeProvider>
              </MuiThemeProvider>
            </div>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={4}
          style={{
            flex: 1,
            marginLeft: 8,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            className={classes.applyButton}
            variant="text"
            onClick={() => checkCouponValidity()}
            disabled={!promoCode}
          >
            {loading ? (
              <ClipLoader size={20} color="#fff" loading={loading} />
            ) : (
              "Apply"
            )}
          </Button>
        </Grid>

        {!isSignedIn && (
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                margin: "35px 0 32px",
                marginRight: 8,
              }}
            >
              <Checkbox
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                inputProps={{ "aria-label": "primary checkbox" }}
                iconStyle={{ fill: "red" }}
                style={{ margin: "2px 0" }}
              />
              <p
                style={{
                  fontFamily: "SFProDisplay-Regular",
                  color: "#5c5c5c",
                  // lineHeight: "24px",
                  lineHeight: "16px",
                  textAlign: "left",
                  margin: 0,
                  fontSize: 16,
                  lineHeight: 1.6,
                }}
              >
                I have read and agree to the SimpleFile{" "}
                <u>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://backend.simplefileapp.com/terms"
                  >
                    Terms of Conditions
                  </a>
                </u>{" "}
                <u>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://backend.simplefileapp.com/privacy"
                  >
                    Privacy Policy, and Membership Terms
                  </a>
                </u>
              </p>
            </div>
          </Grid>
        )}

        {isSignedIn ? (
          <div style={{ marginTop: 40, width: "100%" }}>
            <Button
              className={classes.signUpButton}
              onClick={() => createPlan()}
              disabled={
                !cardholderName ||
                !isCardComplete ||
                !isExpirationComplete ||
                !isCvcComplete ||
                !zip ||
                // (promoCode && !validatedPromoCode) ||
                loading
              }
            >
              {loading ? (
                <ClipLoader size={20} color="#fff" loading={loading} />
              ) : (
                <div style={{ display: "flex" }}>
                  <span>Submit&nbsp;&nbsp;&nbsp;</span>
                  <img src={arrow} />
                </div>
              )}
            </Button>
          </div>
        ) : (
          <Button
            className={classes.signUpButton}
            onClick={() => createAccount()}
            disabled={
              !cardholderName ||
              !isCardComplete ||
              !isExpirationComplete ||
              !isCvcComplete ||
              !zip ||
              !checked ||
              // (promoCode && !validatedPromoCode) ||
              loading
            }
          >
            {loading ? (
              <ClipLoader size={20} color="#fff" loading={loading} />
            ) : (
              <div style={{ display: "flex" }}>
                <span>Next&nbsp;&nbsp;&nbsp;</span>
                <img src={arrow} />
              </div>
            )}
          </Button>
        )}
        <div
          style={{
            opacity: "65%",
            fontSize: 13,
            width: "100%",
            textAlign: "center",
          }}
        >
          Cancel anytime. No commitments.
        </div>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  signUpButton: {
    margin: "14px 0 !important",
    textTransform: "capitalize",
    boxShadow: "0 3px 12px rgb(0 0 0 / 0.5)",
    color: "#fff !important",
    fontWeight: "bold !important",
    width: "100%",
    height: 50,
    borderRadius: 20,
    backgroundColor: "#2a528c !important",
  },
  button: {
    margin: "2.5rem 0 .8rem 0",
    [theme.breakpoints.down("sm")]: {
      margin: ".8rem 0 .5rem 0",
    },
    width: "100%",
    text: {
      "&:hover": {
        backgroundColor: "#fff",
        color: "#3c52b2",
      },
    },
  },
  header: {
    fontSize: 30,
    margin: "2.5rem 0",
    color: "#000",
    [theme.breakpoints.down("xs")]: {
      fontSize: 24,
    },
  },
  label: {
    color: "#B5B5B5",
  },
  input: {
    color: "#424770",
    letterSpacing: "0.025em",
    fontSize: 16,
    fontFamily: "sans-serif !important",
    "& input::placeholder": {
      fontWeight: 350,
      letterSpacing: "0.035em !important",
      color: "rgba(0, 0, 0, 0.5)",
    },
  },
  icon: {
    position: "absolute",
    top: 18,
    right: 16,
    "&:hover": {
      cursor: "pointer",
    },
  },
  promoIcon: {
    position: "absolute",
    top: 18,
    right: 12,
    "&:hover": {
      cursor: "pointer",
    },
  },
  applyButton: {
    margin: "16px 0 !important",
    color: "#2a528c",
    fontWeight: "bold",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50px !important",
    borderRadius: "20px !important",
    opacity: 1,
    transition: "0.2s",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
}));

export default injectStripe(CheckoutForm);
