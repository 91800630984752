import React, { useState, useEffect } from "react";
import { Button, TextField, Box } from "@material-ui/core";
import "../../../App.css";
import axios from "axios";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { ClipLoader } from "react-spinners";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import validations from "../../../lib/validations";

const useStyles = makeStyles((theme) => ({
  input: {
    margin: "3.6% 0",
    textAlign: "left",
    "& > label": {
      color: "#5C5C5C !important",
    },
  },
  relative: {
    position: "relative",
    width: "100%",
  },
  absolute: {
    position: "absolute",
    top: 31,
    right: 16,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  errorMessage: {
    margin: "0px 0px 8px",
    fontSize: 12,
    color: "#ff7070",
    textAlign: "left",
  },
}));

export default function ChangePassword(props) {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [loading, setLoading] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [unmatchedPassword, setUnmatchedPassword] = useState(false);
  const [isPasswordSecure, setIsPasswordSecure] = useState(null);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);
  const [hasNumericValue, setHasNumericValue] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasPasswordLength, setHasPasswordLength] = useState(false);
  const { setOpen, notify } = props;
  const classes = useStyles();

  const updatePassword = () => {
    setLoading(true);
    axios
      .post(`/auth/change/password`, { currentPassword, newPassword })
      .then((resp) => {
        console.log("Password updated", resp);
        notify("Password updated!", true);
        setLoading(false);
        setOpen(false);
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage = err.response.data.message
          .toLowerCase()
          .includes("password incorrect")
          ? "The password you entered is incorrect. Please try again."
          : "Error updating password. Please try again later.";
        notify(errorMessage, false);
        console.log("Error updating password", err.response.data.message);
      });
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleMatchingPassword = () => {
    if (newPassword !== passwordConfirmation) {
      setUnmatchedPassword(true);
    } else {
      setUnmatchedPassword(false);
    }
  };

  const validatePassword = (value) => {
    validations.hasSpecialCharacter(value)
      ? setHasSpecialCharacter(true)
      : setHasSpecialCharacter(false);
    validations.hasNumericValue(value)
      ? setHasNumericValue(true)
      : setHasNumericValue(false);
    validations.hasUppercase(value)
      ? setHasUpperCase(true)
      : setHasUpperCase(false);
    value.length > 7 ? setHasPasswordLength(true) : setHasPasswordLength(false);
  };

  const handleClick = () => {
    // check if 1. new password contains all of the requirements,
    // and 2. new password matches with confirmation password
    if (
      !hasUpperCase ||
      !hasNumericValue ||
      !hasPasswordLength ||
      !hasSpecialCharacter
    ) {
      setIsPasswordSecure(false);
    } else if (newPassword !== passwordConfirmation) {
      setUnmatchedPassword(true);
    } else {
      updatePassword();
    }
  };

  const inputProps = {
    variant: "outlined",
    color: "primary",
    className: classes.input,
    inputProps: { maxLength: 50 },
  };

  return (
    <Modal
      open
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      onClick={handleClose}
    >
      <div className="modal">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <ClearIcon className="close" onClick={handleClose} />
          <h2 className="modal-header">Update Password</h2>
          <div
            className="modal-form"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className={classes.relative}>
              <TextField
                label="Current Password"
                value={currentPassword}
                type={showCurrentPassword ? "text" : "password"}
                name="current password"
                {...inputProps}
                fullWidth
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <div onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                {showCurrentPassword ? (
                  <AiOutlineEyeInvisible
                    size={20}
                    className={classes.absolute}
                  />
                ) : (
                  <AiOutlineEye size={20} className={classes.absolute} />
                )}
              </div>
            </div>

            <div className={classes.relative}>
              <TextField
                label="New Password"
                value={newPassword}
                type={showNewPassword ? "text" : "password"}
                name="new password"
                {...inputProps}
                fullWidth
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  validatePassword(e.target.value);
                }}
              />
              <div onClick={() => setShowNewPassword(!showNewPassword)}>
                {showNewPassword ? (
                  <AiOutlineEyeInvisible
                    size={20}
                    className={classes.absolute}
                  />
                ) : (
                  <AiOutlineEye size={20} className={classes.absolute} />
                )}
              </div>
            </div>

            <div className={classes.relative}>
              <TextField
                label="Confirm New Password"
                value={passwordConfirmation}
                type={showPasswordConfirmation ? "text" : "password"}
                name="confirm new password"
                {...inputProps}
                fullWidth
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                onBlur={(e) => handleMatchingPassword()}
              />
              <div
                onClick={() =>
                  setShowPasswordConfirmation(!showPasswordConfirmation)
                }
              >
                {showPasswordConfirmation ? (
                  <AiOutlineEyeInvisible
                    size={20}
                    className={classes.absolute}
                  />
                ) : (
                  <AiOutlineEye size={20} className={classes.absolute} />
                )}
              </div>
            </div>

            {passwordConfirmation && unmatchedPassword && (
              <p className={classes.errorMessage}>
                The passwords entered do not match
              </p>
            )}

            {isPasswordSecure === false && (
              <p className={classes.errorMessage}>
                Your new password must meet the four conditions below:
              </p>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                marginTop: 16,
              }}
            >
              <Box my={1} className="passwordValidationText">
                <div
                  className={classes.icon}
                  style={{ visibility: !hasPasswordLength && "hidden" }}
                >
                  <i className="checkmark fas fa-check" />
                </div>
                <p className={classes.text}>8 Characters Minimum</p>
              </Box>
              <Box my={1} className="passwordValidationText">
                <div className={classes.icon}>
                  <i
                    className={"checkmark fas fa-check"}
                    style={{ visibility: !hasUpperCase && "hidden" }}
                  />
                </div>
                <p className={classes.text}>At least 1 capital letter</p>
              </Box>
              <Box my={1} className="passwordValidationText">
                <div className={classes.icon}>
                  <i
                    className={"checkmark fas fa-check"}
                    style={{ visibility: !hasNumericValue && "hidden" }}
                  />
                </div>
                <p className={classes.text}>At least 1 numeric value</p>
              </Box>
              <Box my={1} className="passwordValidationText">
                <div className={classes.icon}>
                  <i
                    className={"checkmark fas fa-check"}
                    style={{
                      visibility: !hasSpecialCharacter && "hidden",
                    }}
                  />
                </div>
                <p className={classes.text}>At least 1 special character</p>
              </Box>
            </div>

            <Button
              className="btn light-blue add-btn"
              disabled={
                loading ||
                !currentPassword ||
                !newPassword ||
                !passwordConfirmation
              }
              onClick={handleClick}
            >
              {loading ? (
                <div
                  className="sweet-loading"
                  style={{
                    position: "absolute",
                    width: 15,
                    height: 15,
                    top: 15,
                  }}
                >
                  <ClipLoader size={20} color="#fff" loading={loading} />
                </div>
              ) : (
                <span>Update Password</span>
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
