import React, { useState, useCallback } from "react";
import Modal from "@material-ui/core/Modal";
import { TextField, Grid } from "@material-ui/core";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { useDropzone } from "react-dropzone";
import { FiUpload } from "react-icons/fi";
import { ClipLoader } from "react-spinners";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      display: "flex",
      justifyContent: "center",
      margin: "40px auto",
      textAlign: "center",
      [theme.breakpoints.down(768)]: {
        margin: "24px auto",
      },
      [theme.breakpoints.down(415)]: {
        width: "100%",
      },
      "& label": {
        color: "#5C5C5C",
      },
    },
  },
  heading: {
    fontSize: 32,
    fontWeight: 700,
    marginTop: 16,
    marginBottom: 16,
    [theme.breakpoints.down(768)]: {
      fontSize: 24,
    },
  },
}));

export default function DocumentModal(props) {
  const classes = useStyles();

  const [title, setTitle] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    caseId,
    open,
    firms,
    caseObj,
    setOpen,
    clientDocuments,
    setClientDocuments,
    notify,
    user,
  } = props;
  const [document, setDocument] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  //React Dropzone
  const onDrop = useCallback((acceptedFiles) => {
    console.log("acceptedFiles", acceptedFiles);
    setDocument(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  function uploadDocument() {
    setLoading(true);
    let file = new FormData();
    file.append("title", title);
    file.append("postId", caseObj.id);
    file.append("firmId", firms[0].firmId);
    file.append("userId", user.id);
    let arr = [...clientDocuments];
    let doc;
    let author = {
      firstName: props.user.firstName,
      lastName: props.user.lastName,
    };
    if (document.type.includes("pdf")) {
      file.append("files", document);
      axios
        .post("/upload/documents", file, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((resp) => {
          setLoading(false);
          setOpen(false);
          doc = resp.data.documents;
          doc["author"] = author;
          console.log("docs in uploadDocs", doc);
          console.log("arr for documents", [...arr]);
          arr.unshift(doc);
          setClientDocuments([...arr]);
          notify("Document Added", true);
        })
        .catch((err) => {
          setLoading(false);
          console.log(
            "Error uploading document",
            err.response ? err.response : err
          );
          notify("Error adding document. Please try again later.", false);
        });
    } else {
      file.append("photos", document);
      axios
        .post("/upload/images", file, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((resp) => {
          setLoading(false);
          setOpen(false);
          doc = resp.data.documents;
          doc["author"] = author;
          console.log("images in uploadDocs", resp);
          console.log("arr for images", [...arr]);
          arr.unshift(doc);
          setClientDocuments([...arr]);
          notify("Document Added", true);
        })
        .catch((err) => {
          setLoading(false);
          console.log(
            "Error uploading document",
            err.response ? err.response : err
          );
          notify("Error adding document. Please try again later.", false);
        });
    }
  }

  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ margin: "1rem" }}
      onClick={handleClose}
    >
      <div className="modal">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-form">
            <div className={classes.root}>
              <ClearIcon className="close" onClick={handleClose} />
              <h2 className={classes.heading}>Upload a Document</h2>
              <TextField
                variant="outlined"
                disableUnderline={true}
                label="Document Name"
                onChange={(e) => setTitle(e.target.value)}
                style={{
                  flex: 0.6,
                  color: "#000",
                  borderRadius: "100px",
                }}
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
              <Grid
                container
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={4}>
                  <input
                    type="file"
                    className="file-upload"
                    style={{ fontSize: 16, cursor: "pointer" }}
                    // style={{ cssFloat: "left", width: "100%", margin: "40px auto" }}
                    onChange={(e) => {
                      console.log("TEST", e.target.file);
                      console.log("TESTS", e.target.files);
                      setDocument(e.target.files[0]);
                    }}
                    style={{ display: "none" }}
                    id="file"
                  />
                  <label for="file" className="choose-file-input">
                    Choose File
                  </label>
                </Grid>
                <Grid item xs={8}>
                  <p className="choose-file-text">
                    {document ? (
                      document.name
                    ) : (
                      <span style={{ color: "#5C5C5C" }}>No File Chosen</span>
                    )}
                  </p>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="center"
                {...getRootProps()}
                className="drop-file-section"
              >
                <Grid item xs={12}>
                  <FiUpload />
                </Grid>
                <Grid item xs={12}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the file here..</p>
                  ) : (
                    <p>You can also drop your file here.</p>
                  )}
                </Grid>
              </Grid>
              <button
                className="btn light-blue add-btn"
                style={{
                  alignItems: "center",
                  border: "none",
                  position: "relative",
                }}
                disabled={!title || !document || loading}
                onClick={() => uploadDocument()}
              >
                {loading ? (
                  <div
                    className="sweet-loading"
                    style={{
                      position: "absolute",
                      width: 15,
                      height: 15,
                      top: 13,
                    }}
                  >
                    <ClipLoader size={20} color="#fff" loading={loading} />
                  </div>
                ) : (
                  <span>Add Document</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
