import React, { useState, useCallback, useEffect } from "react";
import { Modal, TextField, Grid, Button } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import { FiUpload } from "react-icons/fi";
import axios from "axios";
import { AddCircleSharp } from "@material-ui/icons";
import { ClipLoader } from "react-spinners";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as Trash } from "../../../assets/small-trash.svg";

export default function EditLabelModal(props) {
  const classes = useStyles();
  const scrollBottomRef = React.createRef();

  const { setOpen, loading, data, handleClick, handleDelete } = props;
  const [newStatusArr, setNewStatusArr] = useState([]);
  const [deletedStatusIds, setDeletedStatusIds] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setStatuses(data);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [newStatusArr.length]);

  const scrollToBottom = () => {
    scrollBottomRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const deleteStatus = (id) => {
    setDeletedStatusIds([...deletedStatusIds, id]);
    let arr = [...statuses];
    let index = arr.findIndex((el) => el?.id == id);
    arr.splice(index, 1);
    setStatuses([...arr]);
    // setLoading(false);
  };

  return (
    <Modal
      open
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ margin: "1rem" }}
      onClick={handleClose}
    >
      <div className="modal">
        <div
          className="modal-content edit-labels"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="edit-modal">
            <ClearIcon className="close" onClick={handleClose} />
            <h2 className={classes.heading}>Edit Labels</h2>
            <div
              className={classes.inputsContainer}
              style={
                //scrolls vertically when there are more than 3 input boxes
                newStatusArr.length + statuses.length > 4
                  ? {
                      height: 240,
                      overflowY: "auto",
                    }
                  : {}
              }
            >
              {statuses.map((status, index) => {
                if (!status?.isButton) {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={status.name}
                        disabled={true}
                        inputProps={{
                          style: {
                            height: "50px",
                            paddingTop: 0,
                            paddingBottom: 0,
                            textTransform: "capitalize",
                          },
                        }}
                      />
                      <button
                        className={classes.trashIcon}
                        onClick={() => deleteStatus(status?.id)}
                      >
                        <Trash />
                      </button>
                    </div>
                  );
                }
              })}
              {newStatusArr.length > 0 &&
                newStatusArr.map((el, i) => (
                  <TextField
                    key={i}
                    ref={scrollBottomRef}
                    fullWidth
                    variant="outlined"
                    placeholder="Add label"
                    value={el.name}
                    onChange={(e) => {
                      const arr = Object.assign([...newStatusArr], {
                        [i]: e.target.value,
                      });
                      setNewStatusArr(arr);
                    }}
                    inputProps={{
                      style: {
                        height: "50px",
                        paddingTop: 0,
                        paddingBottom: 0,
                        textTransform: "capitalize",
                      },
                    }}
                  />
                ))}
            </div>
            <div className={classes.buttonWrapper} style={{ marginTop: 30 }}>
              <Button
                onClick={() => {
                  let newStatus = "";
                  let arr = [...newStatusArr];
                  arr.push(newStatus);
                  setNewStatusArr([...arr]);
                }}
                className={classes.addNewLabel}
                style={{
                  backgroundColor: "#fff",
                  border: "none",
                  fontSize: 16,
                  height: 50,
                  border: "1px solid #C4C4C4",
                  borderRadius: 5,
                  width: "100%",
                  textTransform: "capitalize",
                }}
              >
                + New label
              </Button>
            </div>
          </div>
          <div className={classes.buttonWrapper}>
            <button
              className="btn light-blue add-btn"
              style={{
                alignItems: "center",
                border: "none",
              }}
              disabled={
                loading ||
                //disable if new status added exists
                statuses.some((existingStatus) => {
                  let arr = newStatusArr.map((el) => el.toLowerCase());
                  return arr.includes(existingStatus.name.toLowerCase());
                })
              }
              onClick={() => {
                if (newStatusArr.length > 0) {
                  handleClick(newStatusArr);
                }
                if (deletedStatusIds.length > 0) {
                  handleDelete(deletedStatusIds);
                }
              }}
            >
              {loading ? (
                <div
                  className="sweet-loading"
                  style={{
                    position: "absolute",
                    width: 15,
                    height: 15,
                    top: 13,
                  }}
                >
                  <ClipLoader size={20} color="#fff" loading={loading} />
                </div>
              ) : (
                <span>Save</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  inputsContainer: {
    padding: "0 90px",
    "& > *": {
      display: "flex",
      justifyContent: "center",
      marginTop: 30,
      textAlign: "center",
      [theme.breakpoints.down(768)]: {
        margin: "24px auto",
      },
      [theme.breakpoints.down(415)]: {
        width: "100%",
      },
    },
    [theme.breakpoints.down(768)]: {
      padding: "0 20px",
    },
  },
  heading: {
    fontSize: 24,
    fontWeight: 700,
    marginTop: 32,
    marginBottom: 2,
  },
  addNewLabel: {
    "&:hover": {
      backgroundColor: "#D9D9D9 !important",
    },
  },
  trashIcon: {
    paddingLeft: 6,
    position: "absolute",
    right: -30,
    overflow: "visible",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
  },
  buttonWrapper: {
    padding: "0 90px",
    [theme.breakpoints.down(768)]: {
      padding: "0 20px",
    },
  },
}));
