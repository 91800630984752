import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, useMediaQuery } from "@material-ui/core";
import Logo from "../../assets/logo.svg";
import ClearIcon from "@material-ui/icons/Clear";

export default function SimpleModal({ modalVisible, handleClose, children }) {
  const classes = useStyles();

  return (
    <Modal
      open={modalVisible}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ margin: "1rem" }}
      onClick={() => handleClose()}
    >
      <div className={classes.root} onClick={(e) => e.stopPropagation()}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          <ClearIcon className="close" onClick={() => handleClose()} />
          {children}
        </div>
      </div>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: "72px 48px",
    background: "#fff",
    borderRadius: 30,
    margin: "auto",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down(768)]: {
      padding: "15% 6%",
    },
  },
}));
