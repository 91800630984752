import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { Button, TextField } from "@material-ui/core";
import axios from "axios";
import HelloSign from "hellosign-embedded";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { ClipLoader } from "react-spinners";
import { PhoneFormatter } from "../../lib/NumberFormatter";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: 50,
//     background: "#fff",
//     width: 536,
//     borderRadius: 30,
//     margin: "auto",
//     position: "relative",
//     [theme.breakpoints.down(960)]: {
//       padding: "50px 60px",
//     },
//     [theme.breakpoints.down(541)]: {
//       padding: "50px 30px",
//       width: "100%",
//     },
//     "& > *": {
//       width: "80%",
//       display: "flex",
//       justifyContent: "center",
//       margin: "40px auto",
//       textAlign: "center",
//       [theme.breakpoints.down(768)]: {
//         margin: "24px auto",
//       },
//       [theme.breakpoints.down(415)]: {
//         width: "100%",
//       },
//     },
//   },
// }));

export default function EditUserModal(props) {
  // const classes = useStyles();
  const { notify, team, setTeam, selected, setOpen } = props;
  const [firstName, setFirstName] = useState(
    props.selected?.UserFirms.firstName
  );
  const [lastName, setLastName] = useState(props.selected?.UserFirms.lastName);
  const [email, setEmail] = useState(props.selected?.UserFirms.email);
  const [phone, setPhone] = useState(props.selected?.UserFirms.phone);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.selected) {
      setFirstName(props.selected.UserFirms.firstName);
      setLastName(props.selected.UserFirms.lastName);
      setPhone(props.selected.UserFirms.phone);
      setEmail(props.selected.UserFirms.email);
    }
  }, [props.selected]);

  const handleClose = () => {
    setOpen(false);
  };

  function updateUser() {
    let { team } = props;
    setLoading(true);

    console.log("PARAMS", firstName, lastName, email, phone);
    let data = {};

    if (firstName) data.firstName = firstName;
    if (lastName) data.lastName = lastName;
    if (email) data.email = email;
    if (phone) data.phone = phone;

    console.log("DATA", data, props.selected.UserFirms.id);

    axios
      .put(`/users/team/${props.selected.UserFirms.id}`, data)
      .then((resp) => {
        console.log("User updated", resp);
        setLoading(false);
        setOpen(false);
        let arr = [...team];
        let index = team.findIndex((el) => {
          console.log("COMPARE", el.UserFirms.id, resp.data.user.id);
          return el.UserFirms.id === resp.data.user.id;
        });
        console.log("INDEX", arr, index);
        arr[index].UserFirms = resp.data.user;
        selected.UserFirms = resp.data.user;
        console.log("AFTER", arr);
        setTeam(arr);
        notify("User updated!", true);
      })
      .catch((err) => {
        setLoading(false);
        setOpen(false);
        notify("Error updating user. Please try again later.", false);
        console.log("Error uploading user", err.response ? err.response : err);
        window.alert("Error updating user. Please try again later");
      });
  }

  const inputProps = {
    variant: "outlined",
    color: "primary",
    style: styles.input,
    inputProps: { maxLength: 50 },
  };

  return (
    <Modal
      open={props.open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ margin: "1rem" }}
      onClick={handleClose}
    >
      <div className="modal">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <ClearIcon className="close" onClick={handleClose} />
          <h1 style={{ fontSize: 24 }}>Edit User</h1>
          <div className="modal-form">
            <TextField
              label="First Name"
              name="firstName"
              fullWidth
              defaultValue={selected?.UserFirms.firstName}
              onChange={(e) => setFirstName(e.target.value)}
              {...inputProps}
              inputProps={{ style: { textTransform: "capitalize" } }}
            />
            <TextField
              label="Last Name"
              name="lastName"
              fullWidth
              defaultValue={selected?.UserFirms.lastName}
              onChange={(e) => setLastName(e.target.value)}
              {...inputProps}
              inputProps={{ style: { textTransform: "capitalize" } }}
            />
            <TextField
              label="Email Address"
              name="email"
              fullWidth
              defaultValue={selected?.UserFirms.email}
              onChange={(e) => setEmail(e.target.value)}
              {...inputProps}
            />
            <PhoneFormatter
              label="Phone Number"
              fullWidth
              defaultValue={selected?.UserFirms.phone}
              onChange={(e) => setPhone(e.target.value)}
              {...inputProps}
            />
            <Button
              onClick={() => updateUser()}
              className="btn light-blue add-btn"
              disabled={loading}
            >
              {loading ? (
                <div
                  className="sweet-loading"
                  style={{
                    position: "absolute",
                    width: 15,
                    height: 15,
                    top: 13,
                  }}
                >
                  <ClipLoader size={20} color="#fff" loading={loading} />
                </div>
              ) : (
                <span>Update User</span>
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const styles = {
  input: {
    margin: "10px auto",
  },
  checkboxWrapper: {
    color: "#000",
    margin: "0 7% 3.2% 0",
    alignItems: "end",
  },
  label: {
    textAlign: "left",
    padding: "4.8% 0 4.4%",
  },
};
