import React from "react";
import { Link, NavLink } from "react-router-dom";

export default function RegistrationNavigation() {
  return (
    <div style={{ margin: "0 6%", fontSize: 16 }}>
      <a
        href={`mailto: ${process.env.REACT_APP_SUPPORT_EMAIL}`}
        target="_blank"
      >
        Support
      </a>
    </div>
  );
}
