const validations = {
  validateName: (str) => /^[A-Za-z\s\-]+$/.test(str),
  emailValidation: (str) =>
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      str
    ),
  hasUppercase: (str) => str.toLowerCase() !== str,
  hasNumericValue: (str) => /\d/g.test(str),
  hasSpecialCharacter: (str) => /[~`@!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/.test(str),
  hasUrl: (str) =>
    /^((ftp|http|https):\/\/)?(www.)(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/.test(
      str
    ),
};

export default validations;
