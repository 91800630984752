import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid } from "@material-ui/core";
import pdf from "../../../assets/pdf-icon.svg";
import axios from "axios";
import { ClipLoader } from "react-spinners";

export default function ChooseTemplate(props) {
  const classes = useStyles();
  const {
    notify,
    setAssignedDocuments,
    loading,
    documents,
    setDocuments,
    isSampleCase,
    sampleDocuments,
    clientId,
    user,
    caseId,
    firms,
  } = props;

  function assignToTemplate(template_id) {
    console.log("client id", clientId);
    if (window.confirm("Add this template to case?")) {
      axios
        .post("/helloSign/template/sign/embed", {
          template_id,
          userId: user.id,
          clientId,
          postId: caseId,
          firmId: firms[0].firmId,
          template_ids: template_id,
        })
        .then((resp) => {
          console.log("Template added to case", resp);
          notify("Template added to case!", true);
          props.fetchCase();
          let arr = documents.slice();
          let i = arr.findIndex(
            (el) => el.template_id === resp.data.helloSign.template_ids
          );
          arr.splice(i, 1);
          setDocuments([...arr]);
        })
        .catch((err) => {
          console.log(
            "Error adding template to case",
            err?.response ? err.response : err
          );
          notify(
            "Error adding template to case. Please try again later.",
            false
          );

          // NotificationManager.error(
          //   err?.response?.data?.message
          //     ? err.response.data.message
          //     : "Please try again later",
          //   "Could not add template"
          // );
        });
    }
  }

  return (
    <Box>
      {!isSampleCase ? (
        loading ? (
          <div
            className="sweet-loading"
            style={{
              width: 15,
              height: 15,
              margin: "5% auto",
              justifyContent: "center",
            }}
          >
            <ClipLoader
              size={75}
              color={"rgb(47, 86, 138)"}
              loading={loading}
            />
          </div>
        ) : (
          documents.map((el, i) => (
            <Grid key={i} container className={classes.row}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    marginRight: 16,
                  }}
                >
                  {/* {el.type === "pdf" && ( */}
                  <div
                    style={{
                      display: "flex",
                      backgroundImage: `url(${pdf})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      height: 31,
                      width: 31,
                    }}
                  />
                  {/* )} */}
                  {/* {el.type === "png" && (
                  <img src={png} alt="PNG icon" className={classes.icon} />
                )} */}
                </div>
                <div style={{ textTransform: "capitalize" }}>{el.title}</div>
              </div>
              <div>
                <Button
                  onClick={() => assignToTemplate(el.template_id)}
                  className={classes.button}
                >
                  + Assign to Case
                </Button>
              </div>
            </Grid>
          ))
        )
      ) : (
        sampleDocuments.map((el, i) => (
          <Grid key={i} container className={classes.row}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  display: "flex",
                  marginRight: 16,
                }}
              >
                <img src={pdf} alt="PDF icon" className={classes.icon} />
              </div>
              {el.title}
            </div>
            <div>
              <Button className={classes.button}>+ Assign to Case</Button>
            </div>
          </Grid>
        ))
      )}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#FAFAFA",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
    backgroundColor: "#fff",
    borderRadius: 10,
    margin: "0 auto 25px",
    padding: "3% 5%",
  },
  indicator: {
    backgroundColor: "#000",
  },
  button: {
    textTransform: "none",
    color: "#2A528C",
    fontSize: 16,
    "&:hover": {
      background: "none !important",
    },
  },
  icon: {
    width: 31,
    height: 31,
  },
}));
