import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Tab, Tabs, useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";
import Assigned from "./Assigned";
import ChooseTemplate from "./ChooseTemplate";
import ESignRequestModal from "../Modals/ESignRequestModal";
import { CSSTransition } from "react-transition-group";
import pdf from "../../../assets/pdf-icon.svg";
import EditEsignRequest from "../Modals/EditESignRequest";
import axios from "axios";
import { CallReceived, SettingsRemoteOutlined } from "@material-ui/icons";
import SimpleModal from "../../Modal/SimpleModal";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

var FileSaver = require("file-saver");

export default function ESignRequests(props) {
  const classes = useStyles();
  const mobile = useMediaQuery("(max-width:768px)");
  const {
    templates,
    notify,
    open,
    setOpen,
    firms,
    eSignDocuments,
    setESignDocuments,
    assignedDocuments,
    setAssignedDocuments,
    user,
    isSampleCase,
    clientId,
  } = props;
  const [fade, setFade] = useState(false);
  const [tab, setTab] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeESignRequest, setActiveESignRequest] = useState(false);
  const [eSignOpen, setESignOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [unassignedTemplates, setUnassignedTemplates] = useState([]);

  useEffect(() => {
    setFade(true);
  }, []);

  useEffect(() => {
    fetchTemplates();
  }, [eSignDocuments]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const sampleAssignedDocuments = [
    { title: "Wage and Salary Verification Form" },
    { title: "Auto Accident Sign Up" },
  ];

  const sampleTemplateDocuments = [
    { title: "Non-Vehicle Sign Up" },
    { title: "Non-Vehicle Sign Up" },
  ];

  function fetchTemplates() {
    let unassign = templates.slice();
    let assign = eSignDocuments.slice();
    unassign = unassign.filter(
      (u) => !assign.find((a) => a.template_ids === u.template_id)
    );
    setUnassignedTemplates(unassign);
  }

  function handleChange(event, val) {
    setTab(val);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  // TAB PANEL
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleDelete = (obj) => {
    const { signature_request_id, userId, firmId, postId } = obj;
    axios
      .delete(`/hellosign/doc/${signature_request_id}`, {
        userId,
        postId,
        firmId,
      })
      .then((resp) => {
        notify("Document successfully removed!", true);
        console.log("E-Sign Request deleted!", resp);
        let arr = [...eSignDocuments];
        let index = arr.findIndex(
          (el) => el.signature_request_id == signature_request_id
        );
        arr.splice(index, 1);
        setESignDocuments([...arr]);
        setAnchorEl(null);
      })
      .catch((err) => {
        console.log("Error deleting document", err, err.response, err.message);
        if (err.response.data.message.includes("currently being processed")) {
          notify(
            "This E-Sign Request is currently being processed. Please try again later.",
            false
          );
        } else {
          notify("Issue deleting document. Please try again later", false);
        }
      });
  };

  const handleDownload = (doc) => {
    axios
      .get(
        `/hellosign/download/files?signatureRequestId=${doc.signature_request_id}`
      )
      .then((resp) => {
        console.log("Template downloaded", resp);
        // FileSaver.saveAs(resp.data.file, `${resp.data.templateTitle}.pdf`);
        FileSaver.saveAs(resp.data.file, `file.pdf`);

      })
      .catch((err) => {
        notify("Issue downloading template. Please try again later", false);
        console.log(
          "Error downloading template",
          err?.response ? err.response : err
        );
      });
  };

  const handleClosePreview = () => {
    setOpenPreviewModal(false);
  };

  const handlePreview = (doc) => {
    console.log("doc here", doc);
    axios
      .get(
        `/hellosign/download/files?signatureRequestId=${doc.signature_request_id}`
      )
      .then((resp) => {
        console.log("Template opened", resp);
        setPreviewUrl(resp.data.file);
        setOpenPreviewModal(true);
      })
      .catch((err) => {
        console.log(
          "Error opening template",
          err?.response ? err.response : err
        );
        if (
          err.response.data.error.includes("Files are still being processed")
        ) {
          notify(err.response.data.error, false);
        } else {
          notify("Issue opening template. Please try again later", false);
        }
      });
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <CSSTransition in={fade} timeout={700} classNames="my-node">
      <div>
        {fade ? (
          <div className="eSignRequests">
            <Grid className={classes.root}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 35,
                }}
              >
                <p
                  style={{
                    fontWeight: "700",
                    textAlign: mobile && "left",
                  }}
                >
                  E-Sign Requests
                </p>
                <Button
                  className="btn case e-sign-btn"
                  onClick={() => !isSampleCase && setOpen(true)}
                >
                  + Add E-Sign Document
                </Button>
              </div>

              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  borderBottomWidth: 10,
                }}
              >
                <Tabs
                  value={tab}
                  onChange={handleChange}
                  indicatorColor="transparent"
                  aria-label="basic tabs example"
                  style={{
                    borderBottom: "1px solid #d3d3d3",
                    marginBottom: 25,
                  }}
                >
                  <Tab
                    label={
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontWeight: "500",
                          fontSize: 16,
                          color: tab == 0 ? "#000" : "rgb(186,186,186)",
                        }}
                      >
                        Assigned
                      </span>
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontWeight: "500",
                          fontSize: 16,
                          color: tab == 1 ? "#000" : "rgb(186,186,186)",
                        }}
                      >
                        Choose Template
                      </span>
                    }
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={tab} index={0}>
                <Assigned
                  documents={
                    isSampleCase ? sampleAssignedDocuments : eSignDocuments
                  }
                  handleDelete={handleDelete}
                  setESignOpen={setESignOpen}
                  activeESignRequest={activeESignRequest}
                  handleDownload={handleDownload}
                  setActiveESignRequest={setActiveESignRequest}
                  handlePreview={handlePreview}
                  loading={loading}
                />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <ChooseTemplate
                  documents={unassignedTemplates}
                  setDocuments={setUnassignedTemplates}
                  clientId={clientId}
                  user={user}
                  caseId={props.caseId}
                  fetchCase={props.fetchCase}
                  notify={notify}
                  firms={firms}
                  isSampleCase={isSampleCase}
                  sampleDocuments={sampleTemplateDocuments}
                  setAssignedDocuments={setAssignedDocuments}
                  loading={loading}
                />
              </TabPanel>
            </Grid>
            {open && (
              <ESignRequestModal
                open={open}
                setOpen={setOpen}
                firms={firms}
                notify={notify}
                caseId={props.caseId}
                clientId={clientId}
                eSignDocuments={eSignDocuments}
                setESignDocuments={setESignDocuments}
                user={user}
                fetchCase={props.fetchCase}
                getRootProps={props.getRootProps}
                getInputProps={props.getInputProps}
                isDragActive={props.isDragActive}
                setTab={setTab}
                loading={loading}
                setLoading={setLoading}
              />
            )}
            {activeESignRequest && (
              <EditEsignRequest
                open={eSignOpen}
                setOpen={setESignOpen}
                notify={notify}
                activeESignRequest={activeESignRequest}
                eSignDocuments={eSignDocuments}
                setESignDocuments={setESignDocuments}
              // firmId={props.firms[0].Firm.id}
              // templateId={activeTemplate.id}
              // template={activeTemplate}
              // firms={props.firms}
              // templates={templates}
              // setTemplates={props.setTemplates}
              // notify={props.notify}
              />
            )}

            {openPreviewModal && (
              <SimpleModal
                modalVisible={openPreviewModal}
                handleClose={handleClosePreview}
              >
                {/* <object
                  data={previewUrl}
                  type="application/pdf"
                  frameBorder="0"
                  width="100%"
                  height="100%"
                ></object> */}

                <iframe
                  src={previewUrl}
                  // type="application/pdf"
                  frameBorder="0"
                  width="100%"
                  height="100%"
                ></iframe>


                {/* <Document
                  file={previewUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={1} />
                </Document> */}

                {/* <Document file={`data:application/pdf;base64,${previewUrl}`}>
                  <Page pageNumber={pageNumber} />
                </Document> */}

                {/* <embed
                  src={previewUrl}
                  type="application/pdf"
                  width="300px"
                  height="500px"
                ></embed> */}

                {/* <iframe src={previewUrl} frameborder="0"></iframe> */}
              </SimpleModal>
            )}
          </div>
        ) : (
          <div />
        )}
      </div>
    </CSSTransition>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#FAFAFA",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
    backgroundColor: "#fff",
    borderRadius: 10,
    margin: "0 auto 5%",
    padding: "3% 5%",
  },
  indicator: {
    backgroundColor: "#000",
  },
  button: {
    textTransform: "none",
    color: "#2A528C",
    fontSize: 16,
    "&:hover": {
      background: "none !important",
    },
  },
}));
