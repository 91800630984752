import React, { useState, useEffect } from "react";
import "../../App.css";
import {
  Grid,
  Box,
  Button,
  TextField,
  InputAdornment,
  Input,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
} from "@material-ui/core";
import moment from "moment";
import axios from "axios";
import ClearIcon from "@material-ui/icons/Clear";
import { UncheckedIcon, CheckedIcon } from "../../lib/checkboxIcons";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CSSTransition } from "react-transition-group";
import EditUserModal from "./EditUserModal";
import isEqual from "react-fast-compare";
import { ClipLoader } from "react-spinners";
import { PhoneFormatter } from "../../lib/NumberFormatter";
import Modal from "@material-ui/core/Modal";
import chevronDown from "../../assets/chevron-down.svg";
import useKochava from "../../hooks/useKochava";
import ZeroStateModal from "../Modal/ZeroStateModal";
import data from "../../lib/data";
import ConfirmationModal from "./ConfirmationModal";

export default function Users(props) {
  const {
    team,
    setTeam,
    modal,
    setModal,
    notify,
    firms,
    searchUsers,
    self,
    teamLoaded,
    isSubscribed,
  } = props;

  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [hover, setHover] = useState({});
  const [userRole, setUserRole] = useState({});
  const [templates, setTemplates] = useState([]);
  const [editUser, setEditUser] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [fade, setFade] = useState(false);
  const [attorney, setAttorney] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [render, setRender] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [query, setQuery] = useState(null);
  const [confirmDeleteUser, setConfirmDeleteUser] = useState(false);
  const { recordActivity } = useKochava();

  // Receives user data when ready
  useEffect(() => {
    const { team } = props;
    if (team) {
      // let attorney = team.find((el) => el.roleId === 3);
      // setFade(true);
      // setAttorney(attorney ? attorney : {});

       // Removes attorney
       let arr = [...props.team]
       let index = arr.findIndex((el) => el.roleId === 3);
       console.log("HERE NOW", arr[index])
     setAttorney(arr[index]);
 
       if (index) arr.splice(index, 1)
       setTeam([...arr]);
 
       setLoading(false);
       setModal(false);
      setFade(true)
    }
    // fetchUsers();
  }, []);

  useEffect(() => {
    // setAttorney(team.find((el) => el.roleId === 3));
    //resets filteredUsers when a new user is added
    setFilteredUsers([]);
  }, [team]);

  console.log("TEAM", team)

  useEffect(() => {
    if (props.team.length > 0 && query) {
      fetchFilteredUsers(query);
    }
  }, [query]);

  const fetchFilteredUsers = (searchResult) => {
    const query = searchResult.toLowerCase();
    const { firmId } = firms[0];
    data.getTeam(null, firmId).then((users) => {
      let arr = [];
      for (let i = 0; i < users.length; i++) {
        let { UserFirms, roleId, createdAt } = users[i];
        if (roleId == 1) {
          let name = `${UserFirms.firstName} ${UserFirms.lastName}`;
          let email = UserFirms.email;
          let formattedDate = moment(createdAt).format("MM/DD/YY");
          name = name.toLowerCase();
          email = email.toLowerCase();
          if (name.includes(query)) {
            arr.push(users[i]);
          } else if (email.includes(query)) {
            arr.push(users[i]);
          } else if (formattedDate.includes(query)) {
            arr.push(users[i]);
          }
        }
      }
      setFilteredUsers([...arr]);
    });
  };

  const fetchUsers = () => {
    if (props.team && props.team.length !== team.length) {
      // Removes attorney
      let arr = [...props.team]
      let index = arr.findIndex((el) => el.roleId === 3);
      console.log("HERE NOW", arr[index])
    setAttorney(arr[index]);

      if (index) arr.splice(index, 1)
      setTeam([...arr]);

      setLoading(false);
      setModal(false);
    }
  };

  console.log("ATTORNEY", attorney)

  const handleUserTypeCheckbox = (e) => {
    setUserRole({ roleDescription: e.target.name, roleId: e.target.id });
  };

  const addUser = () => {
    setLoading(true);

    axios
      .post("/users/admin", {
        firstName,
        lastName,
        email: email.trim(),
        phone,
        roleId: userRole.roleId,
        firmId: attorney.firmId,
      })
      .then((resp) => {
        setLoading(false);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        console.log("resp in addUser", resp);
        let arr = [...team];
        arr.unshift({ UserFirms: resp.data.user });
        setTeam([...arr]);
        fetchUsers();
        setModal(false);
        notify("User added!", true);
      })
      .catch((err) => {
        setLoading(false);
        setModal(false);
        console.log("Error creating user", err?.response?.data?.message);
        if (err?.response?.data?.message) {
          notify(err.response.data.message, false);
        } else {
          notify("Error adding user. Please try again.", false);
        }
      });
    process.env.REACT_APP_TRACKING_ENABLED === "true" &&
      recordActivity("Employee Added");
  };

  const inputProps = {
    variant: "outlined",
    color: "primary",
    style: styles.input,
    inputProps: { maxLength: 50 },
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleDeleteUser = () => {
    setLoading(true);
    axios
      .delete(`/users/team/${selected.UserFirms.id}`)
      .then((resp) => {
        setLoading(false);
        let arr = team.slice();
        let i = arr.findIndex(
          (el) => el.UserFirms.id === selected.UserFirms.id
        );
        arr.splice(i, 1);
        setTeam([...arr]);
        setConfirmDeleteUser(false);
        notify("User deleted!", true);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error deleting user", err?.response?.data?.message);
        notify("Error deleting user. Please try again.", false);
      });
  };

  return (
    <CSSTransition in={fade} timeout={700} classNames="my-node">
      {fade ? (
        <div className="body" onClick={() => setModal(false)}>
          <div
            className="main"
            style={{
              padding: "2rem 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flex: 0.55, flexDirection: "row" }}>
              <p
                className="heading"
                style={{ fontSize: 24, fontWeight: "bold", margin: 0 }}
              >
                Recent Users
              </p>
            </div>
            <div>
              <Input
                disableUnderline={true}
                className="search"
                placeholder="Search"
                onChange={(e) => setQuery(e.target.value)}
                style={{ flex: 0.6, padding: "0 20px", color: "#000" }}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="fas fa-search search-icon"></i>
                  </InputAdornment>
                }
              />
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setModal(true);
                }}
                className="btn light-blue"
                style={{ marginLeft: 25, flex: 0.35 }}
              >
                + Add User
              </Button>
            </div>
          </div>

          <div className="team-table-wrapper">
            <div className="team-table">
              {teamLoaded ? (
                <>
                  <Grid
                    container
                    className="table-heading"
                    style={{
                      fontWeight: "600",
                      display: "relative",
                      color: "#BABABA",
                      borderBottom: "1px solid #D3D3D3",
                    }}
                  >
                    <Grid item xs>
                      <Box style={{ display: "flex" }}>
                        Name
                        <img
                          src={chevronDown}
                          style={{ marginLeft: 4 }}
                          alt="down arrow"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box style={{ display: "flex" }}>
                        Email
                        <img
                          src={chevronDown}
                          style={{ marginLeft: 4 }}
                          alt="down arrow"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs>
                      <Box style={{ display: "flex" }}>
                        Role
                        <img
                          src={chevronDown}
                          style={{ marginLeft: 4 }}
                          alt="down arrow"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs>
                      <Box style={{ display: "flex" }}>
                        Created
                        <img
                          src={chevronDown}
                          style={{ marginLeft: 4 }}
                          alt="down arrow"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                  <Accordion
                    elevation={0}
                    style={{ padding: 0, margin: 0 }}
                    defaultExpanded={true}
                  >
                    {team && team.length != 0 && attorney && (
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ padding: 0 }}
                      >
                        <Grid
                          onMouseEnter={() => {
                            let obj = attorney;
                            attorney.hover = true;
                            setAttorney(obj);
                            setRender(!render);
                          }}
                          onMouseLeave={() => {
                            let obj = attorney;
                            attorney.hover = false;
                            setAttorney(obj);
                            setRender(!render);
                          }}
                          alignItems="center"
                          container
                          style={{
                            color: "#000",
                            cursor: "pointer",
                            fontWeight: "500",
                            borderBottom: "1px solid #D3D3D3",
                            background: "#F9F9F9",
                          }}
                          className="table-row"
                        >
                          <Grid item xs style={{ textTransform: "capitalize" }}>
                            {attorney.UserFirms.firstName}{" "}
                            {attorney?.UserFirms.lastName}
                          </Grid>
                          <Grid item xs={3}>
                            {attorney.UserFirms.email}
                          </Grid>
                          <Grid item xs>
                            Attorney
                          </Grid>
                          <Grid item xs>
                            {moment(attorney.UserFirms.createdAt).format(
                              "MM/DD/YY"
                            )}
                          </Grid>
                          <Grid item xs={1}>
                            <FormControlLabel
                              onClick={(event) => {
                                event.stopPropagation();
                                setEditUser(true);
                                setSelected(attorney);
                              }}
                              onFocus={(event) => event.stopPropagation()}
                              control={
                                self.roleId == 3 ? (
                                  <i
                                    className="fas fa-pen edit-icon"
                                    style={{
                                      marginRight: 5,
                                      visibility:
                                        team &&
                                        team.length != 0 &&
                                        !attorney?.hover &&
                                        "hidden",
                                    }}
                                  ></i>
                                ) : (
                                  <i />
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                    )}

                    <AccordionDetails style={{ padding: 0 }}>
                      <Grid container>
                        {query
                          ? filteredUsers.map((el, i) => (
                              <Grid
                                key={i}
                                container
                                onMouseEnter={() => {
                                  let obj = el;
                                  el.hover = true;
                                  setUser(obj);
                                  setRender(!render);
                                }}
                                onMouseLeave={() => {
                                  let obj = el;
                                  el.hover = false;
                                  setUser(obj);
                                  setRender(!render);
                                }}
                                style={{
                                  color: "#000",
                                  cursor: "pointer",
                                  fontWeight: "500",
                                  borderBottom: "1px solid #D3D3D3",
                                }}
                                alignItems="center"
                                className="table-row"
                              >
                                <Grid item xs>
                                  {el.UserFirms.firstName}{" "}
                                  {el.UserFirms.lastName}
                                </Grid>
                                <Grid item xs={3}>
                                  {el.UserFirms.email}
                                </Grid>
                                <Grid item xs>
                                  Paralegal
                                </Grid>
                                <Grid item xs>
                                  {moment(el.UserFirms.createdAt).format(
                                    "MM/DD/YY"
                                  )}
                                </Grid>
                                <Grid item xs={1}>
                                  <FormControlLabel
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setEditUser(true);
                                      setSelected(el);
                                      setUser(el);
                                    }}
                                    onFocus={(event) => event.stopPropagation()}
                                    control={
                                      self.roleId == 3 ? (
                                        <i
                                          className="fas fa-pen edit-icon"
                                          style={{
                                            visibility:
                                              el &&
                                              el.length != 0 &&
                                              !el.hover &&
                                              "hidden",
                                          }}
                                        ></i>
                                      ) : (
                                        <i />
                                      )
                                    }
                                  />
                                </Grid>
                              </Grid>
                            ))
                          : team
                              .filter((admin) => admin.UserFirms.roleId == 1)
                              .map((el, i) => (
                                <Grid
                                  key={i}
                                  container
                                  onMouseEnter={() => {
                                    let obj = el;
                                    el.hover = true;
                                    setUser(obj);
                                    setRender(!render);
                                  }}
                                  onMouseLeave={() => {
                                    let obj = el;
                                    el.hover = false;
                                    setUser(obj);
                                    setRender(!render);
                                  }}
                                  style={{
                                    color: "#000",
                                    cursor: "pointer",
                                    fontWeight: "500",
                                    borderBottom: "1px solid #D3D3D3",
                                  }}
                                  alignItems="center"
                                  className="table-row"
                                >
                                  <Grid
                                    item
                                    xs
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {el.UserFirms.firstName}{" "}
                                    {el.UserFirms.lastName}
                                  </Grid>
                                  <Grid item xs={3}>
                                    {el.UserFirms.email}
                                  </Grid>
                                  <Grid item xs>
                                    Paralegal
                                  </Grid>
                                  <Grid item xs>
                                    {moment(el.UserFirms.createdAt).format(
                                      "MM/DD/YY"
                                    )}
                                  </Grid>
                                  <Grid item xs={1}>
                                    <FormControlLabel
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setEditUser(true);
                                        setSelected(el);
                                        setUser(el);
                                      }}
                                      onFocus={(event) =>
                                        event.stopPropagation()
                                      }
                                      control={
                                        self.roleId == 3 ? (
                                          <i
                                            className="fas fa-pen edit-icon"
                                            style={{
                                              marginRight: 8,
                                              visibility:
                                                el &&
                                                el.length != 0 &&
                                                !el.hover &&
                                                "hidden",
                                            }}
                                          ></i>
                                        ) : (
                                          <i />
                                        )
                                      }
                                    />
                                    <FormControlLabel
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setSelected(el);
                                        setUser(el);
                                        setConfirmDeleteUser(
                                          !confirmDeleteUser
                                        );
                                        console.log("click");
                                      }}
                                      onFocus={(event) =>
                                        event.stopPropagation()
                                      }
                                      control={
                                        self.roleId == 3 ? (
                                          <i
                                            className="fas fa-trash trash-icon"
                                            style={{
                                              visibility:
                                                el &&
                                                el.length != 0 &&
                                                !el.hover &&
                                                "hidden",
                                            }}
                                          ></i>
                                        ) : (
                                          <i />
                                        )
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </>
              ) : (
                <div
                  className="sweet-loading"
                  style={{
                    width: 100,
                    height: 75,
                    margin: "10% auto",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ClipLoader
                    style={{ position: "absolute" }}
                    size={75}
                    color={"rgb(47, 86, 138)"}
                  />
                </div>
              )}
            </div>
          </div>

          {/* Edit User Modal */}
          {selected && (
            <EditUserModal
              notify={notify}
              open={editUser}
              setOpen={setEditUser}
              user={user}
              team={team}
              setTeam={setTeam}
              selected={selected}
              setSelected={setSelected}
            />
          )}

          {console.log("selected", selected)}

          {/* Confirm Delete User Modal */}
          {confirmDeleteUser && (
            <ConfirmationModal
              selected={selected}
              open={confirmDeleteUser}
              setOpen={setConfirmDeleteUser}
              handlePress={handleDeleteUser}
              loading={loading}
              heading={
                <div>
                  Are you sure you want to delete{" "}
                  {selected.UserFirms.firstName &&
                  selected.UserFirms.lastName ? (
                    <span style={{ textTransform: "capitalize" }}>
                      {selected.UserFirms.firstName}{" "}
                      {selected.UserFirms.lastName}
                    </span>
                  ) : (
                    "this user"
                  )}
                  ?
                </div>
              }
            />
          )}

          {/* Add User Modal */}

          {modal &&
            (isSubscribed ? (
              <Modal
                open
                onClick={(e) => e.stopPropagation()}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                onClick={handleClose}
              >
                <div className="modal">
                  <div
                    className="modal-content"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <h1 className="modal-header">Add New User</h1>
                    <ClearIcon className="close" onClick={handleClose} />
                    <div
                      className="modal-scroll"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: 9,
                      }}
                    >
                      <FormControl
                        component="fieldset"
                        style={{ color: "#5C5C5C" }}
                      >
                        <FormLabel style={styles.label} component="legend">
                          Type of User
                        </FormLabel>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                className="checkbox"
                                icon={<UncheckedIcon />}
                                checkedIcon={<CheckedIcon />}
                                name="Paralegal"
                                onChange={handleUserTypeCheckbox}
                                checked={true}
                                id="1"
                                style={{ top: 2 }}
                              />
                            }
                            label="Paralegal"
                            style={styles.checkboxWrapper}
                          />
                          {/* <FormControlLabel
                        control={
                          <Checkbox
                            icon={<UncheckedIcon />}
                            checkedIcon={<CheckedIcon />}
                            name="Attorney"
                            onChange={handleUserTypeCheckbox}
                            checked={
                              userRole &&
                              userRole.roleDescription === "Attorney"
                            }
                            id="3"
                          />
                        }
                        label="Attorney"
                        style={styles.checkboxWrapper}
                      /> */}
                        </FormGroup>
                      </FormControl>

                      <TextField
                        select
                        label="Assigned to"
                        {...inputProps}
                        SelectProps={{
                          native: true,
                        }}
                        inputProps={{ style: { textTransform: "capitalize" } }}
                      >
                        {team && team.length != 0 && attorney && (
                          <option>
                            {attorney.UserFirms.firstName}{" "}
                            {attorney.UserFirms.lastName}
                          </option>
                        )}
                      </TextField>

                      <TextField
                        label="First Name"
                        name="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        {...inputProps}
                        inputProps={{ style: { textTransform: "capitalize" } }}
                      />
                      <TextField
                        label="Last Name"
                        name="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        {...inputProps}
                        inputProps={{ style: { textTransform: "capitalize" } }}
                      />
                      <TextField
                        label="Email Address"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        {...inputProps}
                      />
                      <PhoneFormatter
                        label="Phone Number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        {...inputProps}
                      />
                      {/* <FormControl
                    className="addTemplates"
                    style={{ textAlign: "left", color: "#000" }}
                    component="fieldset"
                  >
                    <FormLabel style={styles.label} component="legend">
                      Add Templates to User
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<UncheckedIcon />}
                            checkedIcon={<CheckedIcon />}
                            // onChange={this.handleTemplatesCheckbox}
                            value={templates}
                            name="Auto Accident Sign Up"
                          />
                        }
                        label="Auto Accident Sign Up"
                        style={styles.checkboxWrapper}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<UncheckedIcon />}
                            checkedIcon={<CheckedIcon />}
                            // onChange={this.handleTemplatesCheckbox}
                            value={templates}
                            name="Non-Vehicle Sign Up"
                          />
                        }
                        label="Non-Vehicle Sign Up"
                        style={styles.checkboxWrapper}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<UncheckedIcon />}
                            checkedIcon={<CheckedIcon />}
                            // onChange={this.handleTemplatesCheckbox}
                            value={templates}
                            name="Wage and Salary Verification Form"
                          />
                        }
                        label="Wage and Salary Verification Form"
                        style={styles.checkboxWrapper}
                      />
                    </FormGroup>
                  </FormControl> */}
                    </div>
                    <div
                      style={{
                        maxWidth: "100%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        padding: "0 24px",
                      }}
                    >
                      <Button
                        onClick={() => addUser()}
                        disabled={
                          !firstName || !lastName || !email || !phone || loading
                        }
                        className="btn light-blue add-btn"
                      >
                        {loading ? (
                          <div
                            className="sweet-loading"
                            style={{
                              position: "absolute",
                              width: 15,
                              height: 15,
                              top: 13,
                            }}
                          >
                            <ClipLoader
                              size={20}
                              color="#fff"
                              loading={loading}
                            />
                          </div>
                        ) : (
                          <span>Add User</span>
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>
            ) : (
              <ZeroStateModal
                modalVisible={modal}
                headerText="To unlock SimpleFile you’ll need to add your payment
                  information to start your subscription."
                paragraphText="Manage your clients. No Commitment. Cancel anytime."
                firstButton={true}
                firstButtonText="Start Subscription"
                handleClose={() => setModal(false)}
              />
            ))}
        </div>
      ) : (
        <div />
      )}
    </CSSTransition>
  );
}

const styles = {
  input: {
    margin: "3.6% 0",
  },
  checkboxWrapper: {
    color: "#000",
    margin: "0 7% 3.2% 0",
    display: "flex",
    alignItems: "start",
  },
  label: {
    textAlign: "left",
    padding: "4.8% 0 4.4%",
  },
};
