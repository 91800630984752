import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import {
  Grid,
  Paper,
  Button,
  CircularProgress,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Rectangle from "../../../assets/rectangle.svg";
import moment from "moment";
import useKochava from "../../../hooks/useKochava";
import { ReactComponent as Check } from "../../../assets/black-check.svg";
import { ClipLoader } from "react-spinners";
import CheckoutForm from "../../SignUp/Billing/CheckoutForm";
import CheckoutFormDetails from "../../SignUp/Billing/CheckoutFormDetails";

export default function PlansBilling(props) {
  // const { recordActivity } = useKochava();
  const classes = useStyles();
  const {
    auth,
    setAuth,
    user,
    notify,
    getFirm,
    planDetails,
    isLoading,
    payment,
    checkVerification,
    setIsSubscribed,
  } = props;
  const [fade, setFade] = useState(false);
  const [planEndDate, setPlanEndDate] = useState(null);
  const [todaysDate, setTodaysDate] = useState(null);
  const [cost, setCost] = useState(null);
  const xs = useMediaQuery("(max-width:325px)");
  const sm = useMediaQuery("(max-width:769px)");
  const md = useMediaQuery("(max-width:1200px)");

  useEffect(() => {
    setFade(true);
    setTodaysDate(getTodaysDate());
  }, []);

  useEffect(() => {
    if (planDetails) {
      setPlanEndDate(convertUnixTimestamp(planDetails?.trial_end));
      if (planDetails?.discount?.coupon) {
        setCost(
          planDetails.plan.amount_decimal / 100 -
            planDetails.discount.coupon.amount_off / 100
        );
      } else {
        setCost(planDetails?.plan.amount_decimal / 100);
      }
    }
  }, [planDetails]);

  function createBilling() {
    axios
      .post("/stripe/session", {
        priceId: process.env.REACT_APP_STRIPE_PRICE_ID,
      })
      .then((resp) => {
        console.log("billingCreate initiated", resp);
        // window.open(
        //   `${process.env.REACT_APP_STRIPE_CHECKOUT}&prefilled_email=${user.email}&customer=${user.stripeCustomerId}&mode=subscription`
        // );
      })
      .catch((err) =>
        console.log(
          "Error creating stripe session",
          err?.response ? err.response.data : err
        )
      );
  }

  function updateBilling() {
    axios
      .post("/stripe/billing/session", {
        return_url: `${process.env.REACT_APP_REDIRECT_URL}/account/plans`,
      })
      .then((resp) => {
        window.open(resp.data.session.url);
      })
      .catch((err) =>
        console.log(
          "Error getting plan details",
          err?.response ? err.response.data : err
        )
      );
    // recordActivity("Paid Account Upgrade: ", { Upgraded: true });
  }

  function convertUnixTimestamp(date, format = "MMMM Do, YYYY") {
    const formatted = moment.unix(date).format(format);
    return formatted;
  }

  function getTodaysDate(format = "MMMM Do, YYYY") {
    let date = moment(moment().toDate()).format(format);
    return date;
  }

  return (
    <CSSTransition in={fade} timeout={10000} classNames="my-node">
      {fade ? (
        <>
          <p className={classes.section}>Plan Details</p>
          <div className={classes.root}>
            <div className="paper">
              {!isLoading ? (
                !planDetails ? (
                  <div style={{ width: sm ? "100%" : "95%" }}>
                    <Grid container className={classes.container}>
                      <Grid
                        item
                        xs={md ? 12 : 6}
                        style={{
                          padding: md
                            ? "30px 30px 20px 30px"
                            : "60px 30px 60px 60px",
                        }}
                      >
                        <CheckoutFormDetails isSignedIn={true} />
                      </Grid>
                      <Grid
                        item
                        xs={md ? 12 : 6}
                        style={{
                          padding: md
                            ? "20px 30px 30px 30px"
                            : "60px 60px 60px 30px",
                        }}
                      >
                        <CheckoutForm
                          isSignedIn={true}
                          notify={notify}
                          auth={auth}
                          stripeCustomerId={user.stripeCustomerId}
                          checkVerification={checkVerification}
                          setIsSubscribed={setIsSubscribed}
                        />
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  <Paper
                    style={{
                      textAlign: "left",
                      maxWidth: 689,
                      borderRadius: 10,
                      position: "relative",
                      overflow: "hidden",
                      borderRadius: 10,
                    }}
                  >
                    <Box px={3.8} py={4}>
                      <Grid
                        container
                        alignItems={sm ? "flex-start" : "center"}
                        justifyContent={"space-between"}
                        direction={sm ? "column" : "row"}
                      >
                        <Grid item xs={sm ? 12 : 8}>
                          {/* not cancelled and trial end date is over */}
                          <p
                            style={{
                              fontWeight: "500",
                              fontSize: 18,
                              marginTop: 0,
                            }}
                          >
                            {!planDetails?.cancel_at_period_end &&
                            moment(
                              convertUnixTimestamp(
                                planDetails?.trial_end,
                                "MM/DD/YYYY"
                              )
                            ).isAfter(getTodaysDate("MM/DD/YYYY"))
                              ? "SimpleFile Subscription with Free Trial"
                              : "SimpleFile Subscription"}
                          </p>
                          {/* <p
                            style={{
                              fontWeight: "500",
                              fontSize: 18,
                              marginTop: 0,
                            }}
                          >
                            SimpleFile Subscription
                          </p> */}
                          <p>
                            ${cost && !isNaN(cost) ? cost.toFixed(2) : "--"}
                            /month
                          </p>

                          {/* not cancelled and trial end date is not over */}
                          {!planDetails?.cancel_at_period_end &&
                            moment(
                              convertUnixTimestamp(
                                planDetails?.trial_end,
                                "MM/DD/YYYY"
                              )
                            ).isAfter(getTodaysDate("MM/DD/YYYY")) && (
                              <p className={classes.smallText}>
                                Your plan ends on {planEndDate}
                              </p>
                            )}

                          {/* is cancelled*/}
                          {planDetails?.cancel_at_period_end ? (
                            <div>
                              <h2
                                style={{
                                  color: "#ff0033",
                                  margin: 0,
                                  marginBottom: 16,
                                  fontSize: 18,
                                }}
                              >
                                Cancelled
                              </h2>

                              {/* if cancelled and trial end date hasn't occurred yet, let user know when plan is active until*/}
                              <p
                                className={classes.smallText}
                                style={{ marginBottom: sm ? 16 : 0 }}
                              >
                                <span>
                                  {moment(
                                    convertUnixTimestamp(
                                      planDetails?.cancel_at,
                                      "MM/DD/YYYY"
                                    )
                                  ).isAfter(
                                    convertUnixTimestamp(
                                      planDetails?.trial_end,
                                      "MM/DD/YYYY"
                                    )
                                  )
                                    ? "Your plan is active until"
                                    : "Your plan ended on"}
                                </span>{" "}
                                {convertUnixTimestamp(planDetails?.cancel_at)}
                              </p>
                            </div>
                          ) : (
                            <div />
                          )}
                        </Grid>

                        <Grid
                          item
                          xs={sm ? 12 : 4}
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <button
                            className={classes.button}
                            onClick={() => updateBilling()}
                          >
                            Manage Plan
                          </button>
                        </Grid>
                      </Grid>

                      <Grid container alignItems="center">
                        <div style={{ marginTop: sm ? 54 : 74 }}>
                          <p className={classes.smallText}>Payment Method</p>
                          <div style={{ display: "flex" }}>
                            <img
                              src={Rectangle}
                              alt="Rectangle"
                              style={{ paddingRight: 13 }}
                            />
                            {payment ? (
                              <p style={{ fontSize: 14 }}>
                                <span style={{ textTransform: "capitalize" }}>
                                  {payment.card?.brand}
                                </span>
                                &nbsp;ending in {payment.card?.last4}
                              </p>
                            ) : (
                              <p>No card found</p>
                            )}
                          </div>
                        </div>
                      </Grid>
                    </Box>
                  </Paper>
                )
              ) : (
                <div
                  className="sweet-loading"
                  style={{
                    margin: "10% auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ClipLoader
                    style={{ position: "absolute" }}
                    size={75}
                    color={"rgb(47, 86, 138)"}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div />
      )}
    </CSSTransition>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "50vh",
    "& > *": {
      margin: "3% 0",
    },
  },
  section: {
    textAlign: "left",
    fontSize: 24,
    fontWeight: "700",
    color: "rgba(92,92,92)",
  },
  button: {
    borderRadius: 25,
    minWidth: 114,
    border: "1px solid #000",
    background: "none",
    padding: 10,
    cursor: "pointer",
    height: 35,
    transition: "0.3s",
    "&:hover": { background: "#e8e8e8" },
    [theme.breakpoints.down(420)]: {
      width: "100%",
    },
  },
  smallText: {
    fontSize: 14,
    color: "#979797",
    marginBottom: 16,
    marginTop: 0,
  },
  link: {
    fontSize: 14,
    color: "#0088FF",
    fontWeight: "500",
    cursor: "pointer",
  },
  planText: {
    fontWeight: "700",
    margin: 7,
    marginLeft: 14,
  },
  container: {
    backgroundColor: "#fff",
    boxShadow: "1px 3px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: 10,
  },
}));
