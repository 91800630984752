import { useMediaQuery } from "@material-ui/core";

export default function CheckoutFormDetails(props) {
  const md = useMediaQuery("(max-width:940px)");
  const { isSignedIn } = props;

  return (
    <div>
      <div style={{ marginBottom: 0, textAlign: "left" }}>
        <p
          style={{
            fontFamily: "SFProDisplay-Black",
            color: "#000",
            fontSize: 42,
            textAlign: "left",
            fontWeight: 700,
            marginTop: 0,
            marginBottom: 19,
            lineHeight: "58px",
            width: md ? "100%" : "80%",
          }}
        >
          {isSignedIn
            ? "Fill out the form to start your subscription."
            : "Fill out the form to get started."}
        </p>
        {/* <span
                  style={{
                    fontFamily: "SFProDisplay-Medium",
                    fontSize: 16,
                    color: "#5c5c5c",
                  }}
                >
                  Your credit card will{" "}
                  <span
                    style={{
                      fontFamily: "SFProDisplay-Bold",
                    }}
                  >
                    not
                  </span>{" "}
                  be charged until after the FREE trial ends.
                </span> */}
      </div>

      <div
        style={{
          background: "#f5f7f9",
          flex: 1,
          padding: "21px 28px 21px 24px",
          borderRadius: 10,
        }}
      >
        <p
          style={{
            textAlign: "left",
            marginTop: 0,
            fontSize: 14,
            fontFamily: "SFProDisplay-Light",
            color: "#5C5C5C",
            maxWidth: "80%",
          }}
        >
          Enjoy all the benefits of SimpleFile for one low monthly price.
          There’s no need to go and spend tens of thousands or hundreds of
          thousands of dollars.
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
            marginTop: 0,
          }}
        >
          <p
            style={{
              fontFamily: "SFProDisplay-Medium",
              color: "#000",
              fontSize: 16,
              margin: 0,
              textAlign: "left",
              paddingRight: 10,
            }}
          >
            LIMITED TIME OFFER
          </p>
          <p
            style={{
              fontFamily: "SFProDisplay-Medium",
              color: "#000",
              fontSize: 16,
              margin: 0,
              textAlign: "right",
            }}
          >
            <span
              style={{
                fontStyle: "italic",
              }}
            >
              FREE
            </span>{" "}
            On-Boarding
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 15,
          }}
        >
          <p
            style={{
              fontFamily: "SFProDisplay-Regular",
              color: "#5c5c5c",
              fontSize: 16,
              margin: 0,
              textAlign: "left",
              paddingRight: 10,
            }}
          >
            45-Day Money Back Guarantee
          </p>
          <p
            style={{
              fontFamily: "SFProDisplay-Regular",
              color: "#5c5c5c",
              fontSize: 16,
              margin: 0,
              textAlign: "right",
            }}
          >
            $99.00/month
          </p>
        </div>
      </div>
    </div>
  );
}
