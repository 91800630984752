import React from "react";
// Import Kochava
import { Kochava } from "kochava";

// Instantiate Kochava with your configuration
const kochava = new Kochava(
  process.env.REACT_APP_KOCHAVA_ID, // app_id
  true, // verbose
  true, // use_cookie
  false, // disable_auto_install
  null // identity_link
);

export const KochavaContext = React.createContext({});

// This context provider is passed to any component requiring the context
export const KochavaProvider = ({ children }) => {
  const recordPage = () => {
    kochava.page();
  };

  const recordCustomPage = (name = "", details = {}, callback = () => {}) => {
    kochava.page(name, details, callback);
  };

  const recordActivity = (name = "", details = {}, callback = () => {}) => {
    console.log("ACTIVITY RECORDED");
    kochava.activity(name, details, callback);
  };

  const recordIdentity = (details = {}, callback = () => {}) => {
    kochava.identify(details, callback);
  };

  const value = {
    recordPage,
    recordCustomPage,
    recordActivity,
    recordIdentity,
  };

  return (
    <KochavaContext.Provider value={value}>{children}</KochavaContext.Provider>
  );
};
