import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, useMediaQuery } from "@material-ui/core";
import Logo from "../../assets/logo.svg";
import ClearIcon from "@material-ui/icons/Clear";

export default function ZeroStateModal({
  modalVisible,
  headerText,
  headerText2,
  paragraphText,
  firstButtonText,
  secondButtonText,
  firstButton = false,
  secondButton = false,
  handleClick = () => (window.location = "/account/plans"),
  handleClose = () => {},
  paragraphWidth,
}) {
  const classes = useStyles();

  return (
    <Modal
      open={modalVisible}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ margin: "1rem" }}
      onClick={() => handleClose()}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
        }}
      >
        <div className={classes.root} onClick={(e) => e.stopPropagation()}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ClearIcon className="close" onClick={() => handleClose()} />
            <Box>
              <img src={Logo} alt="Logo" style={{ width: 126, height: 25 }} />
            </Box>
            <Box className={classes.column}>
              <div style={{ maxWidth: 454 }}>
                <h3
                  style={{
                    fontSize: 24,
                    lineHeight: "36px",
                    margin: "38px 0 0",
                  }}
                >
                  {headerText}
                </h3>
                <h3
                  style={{
                    fontSize: 24,
                    marginTop: 0,
                  }}
                >
                  {headerText2}
                </h3>
                <p
                  style={{
                    margin: "20px 0 0",
                    maxWidth: paragraphWidth && paragraphWidth,
                    marginLeft: "auto",
                    marginRight: "auto",
                    lineHeight: "24px",
                    fontSize: 16,
                    color: "#5C5C5C",
                  }}
                >
                  {paragraphText}
                </p>
              </div>
            </Box>
          </div>
          <Box className={classes.column} style={{ width: 242, marginTop: 46 }}>
            {firstButton && (
              <Button
                className="modal-btn"
                style={{ maxWidth: "100%", height: 50 }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick();
                }}
              >
                {firstButtonText}
              </Button>
            )}
            {secondButton && (
              <Button className={classes.button} onClick={() => handleClose()}>
                View Sample Cases
              </Button>
            )}
          </Box>
        </div>
      </div>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "72px 48px",
    background: "#fff",
    width: 694,
    minHeight: 584,
    borderRadius: 30,
    margin: "auto",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down(768)]: {
      padding: "12% 6%",
    },
    "& > *": {
      width: "80%",
      display: "flex",
      justifyContent: "center",
      margin: "40px auto",
      textAlign: "center",
      [theme.breakpoints.down(768)]: {
        margin: "32px auto",
      },
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    textTransform: "none",
    marginTop: 24,
    padding: 0,
    color: "#2A528C",
    fontWeight: 500,
    transition: "0.3s",
    fontSize: 16,
    "&:hover": {
      background: "none !important",
      color: "#5CA1F2",
    },
  },
}));
