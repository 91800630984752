import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { TextField, Grid, Button } from "@material-ui/core";
import axios from "axios";
import HelloSign from "hellosign-embedded";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { useDropzone } from "react-dropzone";
import { FiUpload } from "react-icons/fi";
import { ClipLoader } from "react-spinners";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      display: "flex",
      justifyContent: "center",
      margin: "40px auto",
      textAlign: "center",
      [theme.breakpoints.down(768)]: {
        margin: "32px auto",
      },
      "& label": {
        color: "#5C5C5C",
      },
    },
  },
  heading: {
    fontSize: 32,
    fontWeight: 700,
    marginTop: 16,
    marginBottom: 16,
    [theme.breakpoints.down(768)]: {
      fontSize: 24,
    },
  },
}));

export default function EditEsignRequest(props) {
  const classes = useStyles();

  const {
    activeESignRequest,
    notify,
    setOpen,
    setESignDocuments,
    eSignDocuments,
  } = props;
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTitle(activeESignRequest.title);
  }, [activeESignRequest]);

  const handleClose = () => {
    props.setOpen(false);
  };

  function updateESignDocument(obj, title) {
    setLoading(true);
    // const { firmId, templateId } = props;
    const { signature_request_id } = obj;
    axios
      .put(`/hellosign/doc/${signature_request_id}`, { title })
      .then((resp) => {
        console.log("Document updated", resp);
        setOpen(false);
        let arr = [...eSignDocuments];
        let index = arr.findIndex(
          (el) => el.signature_request_id == signature_request_id
        );
        arr[index].title = title;
        setESignDocuments([...arr]);
        setLoading(false);
        notify("Document title updated!", true);
      })
      .catch((err) => {
        setLoading(false);
        console.log(
          "Error updating document",
          err.response ? err.response : err
        );
        notify("Issue updating document. Please try again later.", false);
      });
  }

  return (
    <Modal
      open={props.open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ margin: "1rem" }}
      onClick={handleClose}
    >
      <div className="modal">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-form">
            <div className={classes.root}>
              <ClearIcon className="close" onClick={handleClose} />
              <h2 className={classes.heading}>Update Document</h2>
              <TextField
                variant="outlined"
                disableUnderline={true}
                fullWidth
                label="Document Name"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                style={{
                  flex: 0.6,
                  color: "#000",
                  borderRadius: "100px",
                }}
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
              <Button
                className="btn light-blue add-btn"
                disabled={!title}
                onClick={() => updateESignDocument(activeESignRequest, title)}
              >
                {loading ? (
                  <div
                    className="sweet-loading"
                    style={{
                      position: "absolute",
                      width: 15,
                      height: 15,
                      top: 13,
                    }}
                  >
                    <ClipLoader size={20} color="#fff" loading={loading} />
                  </div>
                ) : (
                  <span>Update Template</span>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
