import React from "react";
import { Table, Column, AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";
import "../../App.css";

export default function CaseTable(props) {
  const {
    data,
    label1,
    label2,
    label3,
    buttonLabel,
    dataKey1,
    dataKey2,
    dataKey3,
    buttonDataKey,
  } = props;

  const list = data?.map((el) => el);
  const MIN_TABLE_WIDTH = 1170;

  const rowClassName = ({ index }) => {
    if (index < 0) {
      return "headerRow";
    } else {
      return index % 2 === 0 ? "evenRow" : "oddRow";
    }
  };

  return (
    <AutoSizer>
      {({ width, height }) => (
        <Table
          width={width < MIN_TABLE_WIDTH ? MIN_TABLE_WIDTH : width}
          height={height}
          headerHeight={50}
          rowHeight={96}
          rowCount={list?.length}
          rowClassName={rowClassName}
          rowGetter={({ index }) => list[index]}
        >
          <Column
            label={label1}
            cellRenderer={dataKey1}
            cellDataGetter={(data) => dataKey1(data)}
            width={370}
          />
          <Column
            label={label2}
            cellRenderer={dataKey2}
            cellDataGetter={(data) => dataKey2(data)}
            width={270}
          />
          <Column
            label={label3}
            cellRenderer={dataKey3}
            cellDataGetter={(data) => dataKey3(data)}
            width={270}
          />
          <Column
            label={buttonLabel}
            cellRenderer={buttonDataKey}
            cellDataGetter={(data) => buttonDataKey(data)}
            width={240}
          />
        </Table>
      )}
    </AutoSizer>
  );
}
