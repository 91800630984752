import React, { useState } from "react";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import HelloSign from "hellosign-embedded";
import queryString from "query-string";

export default function Signature(props) {
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  React.useEffect(() => {
    getSignature();
  }, []);

  const blockZooming = () => {
    const viewport = document.querySelector("meta[name=viewport]");
    if (viewport) {
      let content = viewport.getAttribute("content") || "";
      const newContent = content.split(/,\s?/);

      // Prevent browsers from automatically zooming into text fields
      if (!content.includes("maximum-scale=1")) {
        newContent.push("maximum-scale=1");
        content = newContent.join(", ");
      }

      if (content !== viewport.getAttribute("content")) {
        viewport.setAttribute("content", content);
      }
    }
  };

  const getSignature = () => {
    try {
      console.log("PROPS", props)
      let { token } = queryString.parse(props.location.search);

      // HelloSign initialization
      const client = new HelloSign({
        clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
        skipDomainVerification: true,
      });

      client.on('sign', (data) => {
        console.log('The document has been signed!');
        console.log('Signature ID: ' + data.signatureId);
      });

      let { docId } = props.match.params;

      console.log("HELLO HERE")

      axios
        .get(`/hellosign/doc/${docId}?token=${token}`)
        .then((resp) => {
          console.log("HelloSign url received", resp);
          client.open(resp.data.signUrl);
          setLoading(false);
          setInitialized(true);
        })
        .catch((err) => {
          console.log("Error getting HelloSign signed url", err);
          setLoading(false);
          setError(true);
        });
    } catch (err) {
      console.log(
        "Error initializing HelloSign",
        err.response ? err.response.data : err
      );
      setLoading(false);
      setError(true);
    }
  };

  const override = css`
    position: absolute;
  `;

  return (
    <div>
      <div
        className="sweet-loading"
        style={{
          width: 100,
          height: 75,
          margin: "5% auto",
          display: loading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ClipLoader
          css={override}
          size={75}
          color={"rgb(47, 86, 138)"}
          loading={loading}
        />

        <img
          src={require("../../logo.png")}
          alt="attorney-jared-bossola"
          style={{ width: 60, position: "absolute" }}
        />
      </div>
      {error ? (
        <div>
          <h3>An error occurred, please try again.</h3>
        </div>
      ) : (
        <div />
      )}
      {(initialized || error) && (
        <h4>Please press "Back" to return to the app.</h4>
      )}
    </div>
  );
}
